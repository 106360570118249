<h2 mat-dialog-title>{{ existingFolder ? 'Update Folder' : 'Create Folder' }}</h2>
<mat-dialog-content>
  <mat-form-field>
    <input matInput type="text" placeholder="Name" [(ngModel)]="name">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="name == ''">Save</button>
</mat-dialog-actions>
