<span class="divider"></span>

<div class="scenario-menu-container">
  <div class="header-row">
    <span class="header">Scenarios</span>
    <mat-icon class="icon-button" *ngIf="!isMobile" (click)="openScenarioDialog($event)">add</mat-icon>
  </div>
  <div *ngIf="isLoading" class="loading-message">Loading...</div>
  <div *ngIf="!isLoading && !scenarioList.validScenario" class="outdated-scenario-message message-container">
    <mat-icon>error</mat-icon>
    <div class="message-text">
      <span>Viewing deleted scenario,</span>
      <span>Please select another.</span>
    </div>
  </div>
  <div *ngIf="!isLoading && scenarioList.validScenario && getConflictPlayerMessage()" class="conflict-scenario-message message-container">
    <mat-icon>warning</mat-icon>
    <div class="message-text" [matTooltip]="getConflictPlayerMessage(true)">
      <ng-container *ngIf="!isMobile">
        <ng-container *ngFor="let messageLine of getConflictPlayerMessage(); let i=index">
          <span *ngIf="i < 4 || (i == 4 && getConflictPlayerMessage().length <= 5)">{{ messageLine }}</span>
          <span *ngIf="i == 4 && getConflictPlayerMessage().length > 5">{{ '+' + getConflictPlayerMessage().length - 4 + ' More' }}</span>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isMobile">
        <span>Scenario contains conflicting transactions.</span>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">
    <div class="scenario-filter-button" [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger">
      <mat-icon>people</mat-icon>
      <span class="filter-text">{{ filterDisplay }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>

    <div *ngIf="currentFilter == scenarioFilters.ACTIVE" class="hide-other-scenarios-toggle">
      <div class="label">Hide Scenarios Made by Others</div>
        <mat-slide-toggle color="primary" [checked]="hideOtherScenarios"
            (change)="onToggleHideOtherScenarios($event)"></mat-slide-toggle>
    </div>

    <div class="search-row">
      <div class="search-container" [ngClass]="{'active': isSearchActive}">
        <mat-icon class="icon-button" (click)="setSearchActive(true)">search</mat-icon>
        <input #scenarioSearch tabindex="1" (blur)="setSearchActive(false, $event)" class="scenario-search" matInput
               *ngIf="isSearchActive" type="text" [(ngModel)]="searchValue" placeholder="Search Scenarios" (ngModelChange)="handleScenarioFilterChange(currentFilter)"/>
      </div>

      <div class="sort-toggle" (click)="toggleSorting()">
        <span class="sort-text">{{ sortingHeader }}</span>
        <mat-icon>sort</mat-icon>
      </div>
    </div>

    <div class="scenario-options-container" (scroll)="scrollHandler($event)">
      <div class="pinned-option-list">
        <div class="scenario-option pinned" [class.active]="!selectedScenarioID" (click)="selectScenario(null)">
          <img src="assets/images/greyscale_nba_logo.png" class="nba-logo">
          <span class="scenario-name">Current NBA</span>
        </div>
      </div>

      <div class="option-list">
        <div *ngFor="let scenario of filteredScenarios" class="scenario-option" [class.active]="scenario.id && selectedScenarioID == scenario.id" (click)="selectScenario(scenario.id)" (mouseenter)="hoverScenarioID = scenario.id" (mouseleave)="hoverScenarioID = null">
          <span class="scenario-info">
            <mat-icon *ngIf="scenario.hasConflict" class="conflict-warning" matTooltip="Contains conflict between temp transactions and reality">warning</mat-icon>
            <mat-icon *ngIf="scenario.isHistorical" class="archive-notice" matTooltip="Archived on {{ scenario.editDatetime | amDateFormat:'l LT' }}">pause</mat-icon>
            <span class="scenario-name" [class.shortened]=scenario.hasConflict>{{ scenario.name }}</span>
          </span>
          <mat-icon *ngIf="scenario.isEditor && currentUser.id != scenario.owner.userID">people</mat-icon>
          <mat-icon *ngIf="!scenario.isEditor && currentUser.id != scenario.owner.userID">remove_red_eye</mat-icon>
          <ng-container *ngIf="scenario.id == hoverScenarioID && scenario.isEditor">
            <ng-template *ngTemplateOutlet="scenarioControlPanel; context: {scenario: scenario}"></ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #scenarioControlPanel let-scenario="scenario">
  <div class="scenario-control-panel" [ngStyle]="{'margin-top': -1 * scenarioScrollOffset * 2 + 'px'}">
    <div class="scenario-control" (click)="openScenarioDialog($event, scenario)">Edit</div>
    <div class="scenario-control" (click)="openScenarioDialog($event, scenario, true)">Copy</div>
    <div *ngIf="!scenario.isHistorical" class="scenario-control archive" [class.disabled]="scenario.owner.userID != currentUser.id"
      [matTooltip]="scenario.owner.userID != currentUser.id ? 'Only the owner can archive this scenario' : ''"
      (click)="archiveScenario($event, scenario)">Archive</div>
    <div *ngIf="scenario.isHistorical" class="scenario-control archive" [class.disabled]="scenario.owner.userID != currentUser.id"
      [matTooltip]="scenario.owner.userID != currentUser.id ? 'Only the owner can activate this scenario' : ''"
      (click)="activateScenario($event, scenario)">Activate</div>
    <div class="scenario-control delete" [class.disabled]="scenario.owner.userID != currentUser.id"
      [matTooltip]="scenario.owner.userID != currentUser.id ? 'Only the owner can delete this scenario' : ''"
      (click)="deleteScenario($event, scenario)">Delete</div>
  </div>
</ng-template>

<mat-menu class="scenario-filter-menu" #filterMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <div class="scenario-filter-option" (click)="handleScenarioFilterChange(scenarioFilters.ACTIVE)">
      <div>Active Scenarios</div>
    </div>
    <div class="scenario-filter-option" (click)="handleScenarioFilterChange(scenarioFilters.ALL)">
      <div>All Scenarios</div>
    </div>
    <div class="scenario-filter-option" (click)="handleScenarioFilterChange(scenarioFilters.MINE)">
      <div>By you</div>
    </div>
    <div class="scenario-filter-option" (click)="handleScenarioFilterChange(scenarioFilters.EDITOR)">
      <div>Is Editor</div>
    </div>
    <div class="scenario-filter-option" (click)="handleScenarioFilterChange(scenarioFilters.SHARED)">
      <div>Shared with Me</div>
    </div>
    <div *ngIf="showConflictFilter" class="scenario-filter-option" (click)="handleScenarioFilterChange(scenarioFilters.CONFLICT)">
      <div>Has Conflicts</div>
    </div>
    <div class="scenario-filter-option" (click)="handleScenarioFilterChange(scenarioFilters.ARCHIVED)">
      <div>Archived</div>
    </div>
  </ng-template>
</mat-menu>
