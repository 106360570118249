<div class="playlist-menu-container" [ngClass]="{'hotkey-menu': isHotkeyMenu}">
  <div class="playlist-flex-container space-between title-row" *ngIf="!isHotkeyMenu">
    <div class="playlist-filter-button shared" [matMenuTriggerFor]="archiveMenu" #authorMenuTrigger="matMenuTrigger">
      <mat-icon class="playlist-icon margin-right" *ngIf="!displayArchive">video_library</mat-icon>
      <mat-icon class="playlist-icon margin-right" *ngIf="displayArchive">archive</mat-icon>      
      <span class="archive-text">{{ archiveDisplay }}</span>
      <mat-icon class="playlist-icon">arrow_drop_down</mat-icon>
    </div> 
    <mat-icon *ngIf="!isPostMenu" class="playlist-icon pointer" [matMenuTriggerFor]="newGroupingMenu">add</mat-icon>
    <mat-menu class="folder-options" #newGroupingMenu="matMenu">
      <button mat-menu-item *ngIf="!displayArchive" (click)="createNewPlaylist()">
        <mat-icon>playlist_add</mat-icon>
        <span>Create Playlist</span>
      </button>
      <button mat-menu-item (click)="createNewFolder()">
        <mat-icon>create_new_folder</mat-icon>
        <span>Create Folder</span>
      </button>
    </mat-menu>    
  </div>

  <div class="playlist-flex-container space-between top-margin">
    <div class="playlist-filter-button shared" [matMenuTriggerFor]="authorMenu" #authorMenuTrigger="matMenuTrigger">
      <mat-icon class="playlist-icon margin-right">people</mat-icon>
      <span class="author-text">{{ authorDisplay }}</span>
      <mat-icon class="playlist-icon">arrow_drop_down</mat-icon>
    </div>

    <div class="playlist-flex-container recents" (click)="toggleSorting()">
      <span class="playlist-menu-text margin-right">{{ sortingHeader }}</span>
      <mat-icon class="playlist-icon">sort</mat-icon>
    </div>
  </div>

  <div class="playlist-flex-container space-between top-margin search-row">
    <div class="search-container" [ngClass]="{'active': isSearchActive || isHotkeyMenu}">
      <mat-icon class="playlist-icon pointer" (click)="setSearchActive(true)">search</mat-icon>
      <input #playlistSearch tabindex="1" (blur)="setSearchActive(false, $event)" class="playlist-search" matInput
             *ngIf="isSearchActive || isHotkeyMenu" type="text" [(ngModel)]="searchValue" placeholder="Search Playlists" (ngModelChange)="filterPlaylists()"/>
    </div>
  </div>

  <div class="playlist-options-container" [ngClass]="{'hotkey-menu': isHotkeyMenu}">
    <ng-container *ngFor="let folder of filteredFolders">
      <div *ngIf="folder.id != -1" class="folder-option" (click)="changeSelectedFolder(folder)" [ngClass]="{active: !isHotkeyMenu && selectedFolder?.id === folder.id}">
        <span class="folder-menu-text">{{folder.name}}</span>
        <div class="icon-container">
          <button *ngIf="canEditFolder(folder)" class="folder-icon" mat-icon-button [matMenuTriggerFor]="folderMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu class="folder-options" #folderMenu="matMenu">
            <button mat-menu-item (click)="editShareFolder(folder)">
              <span>Edit/Share</span>
            </button>
            <button *ngIf="!folder.isArchived && canDeleteFolder(folder)" mat-menu-item (click)="archiveFolder(folder)">
              <span>Archive</span>
            </button>
            <button *ngIf="folder.isArchived && canDeleteFolder(folder)" mat-menu-item (click)="unarchiveFolder(folder)">
              <span>Unarchive</span>
            </button>
            <button *ngIf="canDeleteFolder(folder)" mat-menu-item (click)="deleteFolder(folder)">
              <span>Delete Folder</span>
            </button>
          </mat-menu>      
          <mat-icon *ngIf="folder.isShared" class="folder-icon" [ngClass]="'shared-icon'">people</mat-icon>
          <mat-icon class="folder-icon" [ngClass]="{'non-editable-folder': !canEditFolder(folder)}" *ngIf="selectedFolder?.id === folder.id">folder_open</mat-icon>
          <mat-icon class="folder-icon" [ngClass]="{'non-editable-folder': !canEditFolder(folder)}" *ngIf="selectedFolder?.id !== folder.id">folder</mat-icon>        
        </div>    
      </div>
      <ng-container *ngIf="selectedFolder?.id === folder.id">
        <ng-container *ngFor="let playlist of folder.playlists">
          <div class="folder-playlist-option" (click)="changeSelectedPlaylist(playlist)" [ngClass]="{active: !isHotkeyMenu && selectedPlaylist?.id === playlist.id}">
            <span class="folder-playlist-menu-text">{{playlist.name}}</span>
            <div class="playlist-icon-container">
              <button class="folder-playlist-icon" mat-icon-button [matMenuTriggerFor]="folderPlaylistMenu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu class="folder-options" #folderPlaylistMenu="matMenu">
                <button mat-menu-item [matMenuTriggerFor]="subFolderMenu">
                  <span>Add To Separate Folder</span>
                </button>
                <mat-menu class="folder-options" #subFolderMenu="matMenu">
                  <ng-container *ngFor="let folder of editableFolders">
                    <button mat-menu-item *ngIf="selectedFolder?.id != folder.id" (click)="addPlaylistToFolder(playlist, folder)">
                      {{ folder.name }}
                    </button>
                  </ng-container>              
                </mat-menu>
                <button *ngIf="canEditFolder(folder)" mat-menu-item (click)="removePlaylistFromFolder(playlist)">
                  <span>Remove From Folder</span>
                </button>
                <button *ngIf="canDeletePlaylist(playlist)" mat-menu-item class="folder-row" (click)="deletePlaylist(playlist)">
                  <span>Delete Playlist</span>
                </button>
              </mat-menu>
              <mat-icon *ngIf="playlist.isShared" class="playlist-icon" [ngClass]="'sub-folder'">people</mat-icon>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let playlist of filteredPlaylists">
      <div *ngIf="playlist.id != -1" class="playlist-option" (click)="changeSelectedPlaylist(playlist)" [ngClass]="{active: !isHotkeyMenu && selectedPlaylist?.id === playlist.id}">
        <span class="playlist-menu-text">{{playlist.name}}</span>
        <div class="playlist-icon-container">
          <button class="playlist-icon" mat-icon-button [matMenuTriggerFor]="playlistMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu class="folder-options" #playlistMenu="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="subFolderMenu">
              <span>Add To Folder</span>
            </button>
            <mat-menu class="folder-options" #subFolderMenu="matMenu">
              <button mat-menu-item *ngFor="let folder of editableFolders" (click)="addPlaylistToFolder(playlist, folder)">
                {{ folder.name }}
              </button>
            </mat-menu>
            <button *ngIf="!playlist.isArchived && canEditPlaylist(playlist) && canDeletePlaylist(playlist)" mat-menu-item (click)="archivePlaylist(playlist)">
              <span>Archive</span>
            </button>
            <button *ngIf="playlist.isArchived && canEditPlaylist(playlist) && canDeletePlaylist(playlist)" mat-menu-item (click)="unarchivePlaylist(playlist)">
              <span>Unarchive</span>
            </button>
            <button *ngIf="canDeletePlaylist(playlist)" mat-menu-item (click)="deletePlaylist(playlist)">
              <span>Delete Playlist</span>
            </button>
          </mat-menu>
          <mat-icon *ngIf="playlist.isShared" class="playlist-icon" [ngClass]="'shared-playlist'">people</mat-icon>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<mat-menu class="author-menu" #authorMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <div class="playlist-author-option" (click)="filterPlaylists(playlistAuthors.ALL)">
      <div>All Authors</div>
    </div>
    <div class="playlist-author-option" (click)="filterPlaylists(playlistAuthors.MINE)">
      <div>By you</div>
    </div>
    <div class="playlist-author-option" (click)="filterPlaylists(playlistAuthors.SHARED)">
      <div>Shared with Me</div>
    </div>
  </ng-template>
</mat-menu>

<mat-menu class="archive-menu" #archiveMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
  <ng-template matMenuContent>
    <div class="archive-option" (click)="filterFolders(false)">
      <div>Library</div>
    </div>
    <div class="archive-option" (click)="filterFolders(true)">
      <div>Archive</div>
    </div>
  </ng-template>
</mat-menu>