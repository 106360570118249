import * as _ from 'lodash';
import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {VideoState, ChannelState, SessionState, PlaylistsState, FoldersState, GeneratedPlaylistsState, SavedVideosLookupState} from './state';

export const selectVideoState = createFeatureSelector<VideoState>('video');

export const getChannel = (state: ChannelState): {
    isLoaded: boolean, channelName: string
} => {
    return {
        isLoaded: state.isLoaded,
        channelName: state.channelName
    };
};
export const selectChannelState: MemoizedSelector<object, ChannelState> =
createSelector(selectVideoState, (state: VideoState) => state.channel);
export const selectChannel: MemoizedSelector<object, any> = createSelector(selectChannelState, getChannel);

export const getSession = (state: SessionState): {
    isLoaded: boolean,
    id: number,
    currentNavigation: string,
    currentExploreType: string,
    playlist1ID: any,
    playlist2ID: any,
    playlist3ID: any,
    playlist4ID: any,
    playlist5ID: any,
    playlist6ID: any,
    explorePlaylistID: any,
    generatedPlaylistID: any,
    exploreClipIndex: number,
    exploreSortColumn: string,
    exploreSortDirection: string,
    exploreTableFilters: any,
    exploreColumns: any,
    exploreIsShuffled: boolean,
    exploreIsNotesShown: boolean,
    queueClipIndex: number,
    queueSortColumn: string,
    queueSortDirection: string,
    queueTableFilters: any,
    queueColumns: any,
    queueIsShuffled: boolean,
    queueIsNotesShown: boolean,
    playlist1ClipIndex: number,
    playlist1SortColumn: string,
    playlist1SortDirection: string,
    playlist1TableFilters: any,
    playlist1Columns: any,
    playlist1IsShuffled: boolean,
    playlist1IsNotesShown: boolean,
    playlist2ClipIndex: number,
    playlist2SortColumn: string,
    playlist2SortDirection: string,
    playlist2TableFilters: any,
    playlist2Columns: any,
    playlist2IsShuffled: boolean,
    playlist2IsNotesShown: boolean,
    playlist3ClipIndex: number,
    playlist3SortColumn: string,
    playlist3SortDirection: string,
    playlist3TableFilters: any,
    playlist3Columns: any,
    playlist3IsShuffled: boolean,
    playlist3IsNotesShown: boolean
    playlist4ClipIndex: number,
    playlist4SortColumn: string,
    playlist4SortDirection: string,
    playlist4TableFilters: any,
    playlist4Columns: any,
    playlist4IsShuffled: boolean,
    playlist4IsNotesShown: boolean,
    playlist5ClipIndex: number,
    playlist5SortColumn: string,
    playlist5SortDirection: string,
    playlist5TableFilters: any,
    playlist5Columns: any,
    playlist5IsShuffled: boolean,
    playlist5IsNotesShown: boolean,
    playlist6ClipIndex: number,
    playlist6SortColumn: string,
    playlist6SortDirection: string,
    playlist6TableFilters: any,
    playlist6Columns: any,
    playlist6IsShuffled: boolean,
    playlist6IsNotesShown: boolean
} => {
    return {
        isLoaded: state.isLoaded,
        id: state.id,
        currentNavigation: state.currentNavigation,
        currentExploreType: state.currentExploreType,
        playlist1ID: state.playlist1ID,
        playlist2ID: state.playlist2ID,
        playlist3ID: state.playlist3ID,
        playlist4ID: state.playlist4ID,
        playlist5ID: state.playlist5ID,
        playlist6ID: state.playlist6ID,
        explorePlaylistID: state.explorePlaylistID,
        generatedPlaylistID: state.generatedPlaylistID,
        exploreClipIndex: state.exploreClipIndex,
        exploreSortColumn: state.exploreSortColumn,
        exploreSortDirection: state.exploreSortDirection,
        exploreTableFilters: state.exploreTableFilters,
        exploreColumns: state.exploreColumns,
        exploreIsShuffled: state.exploreIsShuffled,
        exploreIsNotesShown: state.exploreIsNotesShown,
        queueClipIndex: state.queueClipIndex,
        queueSortColumn: state.queueSortColumn,
        queueSortDirection: state.queueSortDirection,
        queueTableFilters: state.queueTableFilters,
        queueColumns: state.queueColumns,
        queueIsShuffled: state.queueIsShuffled,
        queueIsNotesShown: state.queueIsNotesShown,
        playlist1ClipIndex: state.playlist1ClipIndex,
        playlist1SortColumn: state.playlist1SortColumn,
        playlist1SortDirection: state.playlist1SortDirection,
        playlist1TableFilters: state.playlist1TableFilters,
        playlist1Columns: state.playlist1Columns,
        playlist1IsShuffled: state.playlist1IsShuffled,
        playlist1IsNotesShown: state.playlist1IsNotesShown,
        playlist2ClipIndex: state.playlist2ClipIndex,
        playlist2SortColumn: state.playlist2SortColumn,
        playlist2SortDirection: state.playlist2SortDirection,
        playlist2TableFilters: state.playlist2TableFilters,
        playlist2Columns: state.playlist2Columns,
        playlist2IsShuffled: state.playlist2IsShuffled,
        playlist2IsNotesShown: state.playlist2IsNotesShown,
        playlist3ClipIndex: state.playlist3ClipIndex,
        playlist3SortColumn: state.playlist3SortColumn,
        playlist3SortDirection: state.playlist3SortDirection,
        playlist3TableFilters: state.playlist3TableFilters,
        playlist3Columns: state.playlist3Columns,
        playlist3IsShuffled: state.playlist3IsShuffled,
        playlist3IsNotesShown: state.playlist3IsNotesShown,
        playlist4ClipIndex: state.playlist4ClipIndex,
        playlist4SortColumn: state.playlist4SortColumn,
        playlist4SortDirection: state.playlist4SortDirection,
        playlist4TableFilters: state.playlist4TableFilters,
        playlist4Columns: state.playlist4Columns,
        playlist4IsShuffled: state.playlist4IsShuffled,
        playlist4IsNotesShown: state.playlist4IsNotesShown,
        playlist5ClipIndex: state.playlist5ClipIndex,
        playlist5SortColumn: state.playlist5SortColumn,
        playlist5SortDirection: state.playlist5SortDirection,
        playlist5TableFilters: state.playlist5TableFilters,
        playlist5Columns: state.playlist5Columns,
        playlist5IsShuffled: state.playlist5IsShuffled,
        playlist5IsNotesShown: state.playlist5IsNotesShown,
        playlist6ClipIndex: state.playlist6ClipIndex,
        playlist6SortColumn: state.playlist6SortColumn,
        playlist6SortDirection: state.playlist6SortDirection,
        playlist6TableFilters: state.playlist6TableFilters,
        playlist6Columns: state.playlist6Columns,
        playlist6IsShuffled: state.playlist6IsShuffled,
        playlist6IsNotesShown: state.playlist6IsNotesShown,
    };
};
export const selectSessionState: MemoizedSelector<object, SessionState> =
createSelector(selectVideoState, (state: VideoState) => state.session);
export const selectSession: MemoizedSelector<object, any> = createSelector(selectSessionState, getSession);

export const getPlaylists = (state: PlaylistsState): {
    isLoaded: boolean, playlists: any[]
} => {
    return {
        isLoaded: state.isLoaded,
        playlists: state.playlists
    };
};
export const selectPlaylistsState: MemoizedSelector<object, PlaylistsState> =
createSelector(selectVideoState, (state: VideoState) => state.playlists);
export const selectPlaylists: MemoizedSelector<object, any> = createSelector(selectPlaylistsState, getPlaylists);

export const getFolders = (state: FoldersState): {
    isLoaded: boolean, folders: any[]
} => {
    return {
        isLoaded: state.isLoaded,
        folders: state.folders
    };
};
export const selectFoldersState: MemoizedSelector<object, FoldersState> =
createSelector(selectVideoState, (state: VideoState) => state.folders);
export const selectFolders: MemoizedSelector<object, any> = createSelector(selectFoldersState, getFolders);

export const getGeneratedPlaylists = (state: GeneratedPlaylistsState): {
    isLoaded: boolean, generatedPlaylists: any[]
} => {
    return {
        isLoaded: state.isLoaded,
        generatedPlaylists: state.generatedPlaylists
    };
};
export const selectGeneratedPlaylistsState: MemoizedSelector<object, GeneratedPlaylistsState> =
createSelector(selectVideoState, (state: VideoState) => state.generatedPlaylists);
export const selectGeneratedPlaylists: MemoizedSelector<object, any> = createSelector(selectGeneratedPlaylistsState, getGeneratedPlaylists);

export const getSavedVideosLookup = (state: SavedVideosLookupState): {
    isLoaded: boolean,
    synergyEventIDs: string[],
    nbaChanceIDs: string[],
    eagleChanceIDs: string[],
    savedVideosMetadata: any[]
} => {
    return {
        isLoaded: state.isLoaded,
        synergyEventIDs: state.synergyEventIDs,
        nbaChanceIDs: state.nbaChanceIDs,
        eagleChanceIDs: state.eagleChanceIDs,
        savedVideosMetadata: state.savedVideosMetadata
    };
};
export const selectSavedVideosLookupState: MemoizedSelector<object, SavedVideosLookupState> =
createSelector(selectVideoState, (state: VideoState) => state.savedVideosLookup);
export const selectSavedVideosLookup: MemoizedSelector<object, any> = createSelector(selectSavedVideosLookupState, getSavedVideosLookup);
