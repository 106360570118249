import {CommonModule} from '@angular/common';
import {BildTooltipDirective} from '../../_shared/bild-tooltip/bild-tooltip';
import {BildTooltipModule} from '../../_shared/bild-tooltip/bild-tooltip.module';
import {BildDetailRowDirective} from './directives/bild-detail-row.directive';
import {EntityAvatarComponent} from './entity-avatar/entity-avatar.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {NgxPermissionsModule} from 'ngx-permissions';
import {RouterModule} from '@angular/router';

export {SearchBoxComponent} from './search-box/search-box.component';
export {SearchInputComponent} from './search-input/search-input.component';
import {AnnouncementsComponent} from './announcements/announcements.component';
import {BildLogoDirective} from './directives/bild-logo.directive';
import {BildMenuComponent} from './nav-menus/bild-menu/bild-menu.component';
import { FilterCardComponent } from './filter-card/filter-card.component';
import {BottomNavComponent} from './bottom-nav/bottom-nav.component';
import {DataEntryMenuComponent} from './nav-menus/data-entry-menu/data-entry-menu.component';
import {DragDropDirective} from './directives/drag-drop.directive';
import {EntitiesService} from '@services/entities.service';
import {EntityContextComponent} from './entity-context/entity-context.component';
import {HelpersModule} from 'apps/_helpers/helpers.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {KeyDatesBottomSheetComponent} from './bottom-sheets/key-dates-bottom-sheet/key-dates-bottom-sheet.component';
import {KeyDatesComponent} from './key-dates/key-dates.component';
import {KeyDatesExpandedDialogComponent} from './key-dates/key-dates-expanded-dialog/key-dates-expanded-dialog.component';
import {LeagueMenuComponent} from './nav-menus/league-menu/league-menu.component';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MedicalRatingBadgeComponent} from './medical-rating-badge/medical-rating-badge.component';
import {MomentsBottomSheetComponent} from './bottom-sheets/moments-bottom-sheet/moments-bottom-sheet.component';
import {MoreBottomSheetComponent} from './bottom-sheets/more-bottom-sheet/more-bottom-sheet.component';
import {NewsBottomSheetComponent} from './bottom-sheets/news-bottom-sheet/news-bottom-sheet.component';
import {NewsComponent} from './news/news.component';
import {NewsPostExpandedDialogComponent} from './news/news-post-expanded-dialog/news-post-expanded-dialog.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {NotificationsService} from '@services/notifications.service';
import {PersonBottomSheetComponent} from './bottom-sheets/person-bottom-sheet/person-bottom-sheet.component';
import {PersonMenuComponent} from './nav-menus/person-menu/person-menu.component';
import {PostComposerService} from './post/post-composer/post-composer.service';
import {PrimaryToolbarComponent} from './primary-toolbar/primary-toolbar.component';
import {ScrollDirective} from './directives/scroll.directive';
import {SearchBoxComponent} from './search-box/search-box.component';
import {SearchInputComponent} from './search-input/search-input.component';
import {SiteManagementBottomSheetComponent} from './bottom-sheets/site-management-bottom-sheet/site-management-bottom-sheet.component';
import {SiteManagementMenuComponent} from './nav-menus/site-management-menu/site-management-menu.component';
import {TeamBottomSheetComponent} from './bottom-sheets/team-bottom-sheet/team-bottom-sheet.component';
import {TeamMenuComponent} from './nav-menus/team-menu/team-menu.component';
import {VideoParamsDirective} from './directives/video.directive';
import {VideoService} from '@services/video.service';
import {ViewMoreComponent} from './view-more/view-more.component';
import {FeatureToggleDirective} from './directives/feature-toggle.directive';
import {BasketballCourtChartComponent} from '../entities/entity-metrics/basketball-court-chart/basketball-court-chart.component';
import {EntityMetricsTableComponent} from '../entities/entity-metrics/entity-metrics-table/entity-metrics-table.component';
import {ScoreboardGamesComponent} from './scoreboard-games/scoreboard-games.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {ReliabilityInfoDialogComponent} from './dialogs/reliability-info-dialog/reliability-info-dialog.component';
import {MomentModule} from 'ngx-moment';
import {PlayerInterestDialogComponent} from './dialogs/player-interest/player-interest.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {TableFilterComponent} from './table-filter/table-filter.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MetricsShotsComponent} from './metrics-shots/metrics-shots.component';
import {BaseD3ChartComponent} from './base-d3-chart/base-d3-chart.component';
import {HttpClientJsonpModule} from '@angular/common/http';
import {ChartingTableComponent} from './charting-table/charting-table.component';
import {SlackThreadComponent} from './slack-thread/slack-thread.component';
import {DraftLogoDirective} from './directives/draft-logo.directive';
import {SavantStylePercentileComponent} from './savant-style-percentile/savant-style-percentile.component';
import {
  EntityMetricsChartComponent
} from '../entities/entity-metrics/entity-metrics-chart/entity-metrics-chart.component';
import {
  TeamNetRatingChartComponent
} from '../entities/teams/team-metrics/team-metrics-measures/team-net-rating-chart/team-net-rating-chart.component';
import {MultipleEntitiesIconComponent} from './multiple-entities-icon/multiple-entities-icon.component';
import { TagMenuComponent } from './nav-menus/tag-menu/tag-menu.component';
import {SiteFiltersComponent} from './site-filters/site-filters.component';
import {Ng5SliderModule} from 'ng5-slider';
import {SubNavMenuComponent} from './sub-nav-menu/sub-nav-menu.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {
  EntityMetricsFiltersComponent
} from '../entities/entity-metrics/entity-metrics-filters/entity-metrics-filters.component';
import {ColumnSelectorComponent} from './column-selector/column-selector.component';
import {VideoPlayerComponent} from './video-player/video-player.component';
import {MatLegacySliderModule} from '@angular/material/legacy-slider';
import {VideoClipTableComponent} from "./video-clip-table/video-clip-table.component";
import {EntityHeaderComponent} from "../entities/_shared/entity-header/entity-header.component";
import {BaseMenuComponent} from "./nav-menus/base-menu/base-menu.component";
import {MobileSearchBoxComponent} from "./mobile-search-box/mobile-search-box.component";
import {MobileSearchInputComponent} from "./mobile-search-input/mobile-search-input.component";
import {LeagueSelectorComponent} from "./league-selector/league-selector.component";
import { ContractMenuComponent } from './nav-menus/contract-menu/contract-menu.component';
import { ContractLogoDirective } from './directives/contract-logo.directive';
import { ScenarioMenuComponent } from './nav-menus/scenario-menu/scenario-menu.component';
import { ScenariosBottomSheetComponent } from './bottom-sheets/scenarios-bottom-sheet/scenarios-bottom-sheet.component';
import { SalaryBottomSheetComponent } from './bottom-sheets/salary-bottom-sheet/salary-bottom-sheet.component';
import { SalaryMenuComponent } from './nav-menus/salary-menu/salary-menu.component';
import { PlayersMenuComponent } from './nav-menus/players-menu/players-menu.component';
import { PlayersBottomSheetComponent } from './bottom-sheets/players-bottom-sheet/players-bottom-sheet.component';

import {VideoMenuComponent} from "./nav-menus/video-menu/video-menu.component";
import {PlaylistMenuComponent} from "./playlist-menu/playlist-menu.component";
import {GeneratePlaylistComponent} from "../video/generate-playlist/generate-playlist.component";
import {VirtualHeaderDirective} from "./directives/virtual-header.directive";
import {VirtualCellDirective} from "./directives/virtual-cell.directive";
import {VideoFilterPanelComponent} from "../video/_shared/video-filter-panel/video-filter-panel.component";
import {VideoFilterChipComponent} from "../video/_shared/video-filter-chip/video-filter-chip.component";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {CdkDrag, CdkDragPreview, CdkDropList} from "@angular/cdk/drag-drop";
import { PlayerAvailabilityDialogComponent } from '@bild-dialogs/player-availability/player-availability.component';
import { TeamNeedsDialogComponent } from '@bild-dialogs/team-needs/team-needs.component';

@NgModule({
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, RouterModule, BildTooltipModule,

    HttpClientJsonpModule,
    NgxPermissionsModule.forChild(),
    FlexLayoutModule,
    HelpersModule,

    MatAutocompleteModule, MatBottomSheetModule, MatButtonToggleModule, MatCardModule, MatDialogModule, MatInputModule, MatIconModule,
    MatButtonModule, MatMomentDateModule, MatToolbarModule, MatMenuModule, MatListModule, MatSidenavModule, MatRadioModule,
    MatTabsModule, MatTableModule, MatTooltipModule, MatGridListModule, MatExpansionModule, MatSlideToggleModule, MatSortModule, MatPaginatorModule,

    InfiniteScrollModule,
    MomentModule, MatSelectModule, MatChipsModule, MatCheckboxModule, NgxSliderModule, MatDatepickerModule, Ng5SliderModule, OverlayModule, MatLegacySliderModule, VirtualHeaderDirective, VirtualCellDirective,
    NgxSkeletonLoaderModule, CdkDropList, CdkDrag, CdkDragPreview,
  ],
  declarations: [
    NewsPostExpandedDialogComponent,
    KeyDatesExpandedDialogComponent,

    BildDetailRowDirective,
    ScrollDirective,
    FeatureToggleDirective,

    AnnouncementsComponent,
    BasketballCourtChartComponent,
    EntityAvatarComponent,
    MultipleEntitiesIconComponent,
    EntityContextComponent,
    SearchInputComponent,
    MobileSearchInputComponent,
    SearchBoxComponent,
    MobileSearchBoxComponent,
    ViewMoreComponent,
    NewsComponent,
    KeyDatesComponent,
    BottomNavComponent,

    PrimaryToolbarComponent,
    BildLogoDirective,
    DragDropDirective,
    VideoParamsDirective,
    NotificationsComponent,
    DraftLogoDirective,
    ContractLogoDirective,

    ColumnSelectorComponent,
    TeamBottomSheetComponent,
    MomentsBottomSheetComponent,
    MoreBottomSheetComponent,
    NewsBottomSheetComponent,
    KeyDatesBottomSheetComponent,
    PersonBottomSheetComponent,
    SiteManagementBottomSheetComponent,
    ScenariosBottomSheetComponent,
    SalaryBottomSheetComponent,
    PlayersBottomSheetComponent,
    SalaryMenuComponent,
    PlayersMenuComponent,
    DataEntryMenuComponent,
    SiteManagementMenuComponent,
    PersonMenuComponent,
    TeamMenuComponent,
    LeagueMenuComponent,
    BildMenuComponent,
    FilterCardComponent,
    ContractMenuComponent,
    ScenarioMenuComponent,
    TagMenuComponent,
    VideoMenuComponent,
    PlaylistMenuComponent,
    MedicalRatingBadgeComponent,
    EntityMetricsFiltersComponent,
    EntityMetricsTableComponent,
    EntityMetricsChartComponent,
    TeamNetRatingChartComponent,
    ScoreboardGamesComponent,
    ReliabilityInfoDialogComponent,
    PlayerAvailabilityDialogComponent,
    PlayerInterestDialogComponent,
    TableFilterComponent,
    TeamNeedsDialogComponent,
    MetricsShotsComponent,
    BaseD3ChartComponent,
    ChartingTableComponent,
    SlackThreadComponent,
    SavantStylePercentileComponent,
    SiteFiltersComponent,
    SubNavMenuComponent,
    VideoPlayerComponent,
    VideoClipTableComponent,
    EntityHeaderComponent,
    BaseMenuComponent,
    LeagueSelectorComponent,
    GeneratePlaylistComponent,
    VideoFilterPanelComponent,
    VideoFilterChipComponent,
  ],
  exports: [
    BildDetailRowDirective,
    ScrollDirective,
    BildTooltipDirective,
    DraftLogoDirective,
    ContractLogoDirective,

    AnnouncementsComponent,
    BasketballCourtChartComponent,
    EntityAvatarComponent,
    MultipleEntitiesIconComponent,
    EntityContextComponent,
    SearchInputComponent,
    MobileSearchInputComponent,
    SearchBoxComponent,
    MobileSearchBoxComponent,
    ViewMoreComponent,
    NewsComponent,
    KeyDatesComponent,
    BottomNavComponent,

    PrimaryToolbarComponent,
    BildLogoDirective,
    DragDropDirective,
    VideoParamsDirective,
    ColumnSelectorComponent,
    NotificationsComponent,
    DataEntryMenuComponent,
    SiteManagementMenuComponent,
    PersonMenuComponent,
    TeamMenuComponent,
    LeagueMenuComponent,
    BildMenuComponent,
    FilterCardComponent,
    ContractMenuComponent,
    ScenarioMenuComponent,
    TagMenuComponent,
    VideoMenuComponent,
    PlaylistMenuComponent,
    MedicalRatingBadgeComponent,
    EntityMetricsTableComponent,
    ScoreboardGamesComponent,
    FeatureToggleDirective,
    TableFilterComponent,
    MetricsShotsComponent,
    ChartingTableComponent,
    SlackThreadComponent,
    EntityMetricsFiltersComponent,
    EntityMetricsChartComponent,
    TeamNetRatingChartComponent,
    SavantStylePercentileComponent,
    SiteFiltersComponent,
    SubNavMenuComponent,
    VideoPlayerComponent,
    VideoClipTableComponent,
    EntityHeaderComponent,
    BaseMenuComponent,
    LeagueSelectorComponent,
    GeneratePlaylistComponent,
    VideoFilterPanelComponent,
    VideoFilterChipComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        EntitiesService,
        NotificationsService,
        PostComposerService,
        VideoService
      ],
    };
  }
}
