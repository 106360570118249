import {Type} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Team} from 'apps/_models/teams';
import {Person} from '@models/people';
import { Entity } from './entities';

export class PlayerInterest {
  @Type(forwardRef(() => Person) as any)
  player: Person;
  @Type(forwardRef(() => Team) as any)
  team: Team;
  interest: number;
  isDuplicate: boolean;
}

export class PlayerAvailability {
  @Type(forwardRef(() => Person) as any)
  player: Person;
  @Type(forwardRef(() => Entity) as any)
  team: Entity;
  availability: number;
  isDisgruntled: boolean;
  notes: string;
}

export class TeamNeed {
  @Type(forwardRef(() => Team) as any)
  team: Team;
  need: string;
}
