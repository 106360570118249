import {createEffect, ofType, Actions} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {of} from 'rxjs';
import {switchMap, map, mergeMap} from 'rxjs/operators';

import * as actions from './actions';
import {VideoService} from '@services/video.service';
import {VideoMessageTypes} from '@models/video';
import {Store} from '@ngrx/store';
import {RootStoreState} from '@store/index';
import {connectionSuccessfulMessage} from '@store/constants';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import {parse} from 'json5';


@Injectable()
export class Effects {
  constructor(
    protected actions$: Actions,
    protected broadcastChannelService: BroadcastChannelService,
    protected store$: Store<RootStoreState.State>,
    protected videoService: VideoService,
  ) {}

  openSocket$ = createEffect(() => this.actions$.pipe(
    ofType<actions.OpenSocket>(actions.ActionTypes.OPEN_SOCKET),
    map(action => action.payload),
    switchMap((payload) => {
      if (this.connectVideoSocket()) {
        return of({
          type: actions.ActionTypes.OPEN_SOCKET_SUCCESS,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.OPEN_SOCKET_FAILED,
          payload: payload
        });
      }
    })
  ));

  updateChannelName$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateChannelName>(actions.ActionTypes.UPDATE_CHANNEL_NAME),
    map(action => action.payload),
    mergeMap(payload => {
      return of({
        type: actions.ActionTypes.UPDATE_CHANNEL_NAME_SUCCESS,
        payload: payload
      });
    }),
  ));

  requestInitialData$ = createEffect(() => this.actions$.pipe(
    ofType<actions.RequestInitialData>(actions.ActionTypes.REQUEST_INITIAL_DATA),
    map(action => action.payload),
    mergeMap(payload => {
      this.videoService.sendVideoSocketAction({[connectionSuccessfulMessage]: true});
      return of({
        type: actions.ActionTypes.REQUEST_INITIAL_DATA_SUCCESS,
        payload: payload
      });
    }),
  ));

  saveAction$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveAction>(actions.ActionTypes.SAVE_ACTION),
    map(action => action.payload),
    mergeMap(payload => {
      this.videoService.sendVideoSocketAction(payload);
      return of({
        type: actions.ActionTypes.SAVE_ACTION_SUCCESS,
        payload: payload
      });
    }),
  ));

  receiveVideoAction$ = createEffect(() => this.actions$.pipe(
    ofType<actions.ReceiveAction>(actions.ActionTypes.RECEIVE_ACTION),
    map(action => action.payload),
    mergeMap(payload => {
      if (payload.data.errors) {
        return of({
          type: actions.ActionTypes.RECEIVE_ACTION_FAILED,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.RECEIVE_ACTION_SUCCESS,
          payload: payload
        });
      }
    }),
  ));

  connectVideoSocket(): any {
    const videoSocket = this.videoService.createVideoSocket();

    videoSocket.messages.subscribe((event) => {
      this.broadcastChannelService.postMessage('Video call');
      if (event.data) {
        const actionData = parse(event.data);
        actionData.type && console.log(actionData.type);
        switch (actionData.type) {
          case VideoMessageTypes.WEBSOCKET_CHANNEL_NAME: {
            this.store$.dispatch(new actions.UpdateChannelName(actionData.data));
            this.store$.dispatch(new actions.RequestInitialData(actionData.data));
            break;
          }
          case VideoMessageTypes.SESSION_METADATA: {
            this.store$.dispatch(new actions.UpdateSession(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLISTS_METADATA: {
            this.store$.dispatch(new actions.UpdatePlaylists(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLIST_DATA: {
            this.store$.dispatch(new actions.UpdatePlaylist(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.PLAYLIST_SAVED: {
            this.store$.dispatch(new actions.SavePlaylist(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.PLAYLIST_DELETED: {
            this.store$.dispatch(new actions.DeletePlaylist(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.PLAYLIST_ARCHIVED: {
            this.store$.dispatch(new actions.ArchivePlaylist(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.PLAYLIST_UNARCHIVED: {
            this.store$.dispatch(new actions.UnarchivePlaylist(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.FOLDER_ARCHIVED: {
            this.store$.dispatch(new actions.ArchiveFolder(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.FOLDER_UNARCHIVED: {
            this.store$.dispatch(new actions.UnarchiveFolder(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.FOLDERS_METADATA: {
            this.store$.dispatch(new actions.UpdateFolders(actionData.data));
            break;
          }
          case VideoMessageTypes.FOLDER_DATA: {
            this.store$.dispatch(new actions.UpdateFolder(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.FOLDER_SAVED: {
            this.store$.dispatch(new actions.SaveFolder(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.FOLDER_DELETED: {
            this.store$.dispatch(new actions.DeleteFolder(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.GENERATED_PLAYLISTS_METADATA: {
            this.store$.dispatch(new actions.UpdateGeneratedPlaylists(actionData.data));
            break;
          }
          case VideoMessageTypes.GENERATED_PLAYLIST_DATA: {
            this.store$.dispatch(new actions.UpdateGeneratedPlaylist(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.GENERATED_PLAYLIST_CREATED: {
            this.store$.dispatch(new actions.CreateGeneratedPlaylist(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.SAVED_VIDEOS_LOOKUP_METADATA: {
            this.store$.dispatch(new actions.UpdateSavedVideosLookup(actionData.data));
            break;
          }
          case VideoMessageTypes.NAVIGATION_CHANGE: {
            this.store$.dispatch(new actions.UpdateNavigation(actionData.data));
            break;
          }
          case VideoMessageTypes.EXPLORE_TYPE_CHANGE: {
            this.store$.dispatch(new actions.UpdateExploreType(actionData.data));
            break;
          }
          case VideoMessageTypes.EXPLORE_PLAYLIST_CHANGE: {
            this.store$.dispatch(new actions.UpdateExplorePlaylist(actionData.data));
            break;
          }
          case VideoMessageTypes.EXPLORE_GENERATED_PLAYLIST_CHANGE: {
            this.store$.dispatch(new actions.UpdateExploreGeneratedPlaylist(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLIST_1_CHANGE: {
            this.store$.dispatch(new actions.UpdatePlaylist1(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLIST_2_CHANGE: {
            this.store$.dispatch(new actions.UpdatePlaylist2(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLIST_3_CHANGE: {
            this.store$.dispatch(new actions.UpdatePlaylist3(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLIST_4_CHANGE: {
            this.store$.dispatch(new actions.UpdatePlaylist4(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLIST_5_CHANGE: {
            this.store$.dispatch(new actions.UpdatePlaylist5(actionData.data));
            break;
          }
          case VideoMessageTypes.PLAYLIST_6_CHANGE: {
            this.store$.dispatch(new actions.UpdatePlaylist6(actionData.data));
            break;
          }
          case VideoMessageTypes.VIDEO_SAVED: {
            this.store$.dispatch(new actions.SaveVideo(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.VIDEO_DELETED: {
            this.store$.dispatch(new actions.DeleteVideo(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.VIDEO_POSITION_CHANGED: {
            this.store$.dispatch(new actions.VideoPositionChange(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.BULK_VIDEOS_SAVED: {
            this.store$.dispatch(new actions.BulkSaveVideos(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.BULK_VIDEOS_REMOVED: {
            this.store$.dispatch(new actions.BulkRemoveVideos(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.BULK_VIDEOS_DELETED: {
            this.store$.dispatch(new actions.BulkDeleteVideos(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.VIDEO_NOTES_SAVED: {
            this.store$.dispatch(new actions.SaveVideoNotes(actionData.data, actionData.channelName));
            break;
          }
          case VideoMessageTypes.TAG_SAVED: {
            this.store$.dispatch(new actions.SaveTag(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.TAG_DELETED: {
            this.store$.dispatch(new actions.DeleteTag(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.CLIP_INDEX_CHANGE: {
            this.store$.dispatch(new actions.UpdateClipIndex(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.NOTES_SHOWN_CHANGE: {
            this.store$.dispatch(new actions.UpdateNotesShown(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.IS_SHUFFLED_CHANGE: {
            this.store$.dispatch(new actions.UpdateIsShuffled(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.SORT_COLUMN_CHANGE: {
            this.store$.dispatch(new actions.UpdateSortColumn(actionData.data, actionData.chainID));
            break;
          }
          case VideoMessageTypes.SORT_DIRECTION_CHANGE: {
            this.store$.dispatch(new actions.UpdateSortDirection(actionData.data, actionData.chainID));
            break;
          }
        }
      }

      return videoSocket;
    });

    videoSocket.onclose = (event) => {
      this.store$.dispatch(new actions.UpdateChannelName(null));
      console.log('Socket is closed. Reconnect will be attempted in 5 second.');
      let $this = this;
      setTimeout(function() {
        $this.connectVideoSocket();
      }, 5000);
    }

    videoSocket.onerror = (event) => {
      console.log('Socket encountered error');
      videoSocket.close();
    }
  }
}
