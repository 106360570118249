import { Action } from '@ngrx/store';

export enum ActionTypes {
  OPEN_SOCKET = '[Video] Open Socket',
  OPEN_SOCKET_SUCCESS = '[Video] Open Socket Success',
  OPEN_SOCKET_FAILED = '[Video] Open Socket Failed',
  UPDATE_CHANNEL_NAME = '[Video] Update Channel Name',
  UPDATE_CHANNEL_NAME_SUCCESS = '[Video] Update Channel Name Success',
  UPDATE_CHANNEL_NAME_FAILED = '[Video] Update Channel Name Failed',
  REQUEST_INITIAL_DATA = '[Video] Request Initial Data',
  REQUEST_INITIAL_DATA_SUCCESS = '[Video] Request Initial Data Success',
  REQUEST_INITIAL_DATA_FAILED = '[Video] Request Initial Data Failed',
  SAVE_ACTION = '[Video] Save Action',
  SAVE_ACTION_SUCCESS = '[Video] Save Action Success',
  SAVE_ACTION_FAILED = '[Video] Save Action Failed',
  RECEIVE_ACTION = '[Video] Receive Action',
  RECEIVE_ACTION_SUCCESS = '[Video] Receive Action Success',
  RECEIVE_ACTION_FAILED = '[Video] Receive Action Failed',
  UPDATE_SESSION = '[Video] Update Session',
  UPDATE_SESSION_SUCCESS = '[Video] Update Sessions Success',
  UPDATE_SESSION_FAILED = '[Video] Update Session Failed',
  UPDATE_PLAYLISTS = '[Video] Update Playlists',
  UPDATE_PLAYLISTS_SUCCESS = '[Video] Update Playlists Success',
  UPDATE_PLAYLISTS_FAILED = '[Video] Update Playlists Failed',
  UPDATE_PLAYLIST = '[Video] Update Playlist',
  UPDATE_PLAYLIST_SUCCESS = '[Video] Update Playlist Success',
  UPDATE_PLAYLIST_FAILED = '[Video] Update Playlist Failed',
  SAVE_PLAYLIST = '[Video] Save Playlist',
  SAVE_PLAYLIST_SUCCESS = '[Video] Save Playlist Success',
  SAVE_PLAYLIST_FAILED = '[Video] Save Playlist Failed',
  DELETE_PLAYLIST = '[Video] Delete Playlist',
  DELETE_PLAYLIST_SUCCESS = '[Video] Delete Playlist Success',
  DELETE_PLAYLIST_FAILED = '[Video] Delete Playlist Failed',
  ARCHIVE_PLAYLIST = '[Video] Archive Playlist',
  UNARCHIVE_PLAYLIST = '[Video] Unarchive Playlist',
  UPDATE_FOLDERS = '[Video] Update Folders',
  UPDATE_FOLDERS_SUCCESS = '[Video] Update Folders Success',
  UPDATE_FOLDERS_FAILED = '[Video] Update Folders Failed',
  UPDATE_FOLDER = '[Video] Update Folder',
  UPDATE_FOLDER_SUCCESS = '[Video] Update Folder Success',
  UPDATE_FOLDER_FAILED = '[Video] Update Folder Failed',
  SAVE_FOLDER = '[Video] Save Folder',
  SAVE_FOLDER_SUCCESS = '[Video] Save Folder Success',
  SAVE_FOLDER_FAILED = '[Video] Save Folder Failed',
  DELETE_FOLDER = '[Video] Delete Folder',
  DELETE_FOLDER_SUCCESS = '[Video] Delete Folder Success',
  DELETE_FOLDER_FAILED = '[Video] Delete Folder Failed',
  ARCHIVE_FOLDER = '[Video] Archive Folder',
  UNARCHIVE_FOLDER = '[Video] Unarchive Folder',
  UPDATE_GENERATED_PLAYLISTS = '[Video] Update Generated Playlists',
  UPDATE_GENERATED_PLAYLISTS_SUCCESS = '[Video] Update Generated Playlists Success',
  UPDATE_GENERATED_PLAYLISTS_FAILED = '[Video] Update Generated Playlists Failed',
  UPDATE_GENERATED_PLAYLIST = '[Video] Update Generated Playlist',
  UPDATE_GENERATED_PLAYLIST_SUCCESS = '[Video] Update Generated Playlist Success',
  UPDATE_GENERATED_PLAYLIST_FAILED = '[Video] Update Generated Playlist Failed',
  CREATE_GENERATED_PLAYLIST = '[Video] Create Generated Playlist',
  CREATE_GENERATED_PLAYLIST_SUCCESS = '[Video] Create Generated Playlist Success',
  CREATE_GENERATED_PLAYLIST_FAILED = '[Video] Create Generated Playlist Failed',
  UPDATE_SAVED_VIDEOS_LOOKUP = '[Video] Update Saved Videos Lookup',
  UPDATE_SAVED_VIDEOS_LOOKUP_SUCCESS = '[Video] Update Saved Videos Lookup Success',
  UPDATE_SAVED_VIDEOS_LOOKUP_FAILED = '[Video] Update Saved Videos Lookup Failed',
  UPDATE_NAVIGATION = '[Video] Update Navigation',
  UPDATE_NAVIGATION_SUCCESS = '[Video] Update Navigation Success',
  UPDATE_NAVIGATION_FAILED = '[Video] Update Navigation Failed',
  UPDATE_EXPLORE_TYPE = '[Video] Update Explore Type',
  UPDATE_EXPLORE_TYPE_SUCCESS = '[Video] Update Explore Type Success',
  UPDATE_EXPLORE_TYPE_FAILED = '[Video] Update Explore Type Failed',
  UPDATE_EXPLORE_PLAYLIST = '[Video] Update Explore Playlist',
  UPDATE_EXPLORE_PLAYLIST_SUCCESS = '[Video] Update Explore Playlist Success',
  UPDATE_EXPLORE_PLAYLIST_FAILED = '[Video] Update Explore Playlist Failed',
  UPDATE_EXPLORE_GENERATED_PLAYLIST = '[Video] Update Explore Generated Playlist',
  UPDATE_EXPLORE_GENERATED_PLAYLIST_SUCCESS = '[Video] Update Explore Generated Playlist Success',
  UPDATE_EXPLORE_GENERATED_PLAYLIST_FAILED = '[Video] Update Explore Generated Playlist Failed',
  UPDATE_PLAYLIST_1 = '[Video] Update Playlist 1',
  UPDATE_PLAYLIST_1_SUCCESS = '[Video] Update Playlist 1 Success',
  UPDATE_PLAYLIST_1_FAILED = '[Video] Update Playlist 1 Failed',
  UPDATE_PLAYLIST_2 = '[Video] Update Playlist 2',
  UPDATE_PLAYLIST_2_SUCCESS = '[Video] Update Playlist 2 Success',
  UPDATE_PLAYLIST_2_FAILED = '[Video] Update Playlist 2 Failed',
  UPDATE_PLAYLIST_3 = '[Video] Update Playlist 3',
  UPDATE_PLAYLIST_3_SUCCESS = '[Video] Update Playlist 3 Success',
  UPDATE_PLAYLIST_3_FAILED = '[Video] Update Playlist 3 Failed',
  UPDATE_PLAYLIST_4 = '[Video] Update Playlist 4',
  UPDATE_PLAYLIST_4_SUCCESS = '[Video] Update Playlist 4 Success',
  UPDATE_PLAYLIST_4_FAILED = '[Video] Update Playlist 4 Failed',
  UPDATE_PLAYLIST_5 = '[Video] Update Playlist 5',
  UPDATE_PLAYLIST_5_SUCCESS = '[Video] Update Playlist 5 Success',
  UPDATE_PLAYLIST_5_FAILED = '[Video] Update Playlist 5 Failed',
  UPDATE_PLAYLIST_6 = '[Video] Update Playlist 6',
  UPDATE_PLAYLIST_6_SUCCESS = '[Video] Update Playlist 6 Success',
  UPDATE_PLAYLIST_6_FAILED = '[Video] Update Playlist 6 Failed',
  SAVE_VIDEO = '[Video] Save Video',
  SAVE_VIDEO_SUCCESS = '[Video] Save Video Success',
  SAVE_VIDEO_FAILED = '[Video] Save Video Failed',
  DELETE_VIDEO = '[Video] Delete Video',
  DELETE_VIDEO_SUCCESS = '[Video] Delete Video Success',
  DELETE_VIDEO_FAILED = '[Video] Delete Video Failed',
  VIDEO_POSITION_CHANGE = '[Video] Video Position Change',
  BULK_SAVE_VIDEOS = '[Video] Bulk Save Videos',
  BULK_SAVE_VIDEOS_SUCCESS = '[Video] Bulk Save Videos Success',
  BULK_SAVE_VIDEOS_FAILED = '[Video] Bulk Save Videos Failed',
  BULK_REMOVE_VIDEOS = '[Video] Bulk Remove Videos',
  BULK_REMOVE_VIDEOS_SUCCESS = '[Video] Bulk Remove Videos Success',
  BULK_REMOVE_VIDEOS_FAILED = '[Video] Bulk Remove Videos Failed',
  BULK_DELETE_VIDEOS = '[Video] Bulk Delete Videos',
  BULK_DELETE_VIDEOS_SUCCESS = '[Video] Bulk Delete Videos Success',
  BULK_DELETE_VIDEOS_FAILED = '[Video] Bulk Delete Videos Failed',
  SAVE_VIDEO_NOTES = '[Video] Save Video Notes',
  SAVE_VIDEO_NOTES_SUCCESS = '[Video] Save Video Notes Success',
  SAVE_VIDEO_NOTES_FAILED = '[Video] Save Video Notes Failed',
  SAVE_TAG = '[Video] Save Tag',
  SAVE_TAG_SUCCESS = '[Video] Save Tag Success',
  SAVE_TAG_FAILED = '[Video] Save Tag Failed',
  DELETE_TAG = '[Video] Delete Tag',
  DELETE_TAG_SUCCESS = '[Video] Delete Tag Success',
  DELETE_TAG_FAILED = '[Video] Delete Tag Failed',
  UPDATE_CLIP_INDEX = '[Video] Update Clip Index',
  UPDATE_CLIP_INDEX_SUCCESS = '[Video] Update Clip Index Success',
  UPDATE_CLIP_INDEX_FAILED = '[Video] Update Clip Index Failed',
  UPDATE_NOTES_SHOWN = '[Video] Update Notes Shown',
  UPDATE_NOTES_SHOWN_SUCCESS = '[Video] Update Notes Shown Success',
  UPDATE_NOTES_SHOWN_FAILED = '[Video] Update Notes Shown Failed',
  UPDATE_IS_SHUFFLED = '[Video] Update Is Shuffled',
  UPDATE_IS_SHUFFLED_SUCCESS = '[Video] Update Is Shuffled Success',
  UPDATE_IS_SHUFFLED_FAILED = '[Video] Update Is Shuffled Failed',
  UPDATE_SORT_COLUMN = '[Video] Update Sort Column',
  UPDATE_SORT_COLUMN_SUCCESS = '[Video] Update Sort Column Success',
  UPDATE_SORT_COLUMN_FAILED = '[Video] Update Sort Column Failed',
  UPDATE_SORT_DIRECTION = '[Video] Update Sort Direction',
  UPDATE_SORT_DIRECTION_SUCCESS = '[Video] Update Sort Direction Success',
  UPDATE_SORT_DIRECTION_FAILED = '[Video] Update Sort Direction Failed',
}

export class OpenSocket implements Action {
  public readonly type = ActionTypes.OPEN_SOCKET;
  constructor(public payload: any) { }
}

export class OpenSocketSuccess implements Action {
  public readonly type = ActionTypes.OPEN_SOCKET_SUCCESS;
  constructor(public payload: any) { }
}

export class OpenSocketFailed implements Action {
  public readonly type = ActionTypes.OPEN_SOCKET_FAILED;
  constructor(public payload: any) { }
}

export class UpdateChannelName implements Action {
  public readonly type = ActionTypes.UPDATE_CHANNEL_NAME;
  constructor(public payload: any) {}
}

export class UpdateChannelNameSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_CHANNEL_NAME_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateChannelNameFailed implements Action {
  public readonly type = ActionTypes.UPDATE_CHANNEL_NAME_FAILED;
  constructor(public payload: any) {}
}

export class RequestInitialData implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_DATA;
  constructor(public payload: any) {}
}

export class RequestInitialDataSuccess implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class RequestInitialDataFailed implements Action {
  public readonly type = ActionTypes.REQUEST_INITIAL_DATA_FAILED;
  constructor(public payload: any) {}
}

export class SaveAction implements Action {
  public readonly type = ActionTypes.SAVE_ACTION;
  constructor(public payload: any) {}
}

export class SaveActionSuccess implements Action {
  public readonly type = ActionTypes.SAVE_ACTION_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveActionFailed implements Action {
  public readonly type = ActionTypes.SAVE_ACTION_FAILED;
  constructor(public payload: any) {}
}

export class ReceiveAction implements Action {
  public readonly type = ActionTypes.RECEIVE_ACTION;
  constructor(public payload: any) {}
}

export class ReceiveActionSuccess implements Action {
  public readonly type = ActionTypes.RECEIVE_ACTION_SUCCESS;
  constructor(public payload: any) {}
}

export class ReceiveActionFailed implements Action {
  public readonly type = ActionTypes.RECEIVE_ACTION_FAILED;
  constructor(public payload: any) {}
}

export class UpdateSession implements Action {
  public readonly type = ActionTypes.UPDATE_SESSION;
  constructor(public payload: any) {}
}

export class UpdateSessionSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_SESSION_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateSessionFailed implements Action {
  public readonly type = ActionTypes.UPDATE_SESSION_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylists implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLISTS;
  constructor(public payload: any) {}
}

export class UpdatePlaylistsSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLISTS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylistsFailed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLISTS_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylist implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdatePlaylistSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylistFailed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_FAILED;
  constructor(public payload: any) {}
}

export class SavePlaylist implements Action {
  public readonly type = ActionTypes.SAVE_PLAYLIST;
  constructor(public payload: any, public chainID: any) {}
}

export class SavePlaylistSuccess implements Action {
  public readonly type = ActionTypes.SAVE_PLAYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class SavePlaylistFailed implements Action {
  public readonly type = ActionTypes.SAVE_PLAYLIST_FAILED;
  constructor(public payload: any) {}
}

export class DeletePlaylist implements Action {
  public readonly type = ActionTypes.DELETE_PLAYLIST;
  constructor(public payload: any, public chainID: any) {}
}

export class DeletePlaylistSuccess implements Action {
  public readonly type = ActionTypes.DELETE_PLAYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class DeletePlaylistFailed implements Action {
  public readonly type = ActionTypes.DELETE_PLAYLIST_FAILED;
  constructor(public payload: any) {}
}

export class UpdateFolders implements Action {
  public readonly type = ActionTypes.UPDATE_FOLDERS;
  constructor(public payload: any) {}
}

export class ArchivePlaylist implements Action {
  public readonly type = ActionTypes.ARCHIVE_PLAYLIST;
  constructor(public payload: any, public chainID: any) {}
}

export class UnarchivePlaylist implements Action {
  public readonly type = ActionTypes.UNARCHIVE_PLAYLIST;
  constructor(public payload: any, public chainID: any) {}
}

export class ArchiveFolder implements Action {
  public readonly type = ActionTypes.ARCHIVE_FOLDER;
  constructor(public payload: any, public chainID: any) {}
}

export class UnarchiveFolder implements Action {
  public readonly type = ActionTypes.UNARCHIVE_FOLDER;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdateFoldersSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_FOLDERS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateFoldersFailed implements Action {
  public readonly type = ActionTypes.UPDATE_FOLDERS_FAILED;
  constructor(public payload: any) {}
}

export class UpdateFolder implements Action {
  public readonly type = ActionTypes.UPDATE_FOLDER;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdateFolderSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateFolderFailed implements Action {
  public readonly type = ActionTypes.UPDATE_FOLDER_FAILED;
  constructor(public payload: any) {}
}

export class SaveFolder implements Action {
  public readonly type = ActionTypes.SAVE_FOLDER;
  constructor(public payload: any, public chainID: any) {}
}

export class SaveFolderSuccess implements Action {
  public readonly type = ActionTypes.SAVE_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveFolderFailed implements Action {
  public readonly type = ActionTypes.SAVE_FOLDER_FAILED;
  constructor(public payload: any) {}
}

export class DeleteFolder implements Action {
  public readonly type = ActionTypes.DELETE_FOLDER;
  constructor(public payload: any, public chainID: any) {}
}

export class DeleteFolderSuccess implements Action {
  public readonly type = ActionTypes.DELETE_FOLDER_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteFolderFailed implements Action {
  public readonly type = ActionTypes.DELETE_FOLDER_FAILED;
  constructor(public payload: any) {}
}

export class UpdateGeneratedPlaylists implements Action {
  public readonly type = ActionTypes.UPDATE_GENERATED_PLAYLISTS;
  constructor(public payload: any) {}
}

export class UpdateGeneratedPlaylistsSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_GENERATED_PLAYLISTS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateGeneratedPlaylistsFailed implements Action {
  public readonly type = ActionTypes.UPDATE_GENERATED_PLAYLISTS_FAILED;
  constructor(public payload: any) {}
}

export class UpdateGeneratedPlaylist implements Action {
  public readonly type = ActionTypes.UPDATE_GENERATED_PLAYLIST;
  constructor(public payload: any, public chainID) {}
}

export class UpdateGeneratedPlaylistSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_GENERATED_PLAYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateGeneratedPlaylistFailed implements Action {
  public readonly type = ActionTypes.UPDATE_GENERATED_PLAYLIST_FAILED;
  constructor(public payload: any) {}
}

export class CreateGeneratedPlaylist implements Action {
  public readonly type = ActionTypes.CREATE_GENERATED_PLAYLIST;
  constructor(public payload: any, public chainID: any) {}
}

export class CreateGeneratedPlaylistSuccess implements Action {
  public readonly type = ActionTypes.CREATE_GENERATED_PLAYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateGeneratedPlaylistFailed implements Action {
  public readonly type = ActionTypes.CREATE_GENERATED_PLAYLIST_FAILED;
  constructor(public payload: any) {}
}

export class UpdateSavedVideosLookup implements Action {
  public readonly type = ActionTypes.UPDATE_SAVED_VIDEOS_LOOKUP;
  constructor(public payload: any) {}
}

export class UpdateSavedVideosLookupSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_SAVED_VIDEOS_LOOKUP_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateSavedVideosLookupFailed implements Action {
  public readonly type = ActionTypes.UPDATE_SAVED_VIDEOS_LOOKUP_FAILED;
  constructor(public payload: any) {}
}

export class UpdateNavigation implements Action {
  public readonly type = ActionTypes.UPDATE_NAVIGATION;
  constructor(public payload: any) {}
}

export class UpdateNavigationSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_NAVIGATION_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateNavigationFailed implements Action {
  public readonly type = ActionTypes.UPDATE_NAVIGATION_FAILED;
  constructor(public payload: any) {}
}

export class UpdateExploreType implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_TYPE;
  constructor(public payload: any) {}
}

export class UpdateExploreTypeSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_TYPE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateExploreTypeFailed implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_TYPE_FAILED;
  constructor(public payload: any) {}
}

export class UpdateExplorePlaylist implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_PLAYLIST;
  constructor(public payload: any) {}
}

export class UpdateExplorePlaylistSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_PLAYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateExplorePlaylistFailed implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_PLAYLIST_FAILED;
  constructor(public payload: any) {}
}

export class UpdateExploreGeneratedPlaylist implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_GENERATED_PLAYLIST;
  constructor(public payload: any) {}
}

export class UpdateExploreGeneratedPlaylistSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_GENERATED_PLAYLIST_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateExploreGeneratedPlaylistFailed implements Action {
  public readonly type = ActionTypes.UPDATE_EXPLORE_GENERATED_PLAYLIST_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylist1 implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_1;
  constructor(public payload: any) {}
}

export class UpdatePlaylist1Success implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_1_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylist1Failed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_1_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylist2 implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_2;
  constructor(public payload: any) {}
}

export class UpdatePlaylist2Success implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_2_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylist2Failed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_2_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylist3 implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_3;
  constructor(public payload: any) {}
}

export class UpdatePlaylist3Success implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_3_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylist3Failed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_3_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylist4 implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_4;
  constructor(public payload: any) {}
}

export class UpdatePlaylist4Success implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_4_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylist4Failed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_4_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylist5 implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_5;
  constructor(public payload: any) {}
}

export class UpdatePlaylist5Success implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_5_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylist5Failed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_5_FAILED;
  constructor(public payload: any) {}
}

export class UpdatePlaylist6 implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_6;
  constructor(public payload: any) {}
}

export class UpdatePlaylist6Success implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_6_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePlaylist6Failed implements Action {
  public readonly type = ActionTypes.UPDATE_PLAYLIST_6_FAILED;
  constructor(public payload: any) {}
}

export class SaveVideo implements Action {
  public readonly type = ActionTypes.SAVE_VIDEO;
  constructor(public payload: any, public chainID: any) {}
}

export class SaveVideoSuccess implements Action {
  public readonly type = ActionTypes.SAVE_VIDEO_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveVideoFailed implements Action {
  public readonly type = ActionTypes.SAVE_VIDEO_FAILED;
  constructor(public payload: any) {}
}

export class DeleteVideo implements Action {
  public readonly type = ActionTypes.DELETE_VIDEO;
  constructor(public payload: any, public chainID: any) {}
}

export class DeleteVideoSuccess implements Action {
  public readonly type = ActionTypes.DELETE_VIDEO_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteVideoFailed implements Action {
  public readonly type = ActionTypes.DELETE_VIDEO_FAILED;
  constructor(public payload: any) {}
}

export class VideoPositionChange implements Action {
  public readonly type = ActionTypes.VIDEO_POSITION_CHANGE;
  constructor(public payload: any, public chainID: any) {}
}

export class BulkSaveVideos implements Action {
  public readonly type = ActionTypes.BULK_SAVE_VIDEOS;
  constructor(public payload: any, public chainID: any) {}
}

export class BulkSaveVideosSuccess implements Action {
  public readonly type = ActionTypes.BULK_SAVE_VIDEOS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkSaveVideosFailed implements Action {
  public readonly type = ActionTypes.BULK_SAVE_VIDEOS_FAILED;
  constructor(public payload: any) {}
}

export class BulkRemoveVideos implements Action {
  public readonly type = ActionTypes.BULK_REMOVE_VIDEOS;
  constructor(public payload: any, public chainID: any) {}
}

export class BulkRemoveVideosSuccess implements Action {
  public readonly type = ActionTypes.BULK_REMOVE_VIDEOS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkRemoveVideosFailed implements Action {
  public readonly type = ActionTypes.BULK_REMOVE_VIDEOS_FAILED;
  constructor(public payload: any) {}
}

export class BulkDeleteVideos implements Action {
  public readonly type = ActionTypes.BULK_DELETE_VIDEOS;
  constructor(public payload: any, public chainID: any) {}
}

export class BulkDeleteVideosSuccess implements Action {
  public readonly type = ActionTypes.BULK_DELETE_VIDEOS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkDeleteVideosFailed implements Action {
  public readonly type = ActionTypes.BULK_DELETE_VIDEOS_FAILED;
  constructor(public payload: any) {}
}

export class SaveVideoNotes implements Action {
  public readonly type = ActionTypes.SAVE_VIDEO_NOTES;
  constructor(public payload: any, public channelName: any) {}
}

export class SaveVideoNotesSuccess implements Action {
  public readonly type = ActionTypes.SAVE_VIDEO_NOTES_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveVideoNotesFailed implements Action {
  public readonly type = ActionTypes.SAVE_VIDEO_NOTES_FAILED;
  constructor(public payload: any) {}
}

export class UpdateClipIndex implements Action {
  public readonly type = ActionTypes.UPDATE_CLIP_INDEX;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdateClipIndexSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_CLIP_INDEX_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateClipIndexFailed implements Action {
  public readonly type = ActionTypes.UPDATE_CLIP_INDEX_FAILED;
  constructor(public payload: any) {}
}

export class UpdateNotesShown implements Action {
  public readonly type = ActionTypes.UPDATE_NOTES_SHOWN;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdateNotesShownSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_NOTES_SHOWN_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateNotesShownFailed implements Action {
  public readonly type = ActionTypes.UPDATE_NOTES_SHOWN_FAILED;
  constructor(public payload: any) {}
}

export class SaveTag implements Action {
  public readonly type = ActionTypes.SAVE_TAG;
  constructor(public payload: any, public chainID: any) {}
}

export class SaveTagSuccess implements Action {
  public readonly type = ActionTypes.SAVE_TAG_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveTagFailed implements Action {
  public readonly type = ActionTypes.SAVE_TAG_FAILED;
  constructor(public payload: any) {}
}

export class DeleteTag implements Action {
  public readonly type = ActionTypes.DELETE_TAG;
  constructor(public payload: any, public chainID: any) {}
}

export class DeleteTagSuccess implements Action {
  public readonly type = ActionTypes.DELETE_TAG_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteTagFailed implements Action {
  public readonly type = ActionTypes.DELETE_TAG_FAILED;
  constructor(public payload: any) {}
}

export class UpdateIsShuffled implements Action {
  public readonly type = ActionTypes.UPDATE_IS_SHUFFLED;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdateIsShuffledSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_IS_SHUFFLED_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateIsShuffledFailed implements Action {
  public readonly type = ActionTypes.UPDATE_IS_SHUFFLED_FAILED;
  constructor(public payload: any) {}
}

export class UpdateSortColumn implements Action {
  public readonly type = ActionTypes.UPDATE_SORT_COLUMN;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdateSortColumnSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_SORT_COLUMN_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateSortColumnFailed implements Action {
  public readonly type = ActionTypes.UPDATE_SORT_COLUMN_FAILED;
  constructor(public payload: any) {}
}

export class UpdateSortDirection implements Action {
  public readonly type = ActionTypes.UPDATE_SORT_DIRECTION;
  constructor(public payload: any, public chainID: any) {}
}

export class UpdateSortDirectionSuccess implements Action {
  public readonly type = ActionTypes.UPDATE_SORT_DIRECTION_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateSortDirectionFailed implements Action {
  public readonly type = ActionTypes.UPDATE_SORT_DIRECTION_FAILED;
  constructor(public payload: any) {}
}

export type Actions = OpenSocket | OpenSocketSuccess | OpenSocketFailed |
  UpdateChannelName | UpdateChannelNameSuccess | UpdateChannelNameFailed |
  RequestInitialData | RequestInitialDataSuccess | RequestInitialDataFailed |
  SaveAction | SaveActionSuccess | SaveActionFailed |
  ReceiveAction | ReceiveActionSuccess | ReceiveActionFailed |
  UpdateSession | UpdateSessionSuccess | UpdateSessionFailed |
  UpdatePlaylists | UpdatePlaylistsSuccess | UpdatePlaylistsFailed |
  UpdatePlaylist | UpdatePlaylistSuccess | UpdatePlaylistFailed |
  SavePlaylist | SavePlaylistSuccess | SavePlaylistFailed |
  UpdateFolders | UpdateFoldersSuccess | UpdateFoldersFailed |
  UpdateFolder | UpdateFolderSuccess | UpdateFolderFailed |
  SaveFolder | SaveFolderSuccess | SaveFolderFailed |
  UpdateGeneratedPlaylists | UpdateGeneratedPlaylistsSuccess | UpdateGeneratedPlaylistsFailed |
  UpdateGeneratedPlaylist | UpdateGeneratedPlaylistSuccess | UpdateGeneratedPlaylistFailed |
  CreateGeneratedPlaylist | CreateGeneratedPlaylistSuccess | CreateGeneratedPlaylistFailed |
  UpdateSavedVideosLookup | UpdateSavedVideosLookupSuccess | UpdateSavedVideosLookupFailed |
  UpdateNavigation | UpdateNavigationSuccess | UpdateNavigationFailed |
  UpdateExploreType | UpdateExploreTypeSuccess | UpdateExploreTypeFailed |
  SaveVideo | SaveVideoSuccess | SaveVideoFailed |
  BulkSaveVideos | BulkSaveVideosSuccess | BulkSaveVideosFailed |
  BulkRemoveVideos | BulkRemoveVideosSuccess | BulkRemoveVideosFailed |
  BulkDeleteVideos | BulkDeleteVideosSuccess | BulkDeleteVideosFailed |
  SaveVideoNotes | SaveVideoNotesSuccess | SaveVideoNotesFailed |
  SaveTag | SaveTagSuccess | SaveTagFailed |
  DeleteTag | DeleteTagSuccess | DeleteTagFailed |
  UpdateClipIndex | UpdateClipIndexSuccess | UpdateClipIndexFailed |
  UpdateNotesShown | UpdateNotesShownSuccess | UpdateNotesShownFailed |
  UpdateSortColumn | UpdateSortColumnSuccess | UpdateSortColumnFailed |
  UpdateSortDirection | UpdateSortDirectionSuccess | UpdateSortDirectionFailed |
  UpdateIsShuffled | UpdateIsShuffledSuccess | UpdateIsShuffledFailed;
