export interface ChannelState {
  isLoaded: boolean;
  channelName: string;
}

export interface SessionState {
  isLoaded: boolean;
  id: number;
  currentNavigation: string;
  currentExploreType: string;
  playlist1ID: any;
  playlist2ID: any;
  playlist3ID: any;
  playlist4ID: any;
  playlist5ID: any;
  playlist6ID: any;
  explorePlaylistID: any;
  generatedPlaylistID: any;
  exploreClipIndex: number;
  exploreSortColumn: string;
  exploreSortDirection: string;
  exploreTableFilters: any;
  exploreColumns: any;
  exploreIsShuffled: boolean;
  exploreIsNotesShown: boolean;
  queueClipIndex: number;
  queueSortColumn: string;
  queueSortDirection: string;
  queueTableFilters: any;
  queueColumns: any;
  queueIsShuffled: boolean;
  queueIsNotesShown: boolean;
  playlist1ClipIndex: number;
  playlist1SortColumn: string;
  playlist1SortDirection: string;
  playlist1TableFilters: any;
  playlist1Columns: any;
  playlist1IsShuffled: boolean;
  playlist1IsNotesShown: boolean;
  playlist2ClipIndex: number;
  playlist2SortColumn: string;
  playlist2SortDirection: string;
  playlist2TableFilters: any;
  playlist2Columns: any;
  playlist2IsShuffled: boolean;
  playlist2IsNotesShown: boolean;
  playlist3ClipIndex: number;
  playlist3SortColumn: string;
  playlist3SortDirection: string;
  playlist3TableFilters: any;
  playlist3Columns: any;
  playlist3IsShuffled: boolean;
  playlist3IsNotesShown: boolean;
  playlist4ClipIndex: number;
  playlist4SortColumn: string;
  playlist4SortDirection: string;
  playlist4TableFilters: any;
  playlist4Columns: any;
  playlist4IsShuffled: boolean;
  playlist4IsNotesShown: boolean;
  playlist5ClipIndex: number;
  playlist5SortColumn: string;
  playlist5SortDirection: string;
  playlist5TableFilters: any;
  playlist5Columns: any;
  playlist5IsShuffled: boolean;
  playlist5IsNotesShown: boolean;
  playlist6ClipIndex: number;
  playlist6SortColumn: string;
  playlist6SortDirection: string;
  playlist6TableFilters: any;
  playlist6Columns: any;
  playlist6IsShuffled: boolean;
  playlist6IsNotesShown: boolean;
}

export interface PlaylistsState {
  isLoaded: boolean;
  playlists: any[];
}

export interface FoldersState {
  isLoaded: boolean;
  folders: any[];
}

export interface GeneratedPlaylistsState {
  isLoaded: boolean;
  generatedPlaylists: any[];
}

export interface SavedVideosLookupState {
  isLoaded: boolean;
  synergyEventIDs: string[];
  nbaChanceIDs: string[];
  eagleChanceIDs: string[];
  savedVideosMetadata: any[];
}

export interface VideoState {
  channel: ChannelState;
  session: SessionState;
  playlists: PlaylistsState;
  folders: FoldersState;
  generatedPlaylists: GeneratedPlaylistsState;
  savedVideosLookup: SavedVideosLookupState;
};

export const channelInitialState: ChannelState = {
  isLoaded: false,
  channelName: null,
};

export const sessionInitialState: SessionState = {
  isLoaded: false,
  id: null,
  currentNavigation: null,
  currentExploreType: null,
  playlist1ID: null,
  playlist2ID: null,
  playlist3ID: null,
  playlist4ID: null,
  playlist5ID: null,
  playlist6ID: null,
  explorePlaylistID: null,
  generatedPlaylistID: null,
  exploreClipIndex: null,
  exploreSortColumn: null,
  exploreSortDirection: null,
  exploreTableFilters: null,
  exploreColumns: null,
  exploreIsShuffled: null,
  exploreIsNotesShown: null,
  queueClipIndex: null,
  queueSortColumn: null,
  queueSortDirection: null,
  queueTableFilters: null,
  queueColumns: null,
  queueIsShuffled: null,
  queueIsNotesShown: null,
  playlist1ClipIndex: null,
  playlist1SortColumn: null,
  playlist1SortDirection: null,
  playlist1TableFilters: null,
  playlist1Columns: null,
  playlist1IsShuffled: null,
  playlist1IsNotesShown: null,
  playlist2ClipIndex: null,
  playlist2SortColumn: null,
  playlist2SortDirection: null,
  playlist2TableFilters: null,
  playlist2Columns: null,
  playlist2IsShuffled: null,
  playlist2IsNotesShown: null,
  playlist3ClipIndex: null,
  playlist3SortColumn: null,
  playlist3SortDirection: null,
  playlist3TableFilters: null,
  playlist3Columns: null,
  playlist3IsShuffled: null,
  playlist3IsNotesShown: null,
  playlist4ClipIndex: null,
  playlist4SortColumn: null,
  playlist4SortDirection: null,
  playlist4TableFilters: null,
  playlist4Columns: null,
  playlist4IsShuffled: null,
  playlist4IsNotesShown: null,
  playlist5ClipIndex: null,
  playlist5SortColumn: null,
  playlist5SortDirection: null,
  playlist5TableFilters: null,
  playlist5Columns: null,
  playlist5IsShuffled: null,
  playlist5IsNotesShown: null,
  playlist6ClipIndex: null,
  playlist6SortColumn: null,
  playlist6SortDirection: null,
  playlist6TableFilters: null,
  playlist6Columns: null,
  playlist6IsShuffled: null,
  playlist6IsNotesShown: null,
};

export const playlistsInitialState: PlaylistsState = {
  isLoaded: false,
  playlists: [],
};

export const foldersInitialState: FoldersState = {
  isLoaded: false,
  folders: [],
};

export const generatedPlaylistsInitialState: GeneratedPlaylistsState = {
  isLoaded: false,
  generatedPlaylists: [],
};

export const savedVideosLookupInitialState: SavedVideosLookupState = {
  isLoaded: false,
  synergyEventIDs: [],
  nbaChanceIDs: [],
  eagleChanceIDs: [],
  savedVideosMetadata: []
}

export const initialState: VideoState = {
  channel: null,
  session: null,
  playlists: null,
  folders: null,
  generatedPlaylists: null,
  savedVideosLookup: null,
};
