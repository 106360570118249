import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, EventEmitter, HostListener, Inject, Input,
  OnDestroy,
  OnInit, Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {ErrorsSnackbarComponent} from '../../../_snackbars/errors-snackbar/errors-snackbar.component';
import {VideoService} from '@services/video.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {User} from '@models/users';
import {FormControl} from '@angular/forms';
import {AuthService} from '@services/auth.service';
import {AutocompleteService} from '@services/autocomplete.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {
  MatDialog, MatDialogConfig,
} from '@angular/material/dialog';
import {EntitiesService} from '@services/entities.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, ofType} from '@ngrx/effects';
import {VideoActionTypes} from '@models/video';
import {Store} from '@ngrx/store';
import {RootStoreState} from 'apps/_store';
import {Title} from '@angular/platform-browser';
import * as d3 from 'd3';
import * as _ from 'lodash';
import {SavedPlaylistDialogComponent} from '@bild-dialogs/saved-playlist/saved-playlist.component';
import {debounceTime, take} from 'rxjs/operators';
import {ConfirmDialogComponent} from '@bild-dialogs/confirm-dialog/confirm-dialog.component';
import {MatLegacyAutocomplete as MatAutocomplete} from '@angular/material/legacy-autocomplete';
import {environment} from '../../../../environments/environment';
import * as actions from '@store/video-store/actions';

@UntilDestroy()
@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('entitiesInput') entitiesInput;
  @ViewChild('hotKeyTrigger') hotKeyTrigger;
  @ViewChild('viewersInput') viewersInput;
  @ViewChild('video1') video1;
  @ViewChild('video2') video2;
  @ViewChild('savePanel') savePanel;
  @ViewChild('tagPanel') tagPanel;
  @ViewChild('timeDot') timeDot;
  @ViewChild('progressBar') progressBar;
  @ViewChild('videoControls') videoControls;
  @ViewChild('viewersAutocomplete') viewersAutocomplete: MatAutocomplete;
  @ViewChild('reportButton') reportButton;

  @Input() set currentClip1(val) {
    this._currentClip1 = val;
    this.tagSliderAutoUpdate = true;
    this.cdr.markForCheck();
  };

  get currentClip1() {
    return this._currentClip1;
  }

  @Input() set postFeedClip(val) {
    if (this.isPostFeed && this.postFeedClip && !_.isEqual(this.postFeedClip, val)) {
      this.logView();
      this.resetTimer();
    }
    this._postFeedClip = val;
    this.cdr.markForCheck();
  };

  get postFeedClip() {
    return this._postFeedClip;
  }

  @Input() set currentClip2(val) {
    this._currentClip2 = val;
    this.tagSliderAutoUpdate = true;
    this.cdr.markForCheck();
  };

  get currentClip2() {
    return this._currentClip2;
  }

  @Input() set isTableView(val) {
    if (val != this._isTableView) {
      this._isTableView = val;

      // We need a longer timeout here to account for the animation
      this.delayedTimeDotUpdate(500);
    }
  };

  get isTableView() {
    return this._isTableView;
  }

  @Input() set isFullScreen(val) {
    if (val != this._isFullScreen) {
      this._isFullScreen = val;

      this.delayedTimeDotUpdate();
    }
  };

  get isFullScreen() {
    return this._isFullScreen;
  }

  @Input() set clipsOnSide(val) {
    if (val != this._clipsOnSide) {
      this._clipsOnSide = val;

      this.delayedTimeDotUpdate(50, false);
    }
  };

  get clipsOnSide() {
    return this._clipsOnSide;
  }

  @Input() set currentEpv(val) {
    if (val) {
      this._currentEpv = val;
      this.cdr.markForCheck();
    }
  };

  get currentEpv() {
    return this._currentEpv;
  }

  @Input() set showEpv(val) {
    this._showEpv = val;
    if (!val && this.epvLineChartSvg && d3.select('#epv-linechart').select('svg').size() > 0) {
      this.epvLineChartSvg.remove();
      this.epvProgressLineChartSvg.remove();
    } else if (this.currentClip?.zelusData) {
      setTimeout((_) => {
        if (d3.select('#epv-linechart').select('svg').size() == 0) {
          this.addEpvLineChartSvg(this.currentClip.zelusData);
          this.setEpvProgressLineChart(this.currentClip.zelusData, this.currentZelusDataIndex);
        }
      });
    }
  };

  get showEpv() {
    return this._showEpv;
  }

  @Input() set isSaving(val) {
    if (this._isSaving != val) {
      this._isSaving = val;
      if (this._isSaving) {
        this.startTrimSeconds = this.currentClip.startTrim;
        this.endTrimSeconds = this.currentVideoDuration - this.currentClip.endTrim;
        this.trimSliderOptions.floor = 0;
        this.trimSliderOptions.ceil = this.currentVideoDuration;
      }
    }
  }

  get isSaving() {
    return this._isSaving;
  }

  @Input() set isTagging(val) {
    if (this._isTagging != val) {
      this._isTagging = val;
      if (this._isTagging) {
        this.trimSliderOptions.floor = (this.currentClip?.startTrim || 0);
        this.trimSliderOptions.ceil = this.currentVideoDuration - (this.currentClip?.endTrim || 0);
      }
    }
  }

  get isTagging() {
    return this._isTagging;
  }

  @Input() set tagEditing(val) {
    if (this._tagEditing != val) {
      this._tagEditing = val;
      if (this._tagEditing) {
        this.tagSliderAutoUpdate = false;
        if (this.tagEditing.startTimestamp && this.tagEditing.endTimestamp) {
          this.tagStartSeconds = (this.tagEditing.startTimestamp - this.currentClip.startTimestamp)/1000 + this.currentClip.startPadding;
          this.tagEndSeconds = (this.tagEditing.endTimestamp - this.currentClip.startTimestamp)/1000 + this.currentClip.startPadding;
        }
        else {
          this.tagStartSeconds = this.tagEditing.startSeconds;
          this.tagEndSeconds = this.tagEditing.endSeconds;
        }
      }
      else {
        this.tagSliderAutoUpdate = true;
      }
    }
  }

  get tagEditing() {
    return this._tagEditing;
  }

  @Input() avgClipEpv: number = -1.0;
  @Input() currentVideoIndex: number = 0;
  @Input() currentZelusDataIndex: number = -1;
  @Input() columns: string[] = [];
  @Input() displayedClips: any = [];
  @Input() defaultPadding: number = 0;
  @Input() displayingHalfCourt: boolean = false;
  @Input() epvProgressLineChartSvg;
  @Input() epvLineChartScaleX;
  @Input() epvLineChartScaleY;
  @Input() epvLineChartSvg;
  @Input() fullQuarters: boolean = false;
  @Input() heightOffset: number = 0;
  @Input() isDialog: boolean = false;
  @Input() isPostFeed: boolean = false;
  @Input() minClipEpv: number = -1.0;
  @Input() maxClipEpv: number = -1.0;
  @Input() playerTrackingScaleX;
  @Input() playerTrackingScaleY;
  @Input() simpleNavigation: boolean = false;
  @Input() playerTrackingSvg;
  @Input() postView: boolean = false;
  @Input() show2dCourt: boolean = false;
  @Input() savedClips: boolean = false;
  @Input() selectedClips: any[] = [];
  @Input() shouldAutoPlay: boolean = true;
  @Input() showAutoPlayToggle: boolean = false;
  @Input() showClipNumber: boolean = false;
  @Input() showDefenseHull: boolean = false;
  @Input() showEndPadding: boolean = false;
  @Input() showEvents: boolean = false;
  @Input() showInsights: boolean = false;
  @Input() showHotKeys: boolean = false;
  @Input() showNextButton: boolean = false;
  @Input() showOffenseHull: boolean = false;
  @Input() showPlayerTrails: boolean = false;
  @Input() showPreviousButton: boolean = false;
  @Input() showReportButton: boolean = false;
  @Input() showSaveButton: boolean = false;
  @Input() showSizeButton: boolean = false;
  @Input() showStartPadding: boolean = false;
  @Input() singleVideo: boolean = false;
  @Input() user: User;

  @Output() onCanPlay: EventEmitter<number> = new EventEmitter();
  @Output() onClipChange: EventEmitter<number> = new EventEmitter();
  @Output() onEditSavedClip: EventEmitter<any> = new EventEmitter();
  @Output() onMetadataLoaded: EventEmitter<number> = new EventEmitter();
  @Output() onReloadClipUrl: EventEmitter<number> = new EventEmitter();
  @Output() onSavingUpdate: EventEmitter<boolean> = new EventEmitter();
  // This is used for components which only have clip urls
  @Output() onSimpleNavigation: EventEmitter<boolean> = new EventEmitter();
  @Output() onToggleFullScreen: EventEmitter<number> = new EventEmitter();
  @Output() onToggleShowEpv: EventEmitter<number> = new EventEmitter();
  @Output() onToggleShow2dCourt: EventEmitter<number> = new EventEmitter();
  @Output() onTagSecondsChange: EventEmitter<number[]> = new EventEmitter();

  readonly skipDurationBuffer = 0.2;

  _currentClip1: any = null;
  _currentClip2: any = null;
  _postFeedClip: any = null;
  _currentEpv: number = -1.0;
  _clipsOnSide: boolean;
  _isFullScreen: boolean;
  _isTableView: boolean;
  _showEpv: boolean = false;
  _isSaving: boolean = false;
  _isTagging: boolean = false;
  _tagEditing: any = null;

  isCreatingPlaylist: boolean = false;
  isVideoPlayerFocused: boolean = false;
  isMobile: boolean;
  clips: any = [];
  selectedLeague: string = 'NBA';
  eventsVisibilityOptions = [true, false];
  initialVideoLoaded: boolean = false;
  isDotHovered: boolean = false
  isDotVisible: boolean = false
  isDragging: boolean = false
  isSavingMany: boolean = false;
  isPrivateOptionSelected = true;
  savingInProgress = false;
  isVideoPlaying: boolean = false;
  playPromise: any;
  isAutoplaying: boolean = true;
  volume = 0;
  paddingOptions = [...Array(31).keys()];
  playbackSpeed = 1;
  playbackSpeedOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
  eventsVisibility = false;
  currentVideoDuration1: number = 0;
  currentVideoDuration2: number = 0;
  currentVideoTime1: number = 0;
  currentVideoTime2: number = 0;
  timeDotLeft: number;
  timeDotTop: number;
  wasPlayingBeforeDrag: boolean = false;
  timeSkippingSecondsHeld: number = 0;
  timeSkippingInterval: NodeJS.Timer;
  awaitingAutoplayAvailability: boolean = true;
  enteredFullScreen = false;
  page: number = 1;
  pageSize: number = 100;
  isReportingIssue: boolean = false;
  reportClipFeedback: string = '';
  hotKeysShown: boolean = false;
  initialVideoDuration: number = 0;
  hotKeys: any[] = [
    {keys: ['SPACE'], action: 'Play/Pause'},
    {keys: ['V'], action: 'Forward 2 Sec'},
    {keys: ['C'], action: 'Back 2 Sec'},
    {keys: ['SHIFT', 'V'], action: 'Forward 5 Sec'},
    {keys: ['SHIFT', 'C'], action: 'Back 5 Sec'},
    {keys: ['F'], action: 'Speed Up'},
    {keys: ['D'], action: 'Normal Speed'},
    {keys: ['S'], action: 'Slow Down'},
    {keys: ['R'], action: 'Next'},
    {keys: ['E'], action: 'Previous'},
    {keys: ['M'], action: 'Mute/Unmute'},
    {keys: ['W'], action: 'Autoplay On/Off'},
    {keys: ['Q'], action: 'Full Screen On/Off'},
    {keys: ['G'], action: 'Save'},
    {keys: ['X'], action: 'Select Current Clip'},
  ];
  proLeagues = ['NBA', 'GLEAGUE'];
  isTabActive = true;

  userPlaylists: any[] = [];
  filteredUsers: any[];
  viewersControl: FormControl = new FormControl();
  filteredEntities: any[];
  entitiesControl: FormControl = new FormControl();

  startTrimSeconds = 0;
  endTrimSeconds = 100;
  trimSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 100,
    step: 0.1,
    translate: (value: number): string => value.toFixed(1),
    noSwitching: true,
    animate: false,
  };

  selectedTagType: any;
  tagPresets = [null, null, null, null, null, null, null, null, null, null];
  taggingInProgress: boolean = false;
  tagDefinitions: any[] = [];
  tagSliderAutoUpdate = true;
  tagStartSeconds = 0;
  tagEndSeconds = 100;
  tagSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 100,
    step: 0.1,
    noSwitching: true,
    animate: false,
  };

  isFilterPanelOpen: boolean = false;
  videoCaptionExpanded: boolean = false;

  isRunning: boolean = false;
  watchTime: number = 0;
  intervalId: any;
  startTime: number | null = null;

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {
    if (!this.isDialog || event.target.classList?.contains('mat-input-element')) {
      return;
    } else if ([' ', 'k'].includes(event.key)) {
      if (this.isVideoPlaying) {
        this.pauseVideo();
      } else {
        this.playVideo();
      }
    } else if (['m'].includes(event.key)) {
      if (this.volume == 0) {
        this.changeVolume(1);
      } else {
        this.changeVolume(0);
      }
    } else if (['q'].includes(event.key)) {
      this.toggleFullScreen();
    } else if (['w'].includes(event.key)) {
      this.isAutoplaying = !this.isAutoplaying;
    } else if (['e', ','].includes(event.key)) {
      this.determineClipNavigation(false);
    } else if (['r', '.'].includes(event.key)) {
      this.determineClipNavigation(true);
    } else if (['v', 'V', 'ArrowRight'].includes(event.key)) {
      clearInterval(this.timeSkippingInterval);
      this.timeSkippingInterval = null;
      this.timeSkippingSecondsHeld = 0;
    } else if (['c', 'C', 'ArrowLeft'].includes(event.key)) {
      clearInterval(this.timeSkippingInterval);
      this.timeSkippingInterval = null;
      this.timeSkippingSecondsHeld = 0;
    } else if (['f'].includes(event.key)) {
      this.changePlaybackSpeed(2);
    } else if (['d'].includes(event.key)) {
      this.changePlaybackSpeed(1);
    } else if (['s'].includes(event.key)) {
      this.changePlaybackSpeed(0.5);
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if (!this.isDialog || event.target.classList?.contains('mat-input-element')) {
      return;
    } else if (['v', 'V', 'ArrowRight'].includes(event.key)) {
      if (!this.timeSkippingInterval) {
        this.timeSkippingInterval = setInterval(() => {
          this.timeSkippingSecondsHeld += 1;
        }, 1000);
      }
      this.determineSkipBehavior(event);
    } else if (['c', 'C', 'ArrowLeft'].includes(event.key)) {
      if (!this.timeSkippingInterval) {
        this.timeSkippingInterval = setInterval(() => {
          this.timeSkippingSecondsHeld += 1;
        }, 1000);
      }
      this.changeCurrentTime(Math.max(this.video.nativeElement.currentTime - ((event.shiftKey ? 5 : 2) * (1.1 ** this.timeSkippingSecondsHeld)), 0));
    }
  }

  constructor(
    protected actions$: Actions,
    protected authService: AuthService,
    protected autocompleteService: AutocompleteService,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected entitiesService: EntitiesService,
    protected matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    protected snackBar: MatSnackBar,
    protected store$: Store<RootStoreState.State>,
    protected title: Title,
    protected videoService: VideoService,
  ) { }
  get currentVideoNumber() {
    return this.currentVideoIndex % 2 == 0 ? 1 : 2;
  }

  get nextVideoNumber() {
    return this.currentVideoIndex % 2 == 0 ? 2 : 1;
  }

  get video() {
    return this.currentVideoNumber == 1 ? this.video1 : this.video2;
  }

  get nextVideo() {
    return this.currentVideoNumber == 1 ? this.video2 : this.video1;
  }

  get currentClip() {
    return this.currentVideoNumber == 1 ? this.currentClip1 : this.currentClip2;
  }

  set currentClip(val) {
    if (this.currentVideoNumber == 1) {
      this.currentClip1 = val;
    } else {
      this.currentClip2 = val;
    }
  }

  get nextClip() {
    return this.currentVideoNumber == 1 ? this.currentClip2: this.currentClip1;
  }

  set nextClip(val) {
    if (this.currentVideoNumber == 1) {
      this.currentClip2 = val;
    } else {
      this.currentClip1 = val;
    }
  }

  get currentVideoDuration() {
    return this.currentVideoNumber == 1 ? this.currentVideoDuration1: this.currentVideoDuration2;
  }

  set currentVideoDuration(val) {
    if (this.currentVideoNumber == 1) {
      this.currentVideoDuration1 = val;
    } else {
      this.currentVideoDuration2 = val;
    }
  }

  set nextVideoDuration(val) {
    this.initialVideoDuration = Math.floor(this.currentVideoDuration) - this.currentClip?.startPadding - this.defaultPadding;
    if (this.currentVideoNumber == 1) {
      this.currentVideoDuration2 = val;
    } else {
      this.currentVideoDuration1 = val;
    }
  }

  get currentVideoTime() {
    return this.currentVideoNumber == 1 ? this.currentVideoTime1: this.currentVideoTime2;
  }

  set currentVideoTime(val) {
    if (this.currentVideoNumber == 1) {
      this.currentVideoTime1 = val;
    } else {
      this.currentVideoTime2 = val;
    }
  }

  get nextVideoTime() {
    return this.currentVideoNumber == 1 ? this.currentVideoTime2: this.currentVideoTime1;
  }

  set nextVideoTime(val) {
    if (this.currentVideoNumber == 1) {
      this.currentVideoTime2 = val;
    } else {
      this.currentVideoTime1 = val;
    }
  }

  get trimmedVideoDuration() {
    return this.video?.nativeElement?.duration - (this.currentClip?.startTrim || 0) - (this.currentClip?.endTrim || 0);
  }

  ngOnInit() {
    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small,
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.viewersControl.valueChanges.pipe(
        debounceTime(environment.typingDebounceTime),
        untilDestroyed(this) )
        .subscribe(
            (q) => {
              this.filterUsers(q);
            },
        );

    document.addEventListener('visibilitychange', () => {
      this.isTabActive = document.visibilityState === 'visible';
    });
  }

  ngAfterViewInit() {
    this.videoService.savedPlaylistsSubject.pipe(untilDestroyed(this)).subscribe(
        (savedPlaylists) => {
          this.userPlaylists = _.cloneDeep(savedPlaylists);
        },
    );

    this.videoService.videoTagLookupSubject.pipe(untilDestroyed(this)).subscribe(
        (tagDefinitions) => {
          this.tagDefinitions = tagDefinitions;
        },
    );

    this.actions$.pipe(
      ofType<actions.SaveAction>(actions.ActionTypes.SAVE_ACTION),
      untilDestroyed(this),
    ).subscribe(({payload}) => {
      if (payload[VideoActionTypes.CLIP_INDEX_CHANGE]) {
        if (this.currentClip) {
          this.logView();
        }
        this.resetTimer();
      }
      this.cdr.markForCheck();
    });
  }

  getNextAvailableVideoIndex(startIndex, direction) {
    if (direction == 'next') {
      for (let i = startIndex + 1; i < this.displayedClips.length; i++) {
        if (this.displayedClips[i].clipAvailable || this.displayedClips[i].url) {
          return i;
        }
      }
    } else {
      for (let i = startIndex - 1; i >= 0; i--) {
        if (this.displayedClips[i].clipAvailable || this.displayedClips[i].url) {
          return i;
        }
      }
    }
  }

  playVideo() {
    setTimeout(() => {
      this.playPromise = this.video.nativeElement.play();
      if (this.playPromise !== undefined) {
        this.playPromise
          .then(() => {
            this.isVideoPlaying = true;
            this.startTimer();
          })
          .catch(error => {
            console.log(error);
            this.isVideoPlaying = false;
          });
      } else {
        this.isVideoPlaying = true;
        this.startTimer();
      }
      this.video.nativeElement.volume = this.volume;
    }, 50);
  }

  pauseVideo() {
    if (this.playPromise !== undefined) {
      this.playPromise
        .then(() => {
          this.video.nativeElement.pause();
          this.isVideoPlaying = false;
          this.stopTimer();
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      this.video.nativeElement.pause();
      this.isVideoPlaying = false;
      this.stopTimer();
    }
  }

  startTimer() {
    if (!this.isRunning) {
      this.isRunning = true;
      this.startTime = performance.now();
      this.intervalId = setInterval(() => {
        if (this.startTime !== null) {
          const currentTime = performance.now();
          this.watchTime += (currentTime - this.startTime) / 1000;
          this.startTime = currentTime;
        }
      }, 10);
    }
  }
  
  stopTimer() {
    if (this.isRunning) {
      this.isRunning = false;
      if (this.startTime !== null) {
        const currentTime = performance.now();
        this.watchTime += (currentTime - this.startTime) / 1000;
      }
      clearInterval(this.intervalId);
      this.startTime = null;
    }
  }
  
  resetTimer() {
    this.watchTime = 0;
    this.isRunning = false;
    clearInterval(this.intervalId);
    this.startTime = null;
  }

  logView() {
    let viewedClip = this.isPostFeed ? this.postFeedClip : this.currentClip;
    if (!viewedClip) return;
    let viewData = {
      league: viewedClip.league ?? null,
      nbaGameID: viewedClip.nbaGameID ?? null,
      synergyGameID: viewedClip.synergyGameID ?? null,
      period: viewedClip.period ?? null,
      secondsLeftInPeriod: viewedClip.startGameclock ?? null,
      endPadding: this.proLeagues.includes(viewedClip.league) ? viewedClip.endPadding : viewedClip.startPadding,
      eagleChanceID: viewedClip.eagleChanceID ?? null,
      synergyEventID: viewedClip.synergyEventID ?? null,
      viewDuration: this.watchTime ? parseFloat(this.watchTime.toFixed(2)) : null,
      nbaChanceID: viewedClip.nbaChanceID ?? null,
      isActiveTab: this.isTabActive,
      savedVideoID: viewedClip.id ?? null,
    };
    this.store$.dispatch(new actions.SaveAction({'viewData': viewData, 'chainID': this.videoService.generateChainID()}));
  }

  changeVolume(volume) {
    this.volume = volume;
    this.video.nativeElement.volume = this.volume;
  }

  changePlaybackSpeed(playbackSpeed) {
    this.playbackSpeed = playbackSpeed;
    this.video1.nativeElement.playbackRate = this.playbackSpeed;
    this.video2.nativeElement.playbackRate = this.playbackSpeed;
  }

  changeCurrentTime(currentTime) {
    this.currentVideoTime = currentTime;
    this.video.nativeElement.currentTime = this.currentVideoTime;

    this.currentEpv = -1;
    this.currentZelusDataIndex = -1;
  }

  changeNextVideoTime(currentTime) {
    this.nextVideoTime = currentTime;
    this.nextVideo.nativeElement.currentTime = this.nextVideoTime;
  }

  onVideoMetadataLoaded(videoNumber) {
    if (videoNumber == this.currentVideoNumber) {
      this.currentVideoDuration = this.video.nativeElement.duration;
    } else {
      this.nextVideoDuration = this.nextVideo.nativeElement.duration;
    }
    this.onMetadataLoaded.emit(1);
  }

  hideTimeDot(event) {
    this.isDotVisible = false;
  }

  get dotVerticalPosition() {
    let verticalOffset = this.timeDotTop;
    if (this.isDotHovered) {
      verticalOffset -= 2;
    }

    return verticalOffset + 'px';
  }

  get dotHorizontalPosition() {
    let horizontalOffset = this.timeDotLeft;
    if (this.isDotHovered) {
      horizontalOffset -= 2;
    }

    return horizontalOffset + 'px';
  }

  mouseEnterDot() {
    this.isDotHovered = true;
    this.isDotVisible = true;
  }

  mouseLeaveDot() {
    this.isDotHovered = false;
    this.isDotVisible = false;
  }

  mouseLeavePlayer() {
    this.endDragging();
  }

  onVideoCanPlay(videoNumber) {
    if (videoNumber == this.currentVideoNumber) {
      this.changePlaybackSpeed(this.playbackSpeed);
      this.changeVolume(this.volume);
      if (this.awaitingAutoplayAvailability && !this.wasPlayingBeforeDrag && this.shouldAutoPlay) {
        if (this.currentClip.smoothingTrim && Math.round(this.video.nativeElement.currentTime) != Math.round(this.currentClip.smoothingTrim)) {
          this.changeCurrentTime(this.currentClip.smoothingTrim);
        }
        this.playVideo();
        this.awaitingAutoplayAvailability = false;
      } else if (this.isVideoPlaying && !this.wasPlayingBeforeDrag) {
        this.playVideo();
      }
    } else {
      if (this.nextClip.smoothingTrim && Math.round(this.nextVideo.nativeElement.currentTime) != Math.round(this.nextClip.smoothingTrim)) {
        this.changeNextVideoTime(this.nextClip.smoothingTrim);
      }
    }
    if (!this.initialVideoLoaded) {
      this.updateTimeDotLocation();
    }
    this.initialVideoLoaded = true;

    this.updateZelusData();
    this.onCanPlay.emit();
  }

  updateZelusData() {
    if (this.currentClip?.zelusData) {
      if (this.showEpv) {
        this.addEpvLineChartSvg(this.currentClip.zelusData);
        this.setEpvProgressLineChart(this.currentClip.zelusData, this.currentZelusDataIndex);
      }

      if (this.show2dCourt && d3.select('#court-plot').select('svg').size() == 0) {
        this.drawPlayerTracking();
      }
    }
  }

  delayedTimeDotUpdate(timeout = 50, updateLeftSide = true) {
    setTimeout(() => {
      this.updateTimeDotLocation(updateLeftSide);
      this.cdr.markForCheck();
    }, timeout);
  }

  updateTimeDotLocation(updateLeftSide = true) {
    this.timeDotTop = this.video.nativeElement.clientHeight - this.heightOffset;
    if (this.currentVideoTime && updateLeftSide) {
      const outerProgressBarElement = this.video.nativeElement.closest('.video-outer').querySelector('.progress-bar-outer');
      const offsetLeft = outerProgressBarElement.offsetLeft;
      const containerWidth = this.video.nativeElement.clientWidth - 2 * offsetLeft;
      const halfDotWidthOffset = 6;
      this.timeDotLeft = (this.isSaving ? this.currentVideoTime : Math.min(this.trimmedVideoDuration, this.currentVideoTime - (this.currentClip?.startTrim || 0))) / (this.isSaving ? this.video.nativeElement.duration : this.trimmedVideoDuration) * containerWidth + offsetLeft - halfDotWidthOffset;
    } else if (updateLeftSide) {
      this.timeDotLeft = this.video.nativeElement.closest('.video-outer').querySelector('.progress-bar-outer').offsetLeft;
    }
    this.cdr.markForCheck();
  }

  onCancelSaveButtonClicked(isBulk = false) {
    if (this.isDialog) {
      this.onSaveButtonClicked(isBulk);
    } else {
      this.onSavingUpdate.emit(false);
    }
  }

  changeVideoStartPadding(startPadding) {
    this.currentClip.startPadding = startPadding;
    this.pauseVideo();
    this.onReloadClipUrl.emit(null);
  }

  changeVideoEndPadding(endPadding) {
    this.currentClip.endPadding = endPadding;
    this.pauseVideo();
    this.onReloadClipUrl.emit(null);
  }

  changeShowEpv(showEpv) {
    this.onToggleShowEpv.emit(showEpv);
  }

  changeShow2dCourt(show2dCourt) {
    this.onToggleShow2dCourt.emit(show2dCourt);
  }

  onEditExistingSaveButtonClicked($event) {
    this.isSaving = !this.isSaving;
    if (this.isDialog) {
      this.currentClip = Object.assign(Object.assign({}, this.currentClip), $event);
      this.currentClip.additionalTaggedEntities = [];
      this.isPrivateOptionSelected = this.currentClip.sharedUsers.length == 0;
      this.currentClip.playersTagged.forEach((taggedPlayer) => {
        let isTaggedPlayerOnCourt = false;
        this.currentClip.onCourtEntities.forEach((onCourtEntity) => {
          if (taggedPlayer.id == onCourtEntity.id) {
            onCourtEntity.selected = true;
            isTaggedPlayerOnCourt = true;
          }
        });
        if (!isTaggedPlayerOnCourt) {
          this.currentClip.additionalTaggedEntities.push(taggedPlayer);
        }
      });
      this.startTrimSeconds = this.currentClip.startTrim;
      this.endTrimSeconds = this.currentClip.startPadding + this.currentClip.endPadding - this.currentClip.endTrim;
      this.trimSliderOptions.floor = 0;
      this.trimSliderOptions.ceil = this.currentClip.startPadding + this.currentClip.endPadding;
      this.onTrimStartChange(this.startTrimSeconds);
      this.onTrimEndChange(this.endTrimSeconds);
      if (this.isSaving) {
        // this.savePanel.nativeElement.style.opacity = 1;
        // this.savePanel.nativeElement.style.zIndex = 0;
        this.onResizeSavePanelSliderChange({value: 0});
      } else {
        // this.savePanel?.nativeElement.removeAttribute('style');
      }
      this.onEditSavedClip.emit($event);
    } else {
      this.onEditSavedClip.emit($event);
    }
  }

  determineSkipBehavior(event: KeyboardEvent) {
    const newTime = this.video.nativeElement.currentTime + ((event.shiftKey ? 5 : 2) * (1.1 ** this.timeSkippingSecondsHeld));
    if (newTime >= this.video.nativeElement.duration - this.skipDurationBuffer) {
      this.determineClipNavigation(true);
    } else {
      this.changeCurrentTime(newTime);
    }
  }

  determineClipNavigation(isNext: boolean) {
    if (isNext) {
      this.shouldAutoPlay = true;
    }
    if (this.simpleNavigation) {
      this.onSimpleNavigation.emit(isNext);
    } else {
      const direction = isNext ? 'next' : 'previous';
      this.changeToClip(this.getNextAvailableVideoIndex(this.currentVideoIndex, direction));
    }
  }

  changeToClip(videoIndex) {
    this.initialVideoDuration = Math.floor(this.currentVideoDuration) - this.currentClip.startPadding;
    this.onClipChange.emit(videoIndex);
  }

  onTimeDotDrag(dragEvent) {
    if (this.isDragging) {
      const progressBarBounds = this.progressBar.nativeElement.getBoundingClientRect();
      const offsetX = dragEvent.clientX - progressBarBounds.left;
      if (this.isSaving) {
        this.changeCurrentTime(this.video.nativeElement.duration * Math.min(1, Math.max(0, (offsetX / progressBarBounds.width))));
      } else {
        this.changeCurrentTime((this.currentClip?.startTrim || 0) + this.trimmedVideoDuration * Math.min(1, Math.max(0, (offsetX / progressBarBounds.width))));
      }
      this.updateTimeDotLocation();
    }
  }

  endDragging() {
    this.isDragging = false;
    if (this.wasPlayingBeforeDrag) {
      this.playVideo();
      this.wasPlayingBeforeDrag = false;
    }
  }

  startDragging() {
    this.isDragging = true;
    if (this.isVideoPlaying) {
      this.wasPlayingBeforeDrag = true;
      this.pauseVideo();
    }
  }

  toggleCourtOrientation(): void {
    this.displayingHalfCourt = !this.displayingHalfCourt;
    setTimeout((_) => {
      this.drawPlayerTracking();
      this.updatePlayerTracking(this.currentZelusDataIndex);
    });
  }

  togglePlayerTrails(): void {
    this.showPlayerTrails = !this.showPlayerTrails;

    for (let i = 1; i <= 5; i++) {
      this.playerTrackingSvg.select('#o'+i+'-trail')
          .transition()
          .attr('opacity', this.showPlayerTrails ? 1 : 0);
    }
  }

  toggleDefenseHull(): void {
    this.showDefenseHull = !this.showDefenseHull;

    this.playerTrackingSvg.select('#defense-hull')
        .transition()
        .attr('opacity', this.showDefenseHull ? 1 : 0);
  }

  toggleOffenseHull(): void {
    this.showOffenseHull = !this.showOffenseHull;

    this.playerTrackingSvg.select('#offense-hull')
        .transition()
        .attr('opacity', this.showOffenseHull ? 1 : 0);
  }

  arc(radius, start, end) {
    const points = [...Array(30)].map((d, i) => i);

    const angle = d3.scaleLinear()
        .domain([0, points.length - 1])
        .range([start, end]);

    const line = d3.lineRadial()
        .radius(radius)
        .angle((d, i) => angle(i));

    return line(points);
  }

  drawHalfcourtLines(g, x, y) {
    const pi = Math.PI / 180;
    const threeAngle = Math.atan( (10 - 0.75) / 22 ) * 180 / Math.PI;
    const basket = y(47-4);
    const basketRadius = y(47-4.75) - basket;

    g.select('#leftcourt-3-pt-arc')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-corner')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-corner-2')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-paint')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-restricted-area')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-free-throw')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-ft-dotted')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-basket')
        .transition()
        .attr('opacity', 0);
    g.select('#leftcourt-backboard')
        .transition()
        .attr('opacity', 0);

    // 3-point arc
    g.select('#rightcourt-3-pt-arc')
        .transition()
        .attr('opacity', 0)
        .attr('transform', `translate(${[x(0), basket + basketRadius]})`)
        .attr('d', this.arc(y(47-23.75), (threeAngle + 90) * pi, (270 - threeAngle) * pi))
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // 3-point lines
    g.select('#rightcourt-corner')
        .transition()
        .attr('opacity', 0)
        .attr('x1', x(-21.775))
        .attr('y1', y(47))
        .attr('x2', x(-21.775))
        .attr('y2', y(47-14))
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    g.select('#rightcourt-corner-2')
        .transition()
        .attr('opacity', 0)
        .attr('x1', x(21.775))
        .attr('y1', y(47))
        .attr('x2', x(21.775))
        .attr('y2', y(47-14))
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // paint
    g.select('#rightcourt-paint')
        .transition()
        .attr('opacity', 0)
        .attr('x', x(-8))
        .attr('y', y(47))
        .attr('width', x(8) - x(-8))
        .attr('height', y(47-15) + basket)
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // restricted area
    g.select('#rightcourt-restricted-area')
        .transition()
        .attr('opacity', 0)
        .attr('d', this.arc(x(4) - x(0), 90 * pi, 270 * pi))
        .attr('transform', `translate(${[x(0), basket]})`)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // freethrow
    g.select('#rightcourt-free-throw')
        .transition()
        .attr('opacity', 0)
        .attr('d', this.arc(x(6) - x(0), 90 * pi, 270 * pi))
        .attr('transform', `translate(${[x(0), y(47-15) + basket]})`)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // freethrow dotted
    g.select('#rightcourt-ft-dotted')
        .transition()
        .attr('opacity', 0)
        .attr('d', this.arc(x(6) - x(0), -90 * pi, 90 * pi))
        .attr('stroke-dasharray', '10,10')
        .attr('transform', `translate(${[x(0), y(47-15) + basket]})`)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // basket
    g.select('#rightcourt-basket')
        .transition()
        .attr('opacity', 0)
        .attr('r', basketRadius)
        .attr('cx', x(0))
        .attr('cy', y(47-4.75))
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // backboard
    g.select('#rightcourt-backboard')
        .transition()
        .attr('opacity', 0)
        .attr('x', x(-3))
        .attr('y', basket)
        .attr('width', x(3) - x(-3))
        .attr('height', 1)
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // half court outer
    g.append('path')
        .transition()
        .attr('opacity', 0)
        .attr('transform', `translate(${[x(0), y(0)]})`)
        .attr('d', this.arc(x(6) - x(0), -90 * pi, 90 * pi))
        .transition()
        .attr('opacity', 1)
        .attr('id', 'rightcourt-halfcourt-outer')
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // half court inner
    g.append('path')
        .transition()
        .attr('opacity', 0)
        .attr('transform', `translate(${[x(0), y(0)]})`)
        .attr('d', this.arc(x(2) - x(0), -90 * pi, 90 * pi))
        .transition()
        .attr('opacity', 1)
        .attr('id', 'rightcourt-halfcourt-inner')
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // half court line
    g.select('#halfcourt-line')
        .transition()
        .attr('opacity', 0)
        .attr('x1', x(-25))
        .attr('y1', y(0))
        .attr('x2', x(25))
        .attr('y2', y(0))
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');
  }

  drawFullcourtLines(g, x, y) {
    const pi = Math.PI / 180;
    const threeAngle = -180 + Math.atan( (10 - 0.75) / 22 ) * 180 / Math.PI;
    let basket = x(4-47);
    const basketRadius = x(4.75-47) - basket;

    // half court outer
    g.append('circle')
        .transition()
        .attr('opacity', 0)
        .attr('r', y(6)-y(0))
        .attr('cx', x(0))
        .attr('cy', y(0))
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white')
        .transition()
        .attr('opacity', 1)
        .attr('id', 'rightcourt-halfcourt-outer');

    // half court inner
    g.append('circle')
        .transition()
        .attr('opacity', 0)
        .attr('r', y(2)-y(0))
        .attr('cx', x(0))
        .attr('cy', y(0))
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white')
        .transition()
        .attr('opacity', 1)
        .attr('id', 'rightcourt-halfcourt-inner');

    // half court line
    g.select('#halfcourt-line')
        .transition()
        .attr('opacity', 0)
        .attr('x1', x(0))
        .attr('y1', y(-25))
        .attr('x2', x(0))
        .attr('y2', y(25))
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white')
        .transition()
        .attr('opacity', 1);

    // left 3-point arc
    g.select('#leftcourt-3-pt-arc')
        .transition()
        .attr('d', this.arc(x(23.75-47), (threeAngle + 180) * pi, (315 + threeAngle) * pi))
        .attr('transform', `translate(${[basket + basketRadius, y(0)]})`)
        .attr('fill-opacity', 0)
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // left 3-point lines
    g.select('#leftcourt-corner')
        .transition()
        .attr('y1', y(-21.775))
        .attr('y2', y(-21.775))
        .attr('x2', x(14-47))
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    g.select('#leftcourt-corner-2')
        .transition()
        .attr('y1', y(21.775))
        .attr('y2', y(21.775))
        .attr('x2', x(14-47))
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // left outer paint
    g.select('#leftcourt-paint')
        .transition()
        .attr('x', x(-47))
        .attr('y', y(-8))
        .attr('height', y(8) - y(-8))
        .attr('width', x(15-47) + basket)
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // left restricted area
    g.select('#leftcourt-restricted-area')
        .transition()
        .attr('d', this.arc(y(4) - y(0), 0 * pi, 180 * pi))
        .attr('transform', `translate(${[basket, y(0)]})`)
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // left freethrow
    g.select('#leftcourt-free-throw')
        .transition()
        .attr('d', this.arc(y(6) - y(0), 0 * pi, 180 * pi))
        .attr('transform', `translate(${[x(15-47) + basket, y(0)]})`)
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // left freethrow dotted
    g.select('#leftcourt-ft-dotted')
        .transition()
        .attr('d', this.arc(y(6) - y(0), 180 * pi, 360 * pi))
        .attr('stroke-dasharray', '10,10')
        .attr('transform', `translate(${[x(15-47) + basket, y(0)]})`)
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // left basket
    g.select('#leftcourt-basket')
        .transition()
        .attr('r', basketRadius)
        .attr('cx', x(4.75-47))
        .attr('cy', y(0))
        .attr('stroke-opacity', 0.7)
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // left backboard
    g.select('#leftcourt-backboard')
        .transition()
        .attr('y', y(-3))
        .attr('x', basket)
        .attr('height', y(3) - y(-3))
        .attr('width', 1)
        .attr('opacity', 0)
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    basket = x(47-4);

    // right 3-point arc
    g.select('#rightcourt-3-pt-arc')
        .transition()
        .attr('opacity', 0)
        .attr('d', this.arc(x(23.75-47), -(threeAngle + 315) * pi, -(180 + threeAngle) * pi))
        .attr('transform', `translate(${[basket-basketRadius, y(0)]})`)
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // right 3-point lines
    g.select('#rightcourt-corner')
        .transition()
        .attr('opacity', 0)
        .attr('y1', y(-21.775))
        .attr('x1', x(47))
        .attr('y2', y(-21.775))
        .attr('x2', x(47-14))
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    g.select('#rightcourt-corner-2')
        .transition()
        .attr('opacity', 0)
        .attr('y1', y(21.775))
        .attr('x1', x(47))
        .attr('y2', y(21.775))
        .attr('x2', x(47-14))
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // right paint
    g.select('#rightcourt-paint')
        .transition()
        .attr('opacity', 0)
        .attr('x', x(47-15-4))
        .attr('y', y(-8))
        .attr('height', y(8) - y(-8))
        .attr('width', x(15+4-47))
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    // right restricted area
    g.select('#rightcourt-restricted-area')
        .transition()
        .attr('opacity', 0)
        .attr('d', this.arc(y(4) - y(0), 180 * pi, 360 * pi))
        .attr('transform', `translate(${[basket, y(0)]})`)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // right freethrow
    g.select('#rightcourt-free-throw')
        .transition()
        .attr('opacity', 0)
        .attr('d', this.arc(y(6) - y(0), 180 * pi, 360 * pi))
        .attr('transform', `translate(${[x(47-15-4), y(0)]})`)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // right freethrow dotted
    g.select('#rightcourt-ft-dotted')
        .transition()
        .attr('opacity', 0)
        .attr('d', this.arc(y(6) - y(0), 0 * pi, 180 * pi))
        .attr('stroke-dasharray', '10,10')
        .attr('transform', `translate(${[x(47-15-4), y(0)]})`)
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // right basket
    g.select('#rightcourt-basket')
        .transition()
        .attr('opacity', 0)
        .attr('r', basketRadius)
        .attr('cx', x(47-4.75))
        .attr('cy', y(0))
        .transition()
        .attr('opacity', 1)
        .attr('stroke-opacity', 0.7)
        .attr('fill-opacity', 0)
        .attr('stroke', 'white');

    // right backboard
    g.select('#rightcourt-backboard')
        .transition()
        .attr('opacity', 0)
        .attr('y', y(-3))
        .attr('x', basket)
        .attr('height', y(3) - y(-3))
        .attr('width', 1)
        .transition()
        .attr('opacity', 1)
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white');

    g.append('line')
        .attr('opacity', 0)
        .attr('x1', x(-47))
        .attr('y1', y(25))
        .attr('x2', x(47))
        .attr('y2', y(25))
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0.7)
        .attr('stroke', 'white')
        .attr('id', 'bottom-border')
        .transition()
        .attr('opacity', 1);
  }

  addEpvLineChartSvg(zelusData): void {
    const width = +d3.select('#epv-linechart').node().getBoundingClientRect().width;
    const height = +d3.select('#epv-linechart').node().getBoundingClientRect().height;

    if (d3.select('#epv-linechart').select('svg').size() == 0) {
      this.epvLineChartSvg = d3.select('#epv-linechart')
          .append('svg')
          .attr('width', width)
          .attr('height', height);

      this.epvLineChartSvg
          .append('g')
          .append('path');
    }

    const epvValuesExtent = d3.extent(zelusData.map(function(d) {
      return d.epv;
    }));
    this.minClipEpv = epvValuesExtent[0];
    this.maxClipEpv = epvValuesExtent[1];
    this.avgClipEpv = d3.mean(zelusData.map(function(d) {
      return d.epv;
    }));

    const x = d3.scaleLinear()
        .domain([this.currentClip.startTimestamp - 1000*this.currentClip.startPadding, this.currentClip.startTimestamp - 1000*this.currentClip.startPadding + 1000*this.currentVideoDuration])
        .range([0, width]);

    const y = d3.scaleLinear()
        .domain(epvValuesExtent)
        .range([height-10, 10]);

    this.epvLineChartSvg.select('path')
        .datum(zelusData)
        .attr('fill', 'none')
        .attr('stroke', 'white')
        .attr('stroke-width', 4.0)
        .attr('stroke-opacity', 0.7)
        .attr('d', d3.line()
            .x(function(d) {
              return x(d.wall_clock);
            })
            .y(function(d) {
              return y(d.epv);
            }),
        );

    this.epvLineChartScaleX = x;
    this.epvLineChartScaleY = y;
  }

  setEpvProgressLineChart(zelusData, currentZelusDataIndex): void {
    if (this.epvLineChartSvg == null) {
      return;
    }

    if (d3.select('#epv-progress-linechart').select('svg').size() == 0) {
      const width = +d3.select('#epv-progress-linechart').node().getBoundingClientRect().width;
      const height = +d3.select('#epv-progress-linechart').node().getBoundingClientRect().height;

      this.epvProgressLineChartSvg = d3.select('#epv-progress-linechart')
          .append('svg')
          .attr('width', width)
          .attr('height', height);

      this.epvProgressLineChartSvg
          .append('g')
          .append('path');

      this.epvProgressLineChartSvg
          .append('circle')
          .attr('r', 5)
          .style('fill', 'white');
    }

    const x = this.epvLineChartScaleX;
    const y = this.epvLineChartScaleY;

    this.epvProgressLineChartSvg.select('path')
        .datum(function() {
          return currentZelusDataIndex > -1 ? zelusData.slice(0, currentZelusDataIndex) : [];
        })
        .attr('fill', 'none')
        .attr('stroke', 'white')
        .attr('stroke-width', 5.0)
        .attr('stroke-opacity', 1.0)
        .attr('d', d3.line()
            .x(function(d) {
              return x(d.wall_clock);
            })
            .y(function(d) {
              return y(d.epv);
            }),
        );

    this.epvProgressLineChartSvg.select('circle')
        .attr('cx', function() {
          return currentZelusDataIndex > -1 && zelusData[currentZelusDataIndex] ? x(zelusData[currentZelusDataIndex].wall_clock) : x(zelusData[0].wall_clock);
        })
        .attr('cy', function() {
          return currentZelusDataIndex > -1 && zelusData[currentZelusDataIndex] ? y(zelusData[currentZelusDataIndex].epv) : y(zelusData[0].epv);
        });
  }

  updatePlayerTracking(currentZelusDataIndex): void {
    if (currentZelusDataIndex < 0) {
      for (let i = 0; i <= 5; i++) {
        this.playerTrackingSvg.select('#o'+i).attr('opacity', 0);
        this.playerTrackingSvg.select('#o'+i+'-name').attr('opacity', 0);
        this.playerTrackingSvg.select('#o'+i+'-trail').attr('opacity', 0);

        this.playerTrackingSvg.select('#d'+i).attr('opacity', 0);
        this.playerTrackingSvg.select('#d'+i+'-name').attr('opacity', 0);
      }
      this.playerTrackingSvg.select('#ball').attr('opacity', 0);
      this.playerTrackingSvg.select('#defense-hull').attr('opacity', 0);
      this.playerTrackingSvg.select('#offense-hull').attr('opacity', 0);
      return;
    }

    const x = this.playerTrackingScaleX;
    const y = this.playerTrackingScaleY;

    const xKey = this.displayingHalfCourt ? '_y' : '_x';
    const yKey = this.displayingHalfCourt ? '_x' : '_y';
    const offenseKey = this.currentClip.homeHasPossession ? 'h' : 'a';
    const defenseKey = this.currentClip.homeHasPossession ? 'a' : 'h';

    const coordinatesSign = !this.displayingHalfCourt && this.currentClip.trackingMetadata['offensive_side'] === 'right' ? -1 : 1;

    this.playerTrackingSvg.select('#ball')
        .transition()
        .ease(d3.easeLinear)
        .attr('cx', x(coordinatesSign * this.currentClip.zelusData[currentZelusDataIndex]['ball'+xKey]))
        .attr('cy', y(coordinatesSign * this.currentClip.zelusData[currentZelusDataIndex]['ball'+yKey]))
        .attr('opacity', 1);

    const offensePoints = [];
    for (let i = 1; i <= 5; i++) {
      const offenseX = x(coordinatesSign * this.currentClip.zelusData[currentZelusDataIndex][offenseKey+i+xKey]);
      const offenseY = y(coordinatesSign * this.currentClip.zelusData[currentZelusDataIndex][offenseKey+i+yKey]);
      offensePoints.push([offenseX, offenseY]);

      this.playerTrackingSvg.select('#o'+i)
          .transition()
          .ease(d3.easeLinear)
          .attr('cx', offenseX)
          .attr('cy', offenseY)
          .attr('opacity', 1);

      this.playerTrackingSvg.select('#o'+i+'-name')
          .text(this.currentClip.trackingMetadata[offenseKey+i].last_name)
          .transition()
          .ease(d3.easeLinear)
          .attr('x', offenseX)
          .attr('y', offenseY)
          .attr('opacity', 1);


      this.playerTrackingSvg.select('#o'+i+'-trail')
          .datum(this.currentClip.zelusData.slice(0, currentZelusDataIndex))
          .attr('opacity', this.showPlayerTrails ? 1 : 0)
          .attr('d', d3.line()
              .x(function(d) {
                return x(coordinatesSign * d[offenseKey+i+xKey]);
              })
              .y(function(d) {
                return y(coordinatesSign * d[offenseKey+i+yKey]);
              }),
          );
    }
    this.playerTrackingSvg.select('#offense-hull')
        .transition()
        .ease(d3.easeLinear)
        .attr('points', d3.polygonHull(offensePoints))
        .attr('opacity', this.showOffenseHull ? 1 : 0);

    const defensePoints = [];
    for (let i = 1; i <= 5; i++) {
      const defenseX = x(coordinatesSign * this.currentClip.zelusData[currentZelusDataIndex][defenseKey+i+xKey]);
      const defenseY = y(coordinatesSign * this.currentClip.zelusData[currentZelusDataIndex][defenseKey+i+yKey]);
      defensePoints.push([defenseX, defenseY]);

      this.playerTrackingSvg.select('#d'+i)
          .transition()
          .ease(d3.easeLinear)
          .attr('cx', defenseX)
          .attr('cy', defenseY)
          .attr('opacity', 1);

      this.playerTrackingSvg.select('#d'+i+'-name')
          .text(this.currentClip.trackingMetadata[defenseKey+i].last_name)
          .transition()
          .ease(d3.easeLinear)
          .attr('x', defenseX)
          .attr('y', defenseY)
          .attr('opacity', 1);
    }
    this.playerTrackingSvg.select('#defense-hull')
        .transition()
        .ease(d3.easeLinear)
        .attr('points', d3.polygonHull(defensePoints))
        .attr('opacity', this.showDefenseHull ? 1 : 0);
  }

  drawPlayerTracking(): void {
    let containerWidth = 0;
    let containerHeight = 0;

    // cannot use bounding rect because of animation
    containerWidth = this.displayingHalfCourt ? 300 : 564;
    containerHeight = this.displayingHalfCourt ? 282 : 300;

    const trackingVisSize = this.displayingHalfCourt ? containerWidth / 50 : containerHeight / 50;

    const x = this.displayingHalfCourt ?
      d3.scaleLinear()
          .domain([-25, 25])
          .range([0, containerWidth]) :
      d3.scaleLinear()
          .domain([-47, 47])
          .range([0, containerWidth]);

    const y = this.displayingHalfCourt ?
      d3.scaleLinear()
          .domain([0, 47])
          .range([containerHeight, 0]) :
      d3.scaleLinear()
          .domain([25, -25])
          .range([containerHeight, 0]);

    if (d3.select('#court-plot').select('svg').size() == 0) {
      this.playerTrackingSvg = d3.select('#court-plot')
          .append('svg')
          .attr('width', containerWidth)
          .attr('height', containerHeight);

      const g = this.playerTrackingSvg.append('g');

      // rightcourt 3-point arc
      g.append('path').attr('id', 'rightcourt-3-pt-arc');
      // rightcourt 3-point lines
      g.append('line').attr('id', 'rightcourt-corner');
      g.append('line').attr('id', 'rightcourt-corner-2');
      // rightcourt paint
      g.append('rect').attr('id', 'rightcourt-paint');
      // rightcourt restricted area
      g.append('path').attr('id', 'rightcourt-restricted-area');
      // rightcourt freethrow
      g.append('path').attr('id', 'rightcourt-free-throw');
      // rightcourt freethrow dotted
      g.append('path').attr('id', 'rightcourt-ft-dotted');
      // rightcourt basket
      g.append('circle').attr('id', 'rightcourt-basket');
      // rightcourt backboard
      g.append('rect').attr('id', 'rightcourt-backboard');

      // halfcourt line
      g.append('line').attr('id', 'halfcourt-line');

      // leftcourt 3-point arc
      g.append('path').attr('id', 'leftcourt-3-pt-arc');
      // leftcourt 3-point lines
      g.append('line').attr('id', 'leftcourt-corner');
      g.append('line').attr('id', 'leftcourt-corner-2');
      // leftcourt paint
      g.append('rect').attr('id', 'leftcourt-paint');
      // leftcourt restricted area
      g.append('path').attr('id', 'leftcourt-restricted-area');
      // leftcourt freethrow
      g.append('path').attr('id', 'leftcourt-free-throw');
      // leftcourt freethrow dotted
      g.append('path').attr('id', 'leftcourt-ft-dotted');
      // leftcourt basket
      g.append('circle').attr('id', 'leftcourt-basket');
      // leftcourt backboard
      g.append('rect').attr('id', 'leftcourt-backboard');

      // offensive players
      g.append('polygon')
          .attr('id', 'offense-hull')
          .attr('fill', '#00BFFF')
          .attr('stroke-fill', 'blue')
          .attr('fill-opacity', 0.5)
          .attr('opacity', 0);
      for (let i = 1; i <= 5; i++) {
        g.append('path')
            .attr('id', 'o'+i+'-trail')
            .attr('fill', 'none')
            .attr('stroke', 'white')
            .attr('stroke-width', 1.0)
            .attr('stroke-dasharray', '5,5')
            .attr('stroke-opacity', 0.7)
            .attr('opacity', 0);
        g.append('circle')
            .attr('id', 'o'+i)
            .attr('r', trackingVisSize)
            .attr('fill', '#00BFFF')
            .attr('stroke', 'white')
            .attr('stroke-width', 1.5)
            .attr('fill-opacity', 0.7)
            .attr('cx', containerWidth / 2)
            .attr('cy', containerHeight / 2)
            .attr('opacity', 0);
        g.append('text')
            .attr('id', 'o'+i+'-name')
            .attr('height', 10)
            .attr('width', trackingVisSize)
            .attr('dy', trackingVisSize + 10)
            .attr('text-anchor', 'middle')
            .attr('fill', 'white')
            .attr('font-size', 10);
      }

      // defensive players
      g.append('polygon')
          .attr('id', 'defense-hull')
          .attr('fill', '#fd5c63')
          .attr('stroke-fill', 'red')
          .attr('fill-opacity', 0.5)
          .attr('opacity', 0);
      for (let i = 1; i <= 5; i++) {
        g.append('circle')
            .attr('id', 'd'+i)
            .attr('r', trackingVisSize)
            .attr('fill', '#fd5c63')
            .attr('stroke', 'white')
            .attr('stroke-width', 1.5)
            .attr('fill-opacity', 0.7)
            .attr('cx', containerWidth / 2)
            .attr('cy', containerHeight / 2)
            .attr('opacity', 0);
        g.append('text')
            .attr('id', 'd'+i+'-name')
            .attr('height', 10)
            .attr('width', trackingVisSize)
            .attr('dy', trackingVisSize + 10)
            .attr('text-anchor', 'middle')
            .attr('fill', 'white')
            .attr('font-size', 10);
      }

      // ball
      g.append('circle')
          .attr('id', 'ball')
          .attr('r', trackingVisSize / 2)
          .attr('stroke', 'yellow')
          .attr('fill', 'orange')
          .attr('stroke-width', 1.5)
          .attr('fill-opacity', 0.5)
          .attr('cx', containerWidth / 2)
          .attr('cy', containerHeight / 2)
          .attr('opacity', 0);
    } else {
      this.playerTrackingSvg
          .attr('width', containerWidth)
          .attr('height', containerHeight);

      const g = this.playerTrackingSvg.select('g');

      g.select('#rightcourt-halfcourt-outer').remove();
      g.select('#rightcourt-halfcourt-inner').remove();
      g.select('#bottom-border').remove();
    }

    if (this.displayingHalfCourt) {
      this.drawHalfcourtLines(this.playerTrackingSvg.select('g'), x, y);
    } else {
      this.drawFullcourtLines(this.playerTrackingSvg.select('g'), x, y);
    }

    this.playerTrackingScaleX = x;
    this.playerTrackingScaleY = y;
  }

  onVideoPlaying() {
    if (!this.currentClip) {
      return;
    }
    this.currentVideoTime = this.video.nativeElement.currentTime;
    const currentTimestamp = this.currentClip.startTimestamp + 1000.0 * this.currentVideoTime - 1000.0 * this.currentClip.startPadding;

    if (this.currentClip.endTrim > 0 && this.currentVideoTime >= (this.currentVideoDuration - this.currentClip.endTrim)) {
      this.onVideoEnded(this.currentVideoNumber);
    }
    if ((this.currentVideoDuration > 0) && (this.nextClip?.smoothingTrim && this.nextClip.smoothingTrim > 0) && (this.currentVideoDuration - this.currentVideoTime < 0.35)) {
      if (this.isAutoplaying && !this.isSaving && !this.isReportingIssue) {
        this.awaitingAutoplayAvailability = true;
        this.determineClipNavigation(true);
      }
    }
    this.updateZelusData();
    if (this.currentClip.zelusData?.length > 0 && this.currentClip.zelusData[0].wall_clock < currentTimestamp) {
      while (this.currentZelusDataIndex+1 < this.currentClip.zelusData.length && this.currentClip.zelusData[this.currentZelusDataIndex+1].wall_clock < currentTimestamp) {
        this.currentZelusDataIndex++;
      }
      if (this.showEpv && this.currentClip.zelusData[this.currentZelusDataIndex]) {
        this.currentEpv = this.currentClip.zelusData[this.currentZelusDataIndex].epv;
        this.setEpvProgressLineChart(this.currentClip.zelusData, this.currentZelusDataIndex);
      }
    } else {
      this.currentZelusDataIndex = -1;
      this.currentEpv = -1;
    }

    if (this.show2dCourt && this.currentClip.zelusData && this.currentClip.zelusData[this.currentZelusDataIndex]) {
      this.updatePlayerTracking(this.currentZelusDataIndex);
    }

    if (this.tagSliderAutoUpdate) {
      this.autoUpdateTagSliderParams();
    }

    this.updateTimeDotLocation();
  }

  onVideoEnded(videoIndex) {
    if (videoIndex === this.currentVideoNumber) {
      this.isVideoPlaying = false;
      if (this.isAutoplaying && !this.isSaving && !this.isReportingIssue) {
        this.awaitingAutoplayAvailability = true;
        this.determineClipNavigation(true);
        if (this.video.nativeElement.readyState != 0 && !this.singleVideo) {
          this.onVideoCanPlay(this.currentVideoNumber);
        }
      }
      this.cdr.markForCheck();
    }
  }

  onProgressBarClicked(e) {
    this.startDragging();
    const progressBarBounds = this.progressBar.nativeElement.getBoundingClientRect();
    const offsetX = e.clientX - progressBarBounds.left;
    if (this.isSaving) {
      this.changeCurrentTime(this.video.nativeElement.duration * Math.min(1, Math.max(0, (offsetX / progressBarBounds.width))));
    } else {
      this.changeCurrentTime((this.currentClip?.startTrim || 0) + this.trimmedVideoDuration * Math.min(1, Math.max(0, (offsetX / progressBarBounds.width))));
    }
  }

  toggleFullScreen() {
    this.onToggleFullScreen.emit(null);
    this.delayedTimeDotUpdate();
  }

  onTrimStartChange(startTrim): void {
    if (this.currentClip.startTrim != startTrim) {
      this.currentClip.startTrim = startTrim;
      this.changeCurrentTime(this.currentClip.startTrim);
      this.pauseVideo();
      this.cdr.markForCheck();
    }
  }

  onTrimEndChange(endTrim): void {
    if (this.currentClip.endTrim != this.currentVideoDuration - endTrim) {
      this.currentClip.endTrim = this.currentVideoDuration - endTrim;
      this.changeCurrentTime(this.currentVideoDuration - this.currentClip.endTrim);
      this.pauseVideo();
      this.cdr.markForCheck();
    }
  }

  confirmSave() {
    const savedVideoData = [];
    let selectedClips: any[];
    if (!this.selectedClips?.length) {
      selectedClips = [this.currentClip];
    } else {
      selectedClips = _.cloneDeep(this.selectedClips);
    }
    selectedClips.forEach((clip) => {
      savedVideoData.push({
        id: clip.id,
        league: clip.league,
        nbaGameID: clip.nbaGameID,
        eagleGameID: clip.eagleGameID,
        sportradarGameID: clip.sportradarGameID,
        gameID: clip.gameID,
        eagleChanceID: clip.eagleChanceID,
        sportradarPlayByPlayID: clip.sportradarPlayByPlayID,
        period: clip.period,
        startGameclock: clip.startGameclock,
        startTimestamp: clip.startTimestamp,
        startPadding: clip.startPadding,
        endPadding: clip.endPadding,
        startTrim: clip.startTrim,
        endTrim: clip.endTrim,
        name: clip.name || clip.description,
        commentary: clip.commentary,
        taggedPlayers: clip.onCourtEntities?.filter((onCourtEntity) => onCourtEntity.selected).concat(clip.additionalTaggedEntities).map((player) => player),
        playlists: this.currentClip.playlists,
        sharedUsers: this.isPrivateOptionSelected ? [] : this.currentClip.sharedUsers,
      });
    });
    this.savingInProgress = true;
    this.videoService.saveVideo(savedVideoData).pipe(untilDestroyed(this)).subscribe(
        (savedVideo) => {
          if (savedVideoData.length === 1) {
            if (!savedVideoData[0].id) {
              this.currentClip.savedVideos.push(savedVideo);
            } else {
              this.currentClip = {...this.currentClip, savedVideo};
            }
            this.onCancelSaveButtonClicked();
          } else {
            this.onCancelSaveButtonClicked(true);
          }
          this.savingInProgress = false;
          this.cdr.markForCheck();
        },
        (error) => {
          console.log(error);
        },
    );
  }

  compareIDs(a, b) {
    return a && b && a.id === b.id;
  }

  getCurrentNumberOfPlayersSelected() {
    return (this.currentClip?.onCourtEntities?.filter((onCourtEntity) => onCourtEntity.selected).length || 0);
  }

  filterUsers(q: string): void {
    this.filteredUsers = undefined;
    if (q) {
      this.autocompleteService
          .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
          .subscribe(
              (entities) => {
                this.filteredUsers = entities.filter((entity) => (entity.id != this.user?.entity?.id) && (!this.currentClip?.sharedUsers ||
              this.currentClip?.sharedUsers.findIndex((sharedUser) => sharedUser.id == entity.id) == -1));
                this.cdr.markForCheck();
              },
          );
    }
  }

  addSharingEntity(entity): void {
    if (!this.currentClip.sharedUsers) {
      this.currentClip.sharedUsers = [];
    }
    this.currentClip.sharedUsers.push(entity);
    this.viewersInput.nativeElement.value = '';
  }

  removeSharingEntity(entity: any): void {
    this.currentClip.sharedUsers = this.currentClip.sharedUsers.filter((sharedUser) => sharedUser.id != entity.id);
  }

  addTaggedEntity(event): void {
    const entity = event.option.value;
    this.currentClip.additionalTaggedEntities.push(entity);
    this.entitiesInput.nativeElement.value = '';
  }

  removeTaggedEntity(entity: any): void {
    this.currentClip.additionalTaggedEntities = this.currentClip.additionalTaggedEntities.filter((taggedEntity) => taggedEntity.id != entity.id);
  }

  onTagButtonClicked() {
    this.isTagging = !this.isTagging;
    if (this.isTagging) {
      // this.tagPanel.nativeElement.style.opacity = 1;
      // this.tagPanel.nativeElement.style.zIndex = 0;
    } else {
      // this.tagPanel?.nativeElement.removeAttribute('style');
    }
    this.video.nativeElement.removeAttribute('style');
    this.videoControls.nativeElement.removeAttribute('style');
    if (this.isTagging) {
      this.onResizeTagPanelSliderChange({value: 0});
    }
    // We need a longer timeout here to account for the animation
    this.delayedTimeDotUpdate(500, false);
  }

  onCancelTagButtonClicked() {
    this.isTagging = false;
    this.video.nativeElement.removeAttribute('style');
    this.videoControls.nativeElement.removeAttribute('style');
    // this.tagPanel?.nativeElement.removeAttribute('style');
    // We need a longer timeout here to account for the animation
    this.delayedTimeDotUpdate(500, false);
  }

  onResizeTagPanelSliderChange(e) {
    // const initialTagPanelHeight = 390;
    // const sliderOffsetHeight = this.isFullScreen ? e.value * 3 : e.value;
    // const newTagPanelHeight = initialTagPanelHeight + sliderOffsetHeight + 'px';
    // const newVideoControlsHeight = initialTagPanelHeight + sliderOffsetHeight + 60 + 'px';
    // const newVideoHeight = `calc(100% - ${newVideoControlsHeight})`;
    // this.savePanel.nativeElement.style.height = 0;
    // this.video1.nativeElement.style.height = newVideoHeight;
    // this.video2.nativeElement.style.height = newVideoHeight;
    // this.video1.nativeElement.style.transition = 'none';
    // this.video2.nativeElement.style.transition = 'none';
    // this.videoControls.nativeElement.style.height = newVideoControlsHeight;
    // this.videoControls.nativeElement.style.transition = 'none';
    // this.tagPanel.nativeElement.style.height = newTagPanelHeight;
    this.delayedTimeDotUpdate(50, false);
  }

  onResizeTagPanelSliderFinish() {
    this.video.nativeElement.style.transition = 'height 0.3s';
    this.videoControls.nativeElement.style.transition = 'height 0.3s';
  }

  onResizeSavePanelSliderChange(e) {
    // let initialSavePanelHeight = 390;
    // if (this.isSavingMany) {
    //   initialSavePanelHeight = 225;
    // }
    // const sliderOffsetHeight = this.isFullScreen ? e.value * 3 : e.value;
    // const newSavePanelHeight = initialSavePanelHeight + sliderOffsetHeight + 'px';
    // const newVideoControlsHeight = initialSavePanelHeight + sliderOffsetHeight + 60 + 'px';
    // const newVideoHeight = `calc(100% - ${newVideoControlsHeight})`;
    // this.tagPanel.nativeElement.style.height = 0;
    // this.video1.nativeElement.style.height = newVideoHeight;
    // this.video2.nativeElement.style.height = newVideoHeight;
    // this.video1.nativeElement.style.transition = 'none';
    // this.video2.nativeElement.style.transition = 'none';
    // this.videoControls.nativeElement.style.height = newVideoControlsHeight;
    // this.videoControls.nativeElement.style.transition = 'none';
    // this.savePanel.nativeElement.style.height = newSavePanelHeight;
    this.delayedTimeDotUpdate(50, false);
  }

  onResizeSavePanelSliderFinish() {
    this.video.nativeElement.style.transition = 'height 0.3s';
    this.videoControls.nativeElement.style.transition = 'height 0.3s';
  }

  onSaveButtonClicked(isBulk = false) {
    if (isBulk) {
      this.isSavingMany = !this.isSavingMany;
    } else {
      this.isSaving = !this.isSaving;
    }
    if (this.isSaving) {
      this.currentClip.name = this.currentClip?.name || this.currentClip?.description;
      this.currentClip.playlists = [];
      this.currentClip.sharedUsers = [];
      this.currentClip.additionalTaggedEntities = [];
      this.isPrivateOptionSelected = true;
      // this.savePanel.nativeElement.style.opacity = 1;
      // this.savePanel.nativeElement.style.zIndex = 0;
    } else {
      // this.savePanel?.nativeElement.removeAttribute('style');
    }
    this.startTrimSeconds = 0;
    this.endTrimSeconds = this.currentClip.startPadding + this.currentClip.endPadding;
    this.trimSliderOptions.floor = 0;
    this.trimSliderOptions.ceil = this.currentClip.startPadding + this.currentClip.endPadding;
    this.video1.nativeElement.removeAttribute('style');
    this.video2.nativeElement.removeAttribute('style');
    this.videoControls.nativeElement.removeAttribute('style');
    this.onSavingUpdate.emit(true);
    if (this.isDialog && this.isSaving) {
      this.onResizeSavePanelSliderChange({value: 0});
    }
    // We need a longer timeout here to account for the animation
    this.delayedTimeDotUpdate(500, false);
  }

  saveSelectedClips() {
    this.isSavingMany = true;
    this.isPrivateOptionSelected = true;
    this.startTrimSeconds = 0;
    this.endTrimSeconds = this.currentClip.startPadding + this.currentClip.endPadding;
    this.trimSliderOptions.floor = 0;
    this.trimSliderOptions.ceil = this.currentClip.startPadding + this.currentClip.endPadding;
    this.video1.nativeElement.removeAttribute('style');
    this.video2.nativeElement.removeAttribute('style');
    // this.savePanel.nativeElement.style.opacity = 1;
    // this.savePanel.nativeElement.style.zIndex = 0;
    this.onResizeSavePanelSliderChange({value: 0});
  }

  onReportIssueMenuOpened() {
    this.isReportingIssue = true;
  }

  onReportIssueMenuClosed() {
    this.isReportingIssue = false;
  }

  onReportButtonClicked() {
    this.videoService.reportCTGVideo(
        this.currentClip.league, this.currentClip.gameID, this.currentClip.period,
        this.currentClip.endPadding, this.currentClip.startPadding, this.currentClip.startTimestamp,
        this.currentClip.startGameclock, this.reportClipFeedback).pipe(untilDestroyed(this)).subscribe(
        (reportSuccessful) => {
          console.log(reportSuccessful);
          this.snackBar.open('Successfully reported clip', 'Ok', {duration: 3000});
          this.reportButton.closeMenu();
          this.reportClipFeedback = '';
          this.cdr.markForCheck();
        },
        (error) => {
          console.log(error);
          this.snackBar.openFromComponent(
              ErrorsSnackbarComponent, {data: {errors: ['Error reporting clip']}},
          );
          this.reportButton.closeMenu();
        },
    );
  }

  createPlaylist(addToCurrentClip: boolean = false) {
    this.isCreatingPlaylist = true;
    this.currentClip.playlists = this.currentClip?.playlists?.filter((playlist) => playlist.id != -1);
    const dialogConfig: MatDialogConfig = {
      data: {name: ''},
    };
    const dialogRef = this.dialog.open(SavedPlaylistDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe((newName) => {
      if (newName) {
        this.videoService.savePlaylist({name: newName, sharedUsers: []}).pipe(untilDestroyed(this)).subscribe(
            (newSavedPlaylist) => {
              if (addToCurrentClip) {
                this.currentClip.playlists = [...this.currentClip.playlists, newSavedPlaylist];
              }
              this.userPlaylists.push(newSavedPlaylist);
              this.cdr.markForCheck();
            },
            (error) => {
              console.log(error);
            },
        );
      }
      this.isCreatingPlaylist = false;
    });
  }

  formatTime(seconds) {
    const isoString = new Date(1000 * seconds).toISOString();
    return (isoString.substr(11, 2) == '00') ? isoString.substr(14, 5) : isoString.substr(11, 8);
  }

  roundNumber(num) {
    return Math.round(num);
  }

  playTag(clipIndex, tag) {
    this.changeToClip(clipIndex);
    setTimeout(() => {
      this.changeCurrentTime(tag.startTimestamp ? (tag.startTimestamp - this.currentClip?.startTimestamp)/1000 + this.currentClip?.startPadding : (this.currentClip?.startGameclock - tag.startGameclock) + this.currentClip?.startPadding);
    });
  }

  editTag(clipIndex, tag) {
    this.tagEditing = tag;
    this.playTag(clipIndex, tag);

    // Prevent pause/play race condition
    setTimeout(() => {
      this.pauseVideo();
    }, tag.endTimestamp - tag.startTimestamp + 50);

    this.selectedTagType = this.tagDefinitions.find((tagDefinition) => tagDefinition?.id == tag.definition?.id);
    this.selectedTagType.metadataDefinitions.forEach((metadataDefinition) => {
      if (metadataDefinition.type == 'Person') {
        metadataDefinition.value = tag.metadata.find((metadata) => metadata?.definition?.id == metadataDefinition?.id)?.entity;
      }
      if (metadataDefinition.type == 'Selection') {
        metadataDefinition.value = tag.metadata.find((metadata) => metadata?.definition?.id == metadataDefinition?.id)?.option;
      }
    });
  }

  confirmTag() {
    if (this.currentClip.startTimestamp) {
      this.selectedTagType.startTimestamp = this.currentClip.startTimestamp + (1000 * (this.tagStartSeconds - this.currentClip.startPadding));
      this.selectedTagType.endTimestamp = this.currentClip.startTimestamp + (1000 * (this.tagEndSeconds - this.currentClip.startPadding));
    }
    this.selectedTagType.startGameclock = Math.max(0, this.currentClip.startGameclock - Math.round(this.tagStartSeconds) + this.currentClip.startPadding);
    this.selectedTagType.endGameclock = Math.max(0, this.currentClip.startGameclock - Math.round(this.tagEndSeconds) + this.currentClip.startPadding);
    const videoTagData = {
      tagData: this.selectedTagType,
      videoData: this.currentClip,
    };
    this.taggingInProgress = true;
    this.videoService.saveVideoTag(videoTagData, this.tagEditing?.id).pipe(untilDestroyed(this)).subscribe(
        (videoTag) => {
          let updatedVideoTag = this.currentClip.tags.find((existingTag) => existingTag.id == videoTag.id);
          if (!updatedVideoTag) {
            this.currentClip.tags.push(videoTag);
          } else {
            updatedVideoTag = Object.assign(updatedVideoTag, videoTag);
            this.tagEditing = null;
          }
          this.taggingInProgress = false;
          this.onCancelTagButtonClicked();
        },
        (error) => {
          console.log(error);
        },
    );
  }

  deleteTag() {
    const message = 'Are you sure that you want to delete this tag?';
    const confirmDialogConfig = {
      width: '600px',
      data: {
        message: message,
      },
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.taggingInProgress = true;
        this.videoService.deleteVideoTag(this.tagEditing?.id).pipe(untilDestroyed(this)).subscribe(
            (videoTagID) => {
              this.taggingInProgress = false;
              this.currentClip.tags = this.currentClip.tags.filter((existingTag) => existingTag.id != videoTagID);
              this.tagEditing = null;
              this.onCancelTagButtonClicked();
            },
        );
      }
    });
  }

  assignSelectionsToPreset(presetNumber) {
    this.tagPresets[presetNumber] = JSON.parse(JSON.stringify(this.selectedTagType));
  }

  updateSelectionsFromPreset(presetNumber) {
    if (this.tagPresets[presetNumber]) {
      this.selectedTagType = JSON.parse(JSON.stringify(this.tagPresets[presetNumber]));
    }
    this.cdr.markForCheck();
  }

  autoUpdateTagSliderParams() {
    if (this.tagSliderOptions.ceil != this.currentVideoDuration) {
      const newTagSliderOptions = Object.assign({}, this.tagSliderOptions);
      newTagSliderOptions.floor = (this.currentClip?.startTrim || 0);
      newTagSliderOptions.ceil = this.currentVideoDuration - (this.currentClip?.endTrim || 0);
      this.tagSliderOptions = newTagSliderOptions;
    }
    this.tagStartSeconds = Math.round(10 * Math.max(0, this.currentVideoTime - 0.5))/10;
    this.tagEndSeconds = Math.round(10 * Math.min(this.currentVideoDuration, this.currentVideoTime + 0.5))/10;
    this.onTagSecondsChange.emit([this.tagStartSeconds, this.tagEndSeconds]);
    this.cdr.markForCheck();
  }

  onTagSliderUserUpdated(e) {
    this.tagSliderAutoUpdate = false;
    this.tagStartSeconds = e.value;
    this.tagEndSeconds = e.highValue;
    this.onTagSecondsChange.emit([this.tagStartSeconds, this.tagEndSeconds]);
  }

  downloadCurrentClip() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Download Clip',
        message: 'An email will be sent to you containing the current clip and a corresponding XML file. This can take up to 10 minutes.',
        acceptButtonTitle: 'Ok',
        cancelButtonTitle: 'Cancel',
      },
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe((result) => {
      if (result) {
        const csvString = `${this.currentClip.gameID},${this.currentClip.period},${this.currentClip.startTimestamp || this.currentClip.startGameclock},${this.currentClip.startPadding},${this.currentClip.endPadding}`;
        this.videoService.createCTGEmailDownloadUrl({csvString: csvString}).pipe(untilDestroyed(this)).subscribe(
            (data) => {
              console.log(data);
            },
            (error) => {
              console.log(error);
            },
        );
      }
    });
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy() {
    if (this.isPostFeed || this.displayedClips) this.logView();
  }
}
