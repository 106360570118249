import {Component, OnInit, ViewEncapsulation, Inject, Input} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'bild-saved-folder-dialog',
  templateUrl: './saved-folder.component.html',
  styleUrls: ['./saved-folder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SavedFolderDialogComponent implements OnInit {
  name: string;
  existingFolder: boolean;

  constructor(public dialogRef: MatDialogRef<SavedFolderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.name = data.name;
    this.existingFolder = data.existingFolder;
  }

  ngOnInit() {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    this.dialogRef.close(this.name);
  }
}
