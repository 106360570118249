import {ActionTypes} from './actions';
import {sessionInitialState, VideoState, playlistsInitialState, foldersInitialState, generatedPlaylistsInitialState, channelInitialState, savedVideosLookupInitialState} from './state';
import produce from 'immer';
import {ActionReducerMap} from '@ngrx/store';

const channelProducer = (draft, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_CHANNEL_NAME:
      draft.isLoaded = true;
      draft.channelName = action.payload;
      return;
  }
};

export const channelReducerProducer = produce(channelProducer, channelInitialState);

export function channelReducer(state, action) {
  return channelReducerProducer(state, action);
}

const sessionProducer = (draft, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_SESSION:
      draft.isLoaded = true;
      draft.id = action.payload.id;
      draft.currentNavigation = action.payload.currentNavigation;
      draft.currentExploreType = action.payload.currentExploreType;
      draft.playlist1ID = action.payload.playlist1ID;
      draft.playlist2ID = action.payload.playlist2ID;
      draft.playlist3ID = action.payload.playlist3ID;
      draft.playlist4ID = action.payload.playlist4ID;
      draft.playlist5ID = action.payload.playlist5ID;
      draft.playlist6ID = action.payload.playlist6ID;
      draft.explorePlaylistID = action.payload.explorePlaylistID;
      draft.generatedPlaylistID = action.payload.generatedPlaylistID;
      draft.exploreClipIndex = action.payload.exploreClipIndex;
      draft.exploreSortColumn = action.payload.exploreSortColumn;
      draft.exploreSortDirection = action.payload.exploreSortDirection;
      draft.exploreTableFilters = action.payload.exploreTableFilters;
      draft.exploreColumns = action.payload.exploreColumns;
      draft.exploreIsShuffled = action.payload.exploreIsShuffled;
      draft.exploreIsNotesShown = action.payload.exploreIsNotesShown;
      draft.queueClipIndex = action.payload.queueClipIndex;
      draft.queueSortColumn = action.payload.queueSortColumn;
      draft.queueSortDirection = action.payload.queueSortDirection;
      draft.queueTableFilters = action.payload.queueTableFilters;
      draft.queueColumns = action.payload.queueColumns;
      draft.queueIsShuffled = action.payload.queueIsShuffled;
      draft.queueIsNotesShown = action.payload.queueIsNotesShown;
      draft.playlist1ClipIndex = action.payload.playlist1ClipIndex;
      draft.playlist1SortColumn = action.payload.playlist1SortColumn;
      draft.playlist1SortDirection = action.payload.playlist1SortDirection;
      draft.playlist1TableFilters = action.payload.playlist1TableFilters;
      draft.playlist1Columns = action.payload.playlist1Columns;
      draft.playlist1IsShuffled = action.payload.playlist1IsShuffled;
      draft.playlist1IsNotesShown = action.payload.playlist1IsNotesShown;
      draft.playlist2ClipIndex = action.payload.playlist2ClipIndex;
      draft.playlist2SortColumn = action.payload.playlist2SortColumn;
      draft.playlist2SortDirection = action.payload.playlist2SortDirection;
      draft.playlist2TableFilters = action.payload.playlist2TableFilters;
      draft.playlist2Columns = action.payload.playlist2Columns;
      draft.playlist2IsShuffled = action.payload.playlist2IsShuffled;
      draft.playlist2IsNotesShown = action.payload.playlist2IsNotesShown;
      draft.playlist3ClipIndex = action.payload.playlist3ClipIndex;
      draft.playlist3SortColumn = action.payload.playlist3SortColumn;
      draft.playlist3SortDirection = action.payload.playlist3SortDirection;
      draft.playlist3TableFilters = action.payload.playlist3TableFilters;
      draft.playlist3Columns = action.payload.playlist3Columns;
      draft.playlist3IsShuffled = action.payload.playlist3IsShuffled;
      draft.playlist3IsNotesShown = action.payload.playlist3IsNotesShown;
      draft.playlist4ClipIndex = action.payload.playlist4ClipIndex;
      draft.playlist4SortColumn = action.payload.playlist4SortColumn;
      draft.playlist4SortDirection = action.payload.playlist4SortDirection;
      draft.playlist4TableFilters = action.payload.playlist4TableFilters;
      draft.playlist4Columns = action.payload.playlist4Columns;
      draft.playlist4IsShuffled = action.payload.playlist4IsShuffled;
      draft.playlist4IsNotesShown = action.payload.playlist4IsNotesShown;
      draft.playlist5ClipIndex = action.payload.playlist5ClipIndex;
      draft.playlist5SortColumn = action.payload.playlist5SortColumn;
      draft.playlist5SortDirection = action.payload.playlist5SortDirection;
      draft.playlist5TableFilters = action.payload.playlist5TableFilters;
      draft.playlist5Columns = action.payload.playlist5Columns;
      draft.playlist5IsShuffled = action.payload.playlist5IsShuffled;
      draft.playlist5IsNotesShown = action.payload.playlist5IsNotesShown;
      draft.playlist6ClipIndex = action.payload.playlist6ClipIndex;
      draft.playlist6SortColumn = action.payload.playlist6SortColumn;
      draft.playlist6SortDirection = action.payload.playlist6SortDirection;
      draft.playlist6TableFilters = action.payload.playlist6TableFilters;
      draft.playlist6Columns = action.payload.playlist6Columns;
      draft.playlist6IsShuffled = action.payload.playlist6IsShuffled;
      draft.playlist6IsNotesShown = action.payload.playlist6IsNotesShown;
      return;
    case ActionTypes.UPDATE_NAVIGATION:
      draft.currentNavigation = action.payload;
      return;
    case ActionTypes.UPDATE_EXPLORE_TYPE:
      draft.currentExploreType = action.payload;
      return;
    case ActionTypes.UPDATE_EXPLORE_PLAYLIST:
      draft.explorePlaylistID = action.payload;
      return;
    case ActionTypes.UPDATE_EXPLORE_GENERATED_PLAYLIST:
      draft.generatedPlaylistID = action.payload;
      return;
    case ActionTypes.UPDATE_PLAYLIST_1:
      draft.playlist1ID = action.payload;
      return;
    case ActionTypes.UPDATE_PLAYLIST_2:
      draft.playlist2ID = action.payload;
      return;
    case ActionTypes.UPDATE_PLAYLIST_3:
      draft.playlist3ID = action.payload;
      return;
    case ActionTypes.UPDATE_PLAYLIST_4:
      draft.playlist4ID = action.payload;
      return;
    case ActionTypes.UPDATE_PLAYLIST_5:
      draft.playlist5ID = action.payload;
      return;
    case ActionTypes.UPDATE_PLAYLIST_6:
      draft.playlist6ID = action.payload;
      return;
    case ActionTypes.UPDATE_CLIP_INDEX:
      draft[`${action.payload['currentNavigation'].toLowerCase().replace('_', '')}ClipIndex`] = action.payload['currentIndex'];
      return;
    case ActionTypes.UPDATE_NOTES_SHOWN:
      draft[`${action.payload['currentNavigation'].toLowerCase().replace('_', '')}IsNotesShown`] = action.payload['notesShown'];
      return;
    case ActionTypes.UPDATE_IS_SHUFFLED:
      draft[`${action.payload['currentNavigation'].toLowerCase().replace('_', '')}IsShuffled`] = action.payload['isShuffled'];
      return;
    case ActionTypes.UPDATE_SORT_COLUMN:
      draft[`${action.payload['currentNavigation'].toLowerCase().replace('_', '')}SortColumn`] = action.payload['sortColumn'];
      return;
    case ActionTypes.UPDATE_SORT_DIRECTION:
      draft[`${action.payload['currentNavigation'].toLowerCase().replace('_', '')}SortDirection`] = action.payload['sortDirection'];
      return;
  }
};

export const sessionReducerProducer = produce(sessionProducer, sessionInitialState);

export function sessionReducer(state, action) {
  return sessionReducerProducer(state, action);
}

const playlistsProducer = (draft, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_PLAYLISTS:
      draft.isLoaded = true;
      draft.playlists = action.payload;
      return;
    case ActionTypes.UPDATE_PLAYLIST:
      let playlistIndex = draft.playlists.findIndex(playlist => playlist.id == action.payload.id);
      draft.playlists[playlistIndex] = {...draft.playlists[playlistIndex], ...action.payload};
      return;
    case ActionTypes.SAVE_PLAYLIST:
      let existingPlaylistIndex = draft.playlists.findIndex(playlist => playlist.id == action.payload.id);
      if (existingPlaylistIndex != -1) {
        draft.playlists[existingPlaylistIndex] = {...draft.playlists[existingPlaylistIndex], ...action.payload};
      }
      else {
        draft.playlists.unshift(action.payload);
      }
      return;
    case ActionTypes.DELETE_PLAYLIST:
      draft.playlists = draft.playlists.filter(playlist => playlist.id != action.payload.id);
      return;
    case ActionTypes.SAVE_VIDEO:
      draft.playlists.forEach(playlist => {
        if (playlist.clips) {
          if (playlist.clips.find(clip => clip.id == action.payload.id)) {
            if (!action.payload.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
              playlist.clips = playlist.clips.filter(clip => clip.id != action.payload.id);
            }
            else {
              playlist.clips[playlist.clips.findIndex(clip => clip.id == action.payload.id)] = action.payload;
            }
          }
          else if (action.payload.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
            playlist.clips.push(action.payload);
          }
        }
      });
      return;
    case ActionTypes.DELETE_VIDEO:
      draft.playlists.forEach(playlist => {
        if (playlist.clips) {
          playlist.clips = playlist.clips.filter(clip => clip.id != action.payload.id);
        }
      });
      return;
    case ActionTypes.BULK_SAVE_VIDEOS:
      draft.playlists.forEach(playlist => {
        action.payload.forEach(savedVideo => {
          if (playlist.clips) {
            if (playlist.clips.find(clip => clip.id == savedVideo.id)) {
              if (!savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
                playlist.clips = playlist.clips.filter(clip => clip.id != savedVideo.id);
              }
              else {
                playlist.clips[playlist.clips.findIndex(clip => clip.id == savedVideo.id)] = savedVideo;
              }
            }
            else if (savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
              playlist.clips.push(savedVideo);
            }
          }
        });
      });
      return;
    case ActionTypes.BULK_REMOVE_VIDEOS:
      draft.playlists.forEach(playlist => {
        action.payload.forEach(savedVideo => {
          if (playlist.clips) {
            if (playlist.clips.find(clip => clip.id == savedVideo.id)) {
              if (!savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
                playlist.clips = playlist.clips.filter(clip => clip.id != savedVideo.id);
              }
              else {
                playlist.clips[playlist.clips.findIndex(clip => clip.id == savedVideo.id)] = savedVideo;
              }
            }
            else if (savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
              playlist.clips.push(savedVideo);
            }
          }
        });
      });
      return;
    case ActionTypes.BULK_DELETE_VIDEOS:
      draft.playlists.forEach(playlist => {
        action.payload.forEach(savedVideo => {
          if (playlist.clips) {
            if (playlist.clips.find(clip => clip.id == savedVideo.id)) {
              playlist.clips = playlist.clips.filter(clip => clip.id != savedVideo.id);
            }
          }
        });
      });
      return;
    case ActionTypes.SAVE_VIDEO_NOTES:
      draft.playlists.forEach(playlist => {
        if (playlist.clips) {
          if (playlist.clips.find(clip => clip.id == action.payload.id)) {
            playlist.clips[playlist.clips.findIndex(clip => clip.id == action.payload.id)].notes = action.payload.notes;
          }
        }
      });
      return;
    case ActionTypes.SAVE_TAG:
      draft.playlists.forEach(playlist => {
        if (playlist.clips) {
          if (playlist.clips.find(clip => ((clip.eagleChanceID && clip.eagleChanceID == action.payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == action.payload.synergyEventID)))) {
            let tagClip = playlist.clips[playlist.clips.findIndex(clip => ((clip.eagleChanceID && clip.eagleChanceID == action.payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == action.payload.synergyEventID)))];
            if (tagClip.tags.find(tag => tag.id == action.payload.id)) {
              tagClip.tags[tagClip.tags.findIndex(tag => tag.id == action.payload.id)] = action.payload;
            }
            else {
              tagClip.tags.push(action.payload);
            }
          }
        }
      });
      return;
    case ActionTypes.DELETE_TAG:
      draft.playlists.forEach(playlist => {
        if (playlist.clips) {
          playlist.clips.forEach(clip => {
            if (clip.tags) {
              clip.tags = clip.tags.filter(tag => tag.id != action.payload.id);
            }
          });
        }
      });
      return;
  }
};

export const playlistsReducerProducer = produce(playlistsProducer, playlistsInitialState);

export function playlistsReducer(state, action) {
  return playlistsReducerProducer(state, action);
}

const foldersProducer = (draft, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FOLDERS:
      draft.isLoaded = true;
      draft.folders = action.payload;
      return;
    case ActionTypes.UPDATE_FOLDER:
      let folderIndex = draft.folders.findIndex(folder => folder.id == action.payload.id);
      draft.folders[folderIndex] = {...draft.folders[folderIndex], ...action.payload};
      return;
    case ActionTypes.SAVE_FOLDER:
      let existingFolderIndex = draft.folders.findIndex(folder => folder.id == action.payload.id);
      if (existingFolderIndex != -1) {
        draft.folders[existingFolderIndex] = {...draft.folders[existingFolderIndex], ...action.payload};
      }
      else {
        draft.folders.unshift(action.payload);
      }
      return;
    case ActionTypes.DELETE_FOLDER:
      draft.folders = draft.folders.filter(folder => folder.id != action.payload.id);
      return;
  }
};

export const foldersReducerProducer = produce(foldersProducer, foldersInitialState);

export function foldersReducer(state, action) {
  return foldersReducerProducer(state, action);
}

const generatedPlaylistsProducer = (draft, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_GENERATED_PLAYLISTS:
      draft.isLoaded = true;
      draft.generatedPlaylists = action.payload;
      return;
    case ActionTypes.UPDATE_GENERATED_PLAYLIST:
      let playlistIndex = draft.generatedPlaylists.findIndex(generatedPlaylist => generatedPlaylist.id == action.payload.id);
      draft.generatedPlaylists[playlistIndex] = {...draft.generatedPlaylists[playlistIndex], ...action.payload};
      return;
    case ActionTypes.CREATE_GENERATED_PLAYLIST:
      draft.generatedPlaylists.unshift(action.payload);
      return;
  }
};

export const generatedPlaylistsReducerProducer = produce(generatedPlaylistsProducer, generatedPlaylistsInitialState);

export function generatedPlaylistsReducer(state, action) {
  return generatedPlaylistsReducerProducer(state, action);
}

const savedVideosLookupProducer = (draft, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_SAVED_VIDEOS_LOOKUP:
      draft.isLoaded = true;
      draft.synergyEventIDs = action.payload.synergyEventIDs;
      draft.nbaChanceIDs = action.payload.nbaChanceIDs;
      draft.eagleChanceIDs = action.payload.eagleChanceIDs;
      draft.savedVideosMetadata = action.payload.savedVideosMetadata;
      return;
    case ActionTypes.SAVE_VIDEO:
      if (!draft.savedVideosMetadata.find(savedVideoMetadata => savedVideoMetadata.id == action.payload.id)) {
        draft.savedVideosMetadata.push({
          'id': action.payload.id,
          'synergyEventID': action.payload.synergyEventID,
          'nbaChanceID': action.payload.nbaChanceID,
          'eagleChanceID': action.payload.eagleChanceID,
        });
        draft.synergyEventIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.synergyEventID).filter(synergyEventID => synergyEventID != null))];
        draft.nbaChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.nbaChanceID).filter(nbaChanceID => nbaChanceID != null))];
        draft.eagleChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.eagleChanceID).filter(eagleChanceID => eagleChanceID != null))];
      }
      return;
    case ActionTypes.DELETE_VIDEO:
      if (draft.savedVideosMetadata.find(savedVideoMetadata => savedVideoMetadata.id == action.payload.id)) {
        draft.savedVideosMetadata = draft.savedVideosMetadata.filter(savedVideoMetadata => savedVideoMetadata.id != action.payload.id);
        draft.synergyEventIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.synergyEventID).filter(synergyEventID => synergyEventID != null))];
        draft.nbaChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.nbaChanceID).filter(nbaChanceID => nbaChanceID != null))];
        draft.eagleChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.eagleChanceID).filter(eagleChanceID => eagleChanceID != null))];
      }
      return;
    case ActionTypes.BULK_SAVE_VIDEOS:
      action.payload.forEach(savedVideo => {
        if (!draft.savedVideosMetadata.find(savedVideoMetadata => savedVideoMetadata.id == savedVideo.id)) {
          draft.savedVideosMetadata.push({
            'id': savedVideo.id,
            'synergyEventID': savedVideo.synergyEventID,
            'nbaChanceID': savedVideo.nbaChanceID,
            'eagleChanceID': savedVideo.eagleChanceID,
          });
        }
      });
      draft.synergyEventIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.synergyEventID).filter(synergyEventID => synergyEventID != null))];
      draft.nbaChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.nbaChanceID).filter(nbaChanceID => nbaChanceID != null))];
      draft.eagleChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.eagleChanceID).filter(eagleChanceID => eagleChanceID != null))];
      return;
    case ActionTypes.BULK_DELETE_VIDEOS:
      action.payload.forEach(savedVideo => {
        if (draft.savedVideosMetadata.find(savedVideoMetadata => savedVideoMetadata.id == savedVideo.id)) {
          draft.savedVideosMetadata = draft.savedVideosMetadata.filter(savedVideoMetadata => savedVideoMetadata.id != savedVideo.id);
        }
      });
      draft.synergyEventIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.synergyEventID).filter(synergyEventID => synergyEventID != null))];
      draft.nbaChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.nbaChanceID).filter(nbaChanceID => nbaChanceID != null))];
      draft.eagleChanceIDs = [...new Set(draft.savedVideosMetadata.map(savedVideoMetadata => savedVideoMetadata.eagleChanceID).filter(eagleChanceID => eagleChanceID != null))];
      return;
  }
};

export const savedVideosLookupReducerProducer = produce(savedVideosLookupProducer, savedVideosLookupInitialState);

export function savedVideosLookupReducer(state, action) {
  return savedVideosLookupReducerProducer(state, action);
}

export const reducers: ActionReducerMap<VideoState> = {
  channel: channelReducer,
  session: sessionReducer,
  playlists: playlistsReducer,
  generatedPlaylists: generatedPlaylistsReducer,
  folders: foldersReducer,
  savedVideosLookup: savedVideosLookupReducer
};
