import * as moment from "moment";

export enum SalaryLevel {
    CAP = 'Cap',
    TAX = 'Tax',
    APRON = 'Apron',
    MTS = 'MTS',
    SUMMARY = 'Summary',
}

export enum ContractType {
    CAP_HOLD = 'HOLD',
    DRAFT_PICK = 'DRFPK',
    ETO = 'ETO',
    GTD = 'GTD',
    MTS = 'MTS',
    NG = 'NG',
    PG = 'PG',
    PG_NG = 'PG/NG',
    PLAYER_OPTION = 'PO',
    QO = 'QO',
    ROOKIE_SCALE = 'RKSC',
    SEQUENCED_TRANSACTION = 'SEQ',
    SUMMER = 'SUMM',
    TEAM_OPTION = 'TO',
    TWO_WAY = '2-WAY',
    WAIVED_SALARY = 'DEAD',
}

export enum DraftRound {
    FIRST_ROUND = 'firstRound',
    SECOND_ROUND = 'secondRound',
}

export enum DepthChartTier {
    STARTER = 'starter',
    ROTATION = 'rotation',
    LIMITED = 'limited pt',
}

export enum FreeAgentBoardTier {
    LIKELY = 'likely',
    UNLIKELY = 'unlikely',
    USED = 'used',
}

export enum FreeAgentDesignation {
    BIRD = 'QUAL',
    EARLY_BIRD = 'ERQL',
    NON_BIRD = 'NONQL',
}

export enum FreeAgentStatus {
    RFA = 'RFA',
    UFA = 'UFA',
}

export enum InjuryStatus {
    OFS = 'ofs',
    OUT = 'out',
    GTD = 'gtd',
    AVAIL = 'avail',
}

export enum OptionDecision {
    DECLINE = 'Decline Option',
    DELETE = 'Delete Option',
    EXERCISE = 'Exercise Option',
}

export enum TransactionType {
    SIGNING = 'SIGN',
    OPTION_DECISION = 'OPT',
    RENEGOTIATION = 'RENOG',
    TRADE = 'TRADE',
    WAIVE = 'WAIVE',
    DRAFT = 'DRAFT',
}

export enum TempTransactionAction {
    CREATE = 1,
    EDIT = 2,
    DELETE = 3,
    CREATE_SCENARIO = 4,
    EDIT_SCENARIO = 5,
    DELETE_SCENARIO = 6,
    ENABLE_CAP_HOLDS = 7,
    DISABLE_CAP_HOLDS = 8,
    UPDATE_FREE_AGENT_SOURCE = 9,
    UPDATE_FA_BOARD_SCENARIO = 10,
    UPDATE_TEMP_EXCEPTION = 11,
    ENABLE_QO = 12,
    DISABLED_QO = 13,
    COPY_SCENARIO = 14,
    ARCHIVE_SCENARIO = 15,
    ACTIVATE_SCENARIO = 16,
    TOGGLE_ON_PICK = 17,
    TOGGLE_OFF_PICK = 18,
}

export enum TempTransactionStatus {
    QUEUED = 'queued',
    PROCESSING = 'processing',
    READY = 'ready',
    READY_WITH_PROCESSING = 'readyWithProcessing',
}

export enum SigningContractType {
    REGULAR = 'REGCT',
    ROOKIE = 'RKSC',
    ROOKIE_2ND_ROUND = 'RND2E', // Made up contract type to apply 2nd round exception
    ROOKIE_EXT = 'RSEX',
    ROS = 'ROS',
    TEN_DAY_1 = '10D1',
    TEN_DAY_2 = '10D2',
    TWO_WAY = '2WCT',
    VET_EXT = 'VETEX',
}

export const PCMS_PLAYER_BASE_URL = 'https://pcms.nba.com/#/pcms/player/';
export const PCMS_TEAM_BASE_URL = 'https://pcms.nba.com/#/pcms/teambudget/';
export const TRADED_PLAYER_EXCEPTION = 'TREXC';
export const PRIOR_TAX_SEASONS_DISPLAYED = 4;
export const TEAM_SALARY_YEARS_DISPLAYED = 6;
export const SECOND_APRON_LOCKED_DRAFT_YEAR_OFFSET = 8;
export const SECOND_APRON_LOCKED_DRAFT_COUNT_THRESHOLD = 2;
export const SECOND_APRON_LOCKED_DRAFT_START_YEAR = 2024;
export const PLACEHOLDER_PLAYER_ID = 99999999;

export const CONTRACT_TYPE_TO_CLASS_NAME = {
    [ContractType.CAP_HOLD]: 'cap-hold',
    [ContractType.DRAFT_PICK]: 'draft-pick',
    [ContractType.ETO]: 'eto',
    [ContractType.NG]: 'non-fully-guaranteed',
    [ContractType.PG]: 'non-fully-guaranteed',
    [ContractType.PG_NG]: 'non-fully-guaranteed',
    [ContractType.PLAYER_OPTION]: 'player-option',
    [ContractType.QO]: 'qo',
    [ContractType.SUMMER]: 'summer',
    [ContractType.TEAM_OPTION]: 'team-option',
    [ContractType.TWO_WAY]: 'two-way',
};

export const FA_DESIGNATION_DISPLAY = {
    [FreeAgentDesignation.BIRD]: 'Bird',
    [FreeAgentDesignation.EARLY_BIRD]: 'Early-Bird',
    [FreeAgentDesignation.NON_BIRD]: 'Non Bird',
};

export const SALARY_LEVEL_TO_DISPLAY_NAME = {
    [SalaryLevel.MTS]: 'Salary Floor',
    [SalaryLevel.CAP]: 'Salary Cap',
    [SalaryLevel.TAX]: 'Luxury Tax',
    [SalaryLevel.APRON]: 'First Apron',
};

export const SALARY_LEVEL_TO_FIELD_NAME = {
    [SalaryLevel.MTS]: 'mtsamount',
    [SalaryLevel.CAP]: 'capamount',
    [SalaryLevel.TAX]: 'taxamount',
    [SalaryLevel.APRON]: 'apronamount',
};

export const TRANSACTION_TYPE_DISPLAY_NAME = {
    [TransactionType.SIGNING]: 'Signing',
    [TransactionType.RENEGOTIATION]: 'Renegotiation',
    [TransactionType.TRADE]: 'Trade',
    [TransactionType.WAIVE]: 'Waive Player',
    [TransactionType.OPTION_DECISION]: 'Option Decision',
};

export const SIGNED_CONTRACT_TYPE_TO_DISPLAY_NAME = {
    [SigningContractType.TEN_DAY_1]: '10 Day',
    [SigningContractType.TWO_WAY]: '2-Way',
    [SigningContractType.ROOKIE_2ND_ROUND]: '2nd Round Exception',
    [SigningContractType.REGULAR]: 'Regular Contract',
    [SigningContractType.ROOKIE_EXT]: 'Rookie Extension',
    [SigningContractType.ROOKIE]: 'Rookie Scale',
    [SigningContractType.ROS]: 'ROS',
    [SigningContractType.VET_EXT]: 'Vet Extension',
};

export const PCMS_TRANSACTION_TYPE_MAP = {
    'SIGN': TransactionType.SIGNING,
    'TRADE': TransactionType.TRADE,
    '2WTRD': TransactionType.TRADE,
    'TRADL': TransactionType.TRADE,
    'WAIVE': TransactionType.WAIVE,
    '2WWAV': TransactionType.WAIVE,
    'OPT': TransactionType.OPTION_DECISION,
    'CTAMN': TransactionType.RENEGOTIATION,
};

export const BUYOUT_TRANSACTION = 'DIV';
export const CONTRACT_OPTIONS = ['ETO', 'PO', 'TO'];
export const SETOFF_TRANSACTION = 'ADJSO';
export const WAIVER_STRETCH_TRANSACTION = 'WVSTR';
export const RELEASE_TEN_DAY_TRANSACTION = 'CTR10';
export const TWO_WAY_WAIVE_TRANSACTION = '2WWAV';
export const WAIVER_CLEAR_TRANSACTIONS = new Set(['WVCLR', '2WWVC']);
export const WAIVER_RELATED_TRANSACTIONS = new Set([...['WAIVE', TWO_WAY_WAIVE_TRANSACTION, SETOFF_TRANSACTION, BUYOUT_TRANSACTION, WAIVER_STRETCH_TRANSACTION], ...WAIVER_CLEAR_TRANSACTIONS]);

export const NO_OPTION = 'NONE';

export const ROOM_SIGNING = 'ROM';
export const SECOND_ROUND_EXCEPTION = '2RDPE';

export const FULLY_PROTECTED_SALARY = 'FULL';

export const OPTION_DECISION_MAP = {
    'ETO': {
        [OptionDecision.DELETE]: 'ETODE',
        [OptionDecision.EXERCISE]: 'ETOEX',
    },
    'PO': {
        [OptionDecision.DECLINE]: 'POD',
        [OptionDecision.EXERCISE]: 'POE',
    },
    'TO': {
        [OptionDecision.DECLINE]: 'TOD',
        [OptionDecision.EXERCISE]: 'TOE',
    }
};

export const OPTION_PCMS_MAP = {
    'ETO': 'ETO',
    'PO': 'PLYR',
    'TO': 'TEAM',
};

export const TRANSACTION_TO_DECISION_MAP = {
    'ETODE': OptionDecision.DELETE,
    'ETOEX': OptionDecision.EXERCISE,
    'POD': OptionDecision.DECLINE,
    'POE': OptionDecision.EXERCISE,
    'TOD': OptionDecision.DECLINE,
    'TOE': OptionDecision.EXERCISE,
};

export const TRANSACTION_TO_OPTION_TYPE_MAP = {
    'ETODE': 'ETO',
    'ETOEX': 'ETO',
    'POD': 'PO',
    'POE': 'PO',
    'TOD': 'TO',
    'TOE': 'TO',
};

export const PCMS_SUBJECT_APRON_REASONS = [
    {'subjecttoapronreasonlk': 'CASH', 'description': 'Cash Trade'},
    {'subjecttoapronreasonlk': '1I20', 'description': 'One Incoming, Two Outgoing'},
    {'subjecttoapronreasonlk': 'SAT', 'description': 'Sign and Trade'},
    {'subjecttoapronreasonlk': 'SM110', 'description': 'Simultaneous +110%'},
    {'subjecttoapronreasonlk': 'SM125', 'description': 'Simultaneous +125%'},
    {'subjecttoapronreasonlk': 'SM200', 'description': 'Simultaneous +200%'},
    {'subjecttoapronreasonlk': 'SMTPE', 'description': 'Simultaneous +TPE Allowance'},
    {'subjecttoapronreasonlk': 'TPE1', 'description': 'Used Prior Year TPE'},
    {'subjecttoapronreasonlk': 'TPE2', 'description': 'Used TPE From Sign-and-Trade'},
    {'subjecttoapronreasonlk': 'UBA', 'description': 'Used Bi-Annual'},
    {'subjecttoapronreasonlk': 'CMLE', 'description': 'Used Convertible Mid-Level'},
    {'subjecttoapronreasonlk': 'UNTML', 'description': 'Used Non-Taxpayer Mid-Level'},
    {'subjecttoapronreasonlk': 'TMLE', 'description': 'Used Taxpayer Mid-Level'},
    {'subjecttoapronreasonlk': 'SNTML', 'description': 'Waived >= NTMLE'},
];

export enum HiddenContractColumns {
    GTD_CAP_AMOUNT = 'guaranteed',
    NFG_CAP_AMOUNT = 'nonFullyGuaranteed',
    TO_CAP_AMOUNT = 'teamOptions',
    PO_CAP_AMOUNT = 'playerOptions',
    ETO_CAP_AMOUNT = 'earlyTerminationOptions',
    SEQUENCED_CAP_AMOUNT = 'delayTransactionSalary',
    WAIVED_SALARY_AMOUNT = 'waivedSalary',
};

export const HIDDEN_COLUMN_TO_CONTRACT_TYPE = {
    [HiddenContractColumns.GTD_CAP_AMOUNT]: ContractType.GTD,
    [HiddenContractColumns.NFG_CAP_AMOUNT]: ContractType.PG_NG,
    [HiddenContractColumns.TO_CAP_AMOUNT]: ContractType.TEAM_OPTION,
    [HiddenContractColumns.PO_CAP_AMOUNT]: ContractType.PLAYER_OPTION,
    [HiddenContractColumns.ETO_CAP_AMOUNT]: ContractType.ETO,
    [HiddenContractColumns.SEQUENCED_CAP_AMOUNT]: ContractType.SEQUENCED_TRANSACTION,
    [HiddenContractColumns.WAIVED_SALARY_AMOUNT]: ContractType.WAIVED_SALARY,
};

export const FA_BOARD_SCENARIO_TYPES = {
    'high': 'Max Cap Room Scenario',
    'bulls': 'Bullseye Cap Room Scenario',
    'low': 'Low Cap Room Scenario',
};

export const NON_TAX_MLE = 'NTMDL';
export const TAX_MLE = 'TMDLE';
export const CONV_NON_TAX_MLE = 'CNTMD';

export const NON_PLAYER_EXCEPTION_TYPES = [
    {'exceptiontypelk': 'BIEXC', 'display': 'BAE', 'systemValueKey': 'bae'},
    {'exceptiontypelk': 'RMEXC', 'display': 'RMLE', 'systemValueKey': 'rmle'},
    {'exceptiontypelk': NON_TAX_MLE, 'display': 'NTMLE', 'systemValueKey': 'ntmle'},
    {'exceptiontypelk': CONV_NON_TAX_MLE, 'display': 'Conv. NTMLE'},
    {'exceptiontypelk': TAX_MLE, 'display': 'TPMLE', 'systemValueKey': 'tpmle'},
];

export const CONTRACT_TO_HIDDEN_COLUMN_TYPE = {
    [ContractType.GTD]: HiddenContractColumns.GTD_CAP_AMOUNT,
    [ContractType.PG_NG]: HiddenContractColumns.NFG_CAP_AMOUNT,
    [ContractType.TEAM_OPTION]: HiddenContractColumns.TO_CAP_AMOUNT,
    [ContractType.PLAYER_OPTION]: HiddenContractColumns.PO_CAP_AMOUNT,
    [ContractType.ETO]: HiddenContractColumns.ETO_CAP_AMOUNT,
    [ContractType.SEQUENCED_TRANSACTION]: HiddenContractColumns.SEQUENCED_CAP_AMOUNT,
    [ContractType.WAIVED_SALARY]: HiddenContractColumns.WAIVED_SALARY_AMOUNT,
};

export const SALARY_FIELDS = ['capamount', 'protectedCapamount', 'taxamount', 'apronamount'];
export const TV_DEAL_END_YEAR = 2024;
export const CBA_OPT_OUT_YEAR = 2028;
export const MTS_START_DATE = moment([2024, 4, 19]);
