import {Injectable} from '@angular/core';
import * as _ from "lodash";
import {DOMHelper} from "@helpers/dom.helper";
import * as moment from "moment-timezone";


@Injectable({
  providedIn: 'root',
})
export class VideoHelper {
  currentUserID: number;
  oldCommentary: string[] = [];

  constructor(
    protected domHelper: DOMHelper,
  ) {}

  randomWithSeed(shuffleSeed: number) {
    const randomNumber = Math.sin(shuffleSeed++) * 10000;
    return randomNumber - Math.floor(randomNumber);
  }

  shuffle(data) {
    let shuffleSeed = 42;
    let remainingElements = data.length;
    let temporaryElement;
    let randomIndex;

    while (remainingElements) {
      randomIndex = Math.floor(this.randomWithSeed(shuffleSeed) * remainingElements--);

      temporaryElement = data[remainingElements];
      data[remainingElements] = data[randomIndex];
      data[randomIndex] = temporaryElement;
      ++shuffleSeed;
    }

    data = _.cloneDeep(data);
    return data;
  }

  isSameClips(originalClips, newClips) {
    if (newClips.length && newClips?.length == originalClips?.length) {
      // Check IDs and order of clips
      const newClipIDs = newClips.map((clip) => clip.eagleChanceID || clip.synergyEventID);
      const originalClipIDs = originalClips.map((clip) => clip.eagleChanceID || clip.synergyEventID);
      const sameClips = _.isEqual(newClipIDs, originalClipIDs);
      // Check notes
      const oldNotes = originalClips.map(clip => clip.notes);
      const newNotes = newClips.map(clip => clip.notes);
      const sameNotes = _.isEqual(oldNotes, newNotes);
      // Check commentary
      const newCommentary = originalClips.map(clip => clip.commentary);
      const sameCommentary = _.isEqual(this.oldCommentary, newCommentary);
      this.oldCommentary = newCommentary;
      // Check tags
      const oldTags = originalClips.map(clip => clip.tags);
      const newTags = newClips.map(clip => clip.tags);
      const sameTags = _.isEqual(oldTags, newTags);
      // Check selectedClips
      const oldSelections = originalClips.map(clip => clip.isSelected);
      const newSelections = newClips.map(clip => clip.isSelected);
      const sameSelections = _.isEqual(oldSelections, newSelections);
      return sameClips && sameNotes && sameCommentary && sameTags && sameSelections;
    }
    return false;
  }

  getDataAccessor(key) {
    if (key === 'saved') {
      return 'createDatetime';
    } else if (key === 'contestednessNumber') {
      return 'contestedness';
    }
    return key;
  }

  getRowSpecificAccessor(row, key) {
    if (key === 'date') {
      return row?.clip?.game ? 'game.date' : 'gameStartTime';
    } else if (['offTeam', 'defTeam'].includes(key)) {
      return (row.homeHasPossession && key === 'offTeam') || (!row.homeHasPossession && key !== 'offTeam') ? 'homeTeamName' : 'awayTeamName';
    } else if (key === 'shooter-name') {
      return row.nbaShooterID ?
        row.nbaShooterID && row.nbaEntityMap && row.nbaEntityMap[row.nbaShooterID] && `nbaEntityMap.${row.nbaShooterID}.name` :
        row.synergyShooterID && row.synergyEntityMap && row.synergyEntityMap[row.synergyShooterID] && `synergyEntityMap.${row.synergyShooterID}.name`;
    } else if (key === 'assister-name') {
      return row.nbaAssisterID ?
        row.nbaAssisterID && row.nbaEntityMap && row.nbaEntityMap[row.nbaAssisterID] && `nbaEntityMap.${row.nbaAssisterID}.name` :
        row.synergyAssisterID && row.synergyEntityMap && row.synergyEntityMap[row.synergyAssisterID] && `synergyEntityMap.${row.synergyAssisterID}.name`;
    } else if (key === 'potential-assister-name') {
      return row.nbaPotentialAssisterID && row.nbaEntityMap[row.nbaPotentialAssisterID] && `nbaEntityMap.${row.nbaPotentialAssisterID}.name`;
    } else if (key === 'blocker-name') {
      return row.nbaBlockerID ?
        row.nbaBlockerID && row.nbaEntityMap && row.nbaEntityMap[row.nbaBlockerID] && `nbaEntityMap.${row.nbaBlockerID}.name` :
        row.synergyBlockerID && row.synergyEntityMap && row.synergyEntityMap[row.synergyBlockerID] && `synergyEntityMap.${row.synergyBlockerID}.name`;
    } else if (key === 'rebounder-name') {
      return row.nbaRebounderID ?
        row.nbaRebounderID && row.nbaEntityMap && row.nbaEntityMap[row.nbaRebounderID] && `nbaEntityMap.${row.nbaRebounderID}.name` :
        row.synergyRebounderID && row.synergyEntityMap && row.synergyEntityMap[row.synergyRebounderID] && `synergyEntityMap.${row.synergyRebounderID}.name`;
    } else if (key === 'cutter-name') {
      return row.synergyCutterID && row.synergyEntityMap[row.synergyCutterID] && `synergyEntityMap.${row.synergyCutterID}.name`;
    }  else if (key === 'ballhandler-name') {
      return row.synergyBallHandlerID && row.synergyEntityMap[row.synergyBallHandlerID] && `synergyEntityMap.${row.synergyBallHandlerID}.name`;
    }  else if (key === 'screener-name') {
      return row.synergyScreenerID && row.synergyEntityMap[row.synergyScreenerID] && `synergyEntityMap.${row.synergyScreenerID}.name`;
    }  else if (key === 'passer-name') {
      return row.synergyPasserID && row.synergyEntityMap[row.synergyPasserID] && `synergyEntityMap.${row.synergyPasserID}.name`;
    } else if (key === 'contestednessNumber') {
      return 'contestedness';
    } else {
      return key;
    }
  }

  customSorting(clips: any[], sortColumn: string, sortDirection: any) {
    const columnMapping = {
      'assister-name': clip => this.getEntityName(clip, 'nbaAssisterID', 'synergyAssisterID', sortDirection),
      'potential-assister-name': clip => this.getPotentialAssisterName(clip, sortDirection),
      'blocker-name': clip => this.getEntityName(clip, 'nbaBlockerID', 'synergyBlockerID', sortDirection),
      'shotClock': clip => this.getValueOrPushNull(clip.shotClock, sortDirection),
      'shotZoneIntermediate': clip => this.getDescOrPushNull(clip.shotZoneIntermediate, sortDirection),
      'shotDistance': clip => this.getValueOrPushNull(clip.shotDistance, sortDirection),
      'setup': clip => this.getDescOrPushNull(clip.setup, sortDirection),
      'gameState': clip => this.getDescOrPushNull(clip.gameState, sortDirection),
      'date': clip => clip.gameStartTime || clip.game?.date,
      'defTeam': clip => clip.homeHasPossession ? clip.awayTeamName : clip.homeTeamName,
      'label': clip => this.getDescOrPushNull(clip.label, sortDirection),
      'notes': clip => this.getDescOrPushNull(clip.notes?.toLowerCase(), sortDirection),
      'offTeam': clip => clip.homeHasPossession ? clip.homeTeamName : clip.awayTeamName,
      'rebounder-name': clip => this.getEntityName(clip, 'nbaRebounderID', 'synergyRebounderID', sortDirection),
      'player-name': clip => this.getEntityName(clip, 'chartingPlayerNBAID', null, sortDirection),
      'contestednessNumber': clip => this.getContestednessValue(clip, sortDirection),
      'saved': 'createDatetime',
      'shotType': clip => this.getDescOrPushNull(clip.shotType?.toLowerCase(), sortDirection),
      'shooter-name': clip => this.getEntityName(clip, 'nbaShooterID', 'synergyShooterID', sortDirection),
      'xefg': clip => this.getValueOrPushNull(clip.xefg, sortDirection),
      'qefg': clip => this.getValueOrPushNull(clip.qefg, sortDirection),
      'cutter-name': clip => this.getEntityName(clip, null, 'synergyCutterID', sortDirection),
      'ballhandler-name': clip => this.getEntityName(clip, null, 'synergyBallHandlerID', sortDirection),
      'screener-name': clip => this.getEntityName(clip, null, 'synergyScreenerID', sortDirection),
      'passer-name': clip => this.getEntityName(clip, null, 'synergyPasserID', sortDirection),
      'actionName': clip => this.getEntityNameNCAAActions(clip, sortDirection),
    };

    const getSortValue = columnMapping[sortColumn] || (clip => clip[sortColumn]);

    clips = _.orderBy(clips, getSortValue, [sortDirection]);
    return clips;
  }

  getEntityName(clip, nbaID, synergyID = null, sortDirection) {
    return (clip.nbaEntityMap?.[clip[nbaID]]?.name?.toLowerCase() ||
            (synergyID && clip.synergyEntityMap?.[clip[synergyID]]?.name?.toLowerCase()) ||
            this.domHelper.pushNullsToBottom(sortDirection));
  }

  getEntityNameNCAAActions(clip, sortDirection) {
    if (clip.actionName == 'Transition' || clip.actionName == null) {
      return this.domHelper.pushNullsToBottom(sortDirection);
    } else {
      return this.actionDescriptionHelper(clip.actionName);
    }
  }

  actionDescriptionHelper(actionNames) {
    if (!Array.isArray(actionNames)) {
      return '';
    }
    return actionNames.map(actionName => {
      switch (actionName) {
        case 'Handoff':
          return 'Hand Off';
        case 'PostUp':
          return 'Post-Up';
        case 'OnBallScreen':
          return 'On-Ball-Screen';
        case 'OffBallScreen':
          return 'Off-Ball-Screen';
        case 'SpotUp':
          return 'Spot-Up';
        default:
          return actionName;
      }
    }).join(', ');
  }

  getPotentialAssisterName(clip, sortDirection) {
    return (clip.nbaEntityMap?.[clip.nbaPotentialAssisterID]?.name?.toLowerCase() && !clip.nbaEntityMap?.[clip.nbaAssisterID]) ?
      clip.nbaEntityMap[clip.nbaPotentialAssisterID].name.toLowerCase() :
      this.domHelper.pushNullsToBottom(sortDirection);
  }

  getValueOrPushNull(value, sortDirection) {
    return value != null ? value : this.domHelper.pushNullNumbersToBottom(sortDirection);
  }

  getDescOrPushNull(value, sortDirection) {
    return value != null ? value : this.domHelper.pushNullsToBottom(sortDirection);
  }

  getContestednessValue(clip, sortDirection) {
    if (clip.contestedness) {
      switch (clip.contestedness.toLowerCase()) {
        case 'low': return 0;
        case 'moderate': return 1;
        case 'high': return 2;
      }
    }
    return this.domHelper.pushNullNumbersToBottom(sortDirection);
  }

  getRowValue(data, accessor) {
    let value = _.get(data, accessor);
    if (accessor === 'isMake') {
      value = value === true ? 'Make' : 'Miss';
    }
    return value;
  }

  filterClips(filterProperties, displayedClips) {
    Object.keys(filterProperties).forEach((columnName) => {
      if (filterProperties[columnName]) {
        displayedClips = this.filterColumn(filterProperties[columnName], displayedClips, columnName);
      }
    });
    return displayedClips;
  }

  filterColumn = (values, filteredValues, key) => {
    let isNestedField;
    let operation;
    let operand;

    // Search fields
    if (['description', 'notes'].includes(key) && values.contains) {
      const searchValue = values.contains.trim().toLowerCase();
      const allWordsRegex = new RegExp(`(?=.*${searchValue.split(' ').join(')(?=.*')})`);
      filteredValues = _.filter(filteredValues, (data) => {
        return allWordsRegex.test(data[key]?.toLowerCase());
      });
    }
    // Checkboxes
    if (values?.checkValues?.length) {
      filteredValues = _.filter(filteredValues, (data) => {
        // This applies for slider
        const accessor = this.getRowSpecificAccessor(data, key);
        const value = this.getRowValue(data, accessor);
        if (key == 'potential-assister-name') {
          const assistCol = this.getRowSpecificAccessor(data, 'assister-name');
          const assistValue = this.getRowValue(data, assistCol);
          return value && (values.checkValues.includes(Number(value)) || values.checkValues.includes(`${value}`)) && !(values.checkValues.includes(Number(assistValue)) || values.checkValues.includes(`${assistValue}`));
        }
        if (key == 'actionName') {
          const accessor = this.getRowSpecificAccessor(data, key);
          const value = this.getRowValue(data, accessor);
          return value && (values.checkValues.includes(this.actionDescriptionHelper(value)));
        }
        if (key == 'tags') {
          const value = this.getTagDataArray(data);
          return value && value.some(val => values.checkValues.includes(val));
        }
        return value && (values.checkValues.includes(Number(value)) || values.checkValues.includes(`${value}`));
      });
    }
    // Dates
    const DateAccessor = this.getRowSpecificAccessor(filteredValues?.length && filteredValues[0], key);
    if (values?.minDate) {
      filteredValues = _.filter(filteredValues, (data) => {
        return moment(_.get(data, DateAccessor)) >= values.minDate;
      });
    }
    if (values?.maxDate) {
      filteredValues = _.filter(filteredValues, (data) => {
        return moment(_.get(data, DateAccessor)) <= values.maxDate;
      });
    }
    if (values?.minValue != null && !isNestedField) {
      filteredValues = _.filter(filteredValues, (data) => {
        if (operation && operand && !isNestedField) {
          return data[key] && (eval(`${data[key]} ${operation} ${operand}`) >= values.minValue);
        } else if (!isNestedField) {
          return data[key] && data[key] >= values.minValue;
        }
      });
    }
    if (values?.maxValue != null && !isNestedField) {
      filteredValues = _.filter(filteredValues, (data) => {
        if (operation && operand && !isNestedField) {
          return data[key] && (eval(`${data[key]} ${operation} ${operand}`) <= values.maxValue);
        } else if (!isNestedField) {
          return data[key] && data[key] <= values.maxValue;
        }
      });
    }
    return filteredValues;
  };

  getTagDataArray(clip: any): string[] {
    if (!clip.tags?.length) return [];
    const tags = clip.tags.filter(
      tag => tag.author?.userID === this.currentUserID
    );
    const tagArray: string[] = [];
    tags.some(tag => {
      let strTag = '';
      if (tag?.definition?.name) {
        strTag += `${tag.definition.name}`;
      }
      if (tag?.metadata?.[1]?.entity?.name) {
        strTag += ` - ${tag.metadata[1].entity.name}`;
      }
      if (tag?.metadata?.[0]?.entity?.name) {
        strTag += ` - ${tag.metadata[0].entity.name}`;
      } else if (tag?.metadata?.[0]?.option?.name) {
        strTag += ` - ${tag.metadata[0].option.name}`;
      }
      tagArray.push(strTag);
      return false;
    }); 
    return tagArray;
  }
}
